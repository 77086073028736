import React from 'react'
import { useFormikContext } from 'formik'
import groupBy from 'lodash.groupby'
import { Form, FormCheckboxInput, FormTextInput, FormLongTextInput, Heading } from '@superhi/design'

import FulfillableSelect from '../../../../components/MultiSelectProductFulfillable'
import PriceSelect from '../../../../components/SelectPrice'
import { InitialValuesCreate, InitialValuesUpdate } from '../types'

import OptionsManager from './CreateOptions'

export type VariantOption = {
  name: string
  value: string
}

type Option = {
  name: string
  value: string
}

export interface Variant {
  id?: string
  fulfillableIds?: string[]
  options: Option[]
  priceId: string
  maxQuantity: number
  mailchimpTag?: string
  title: string
  description?: string
}

type Props = {
  initialOptions?: Option[]
}

const VariantsManager: React.FC<Props> = () => {
  const formik = useFormikContext<InitialValuesCreate>()

  React.useEffect(() => {
    if (!formik.values.hasVariants && (formik.values.variants || []).length > 0) {
      formik.setFieldValue('variants', undefined)
    }
  }, [formik, formik.values.hasVariants])

  return (
    <Form.Legend label="Variants">
      <Form.Row>
        <FormCheckboxInput ariaLabel="has variants" name="hasVariants">
          This product has variants
        </FormCheckboxInput>
      </Form.Row>

      <Form.Peek<InitialValuesUpdate, InitialValuesUpdate['hasVariants']> name="hasVariants">
        {({ meta }) => meta.value && <OptionsManager />}
      </Form.Peek>

      <Form.Peek<InitialValuesUpdate, InitialValuesUpdate['variants']> name="variants">
        {({ meta }) =>
          (meta.value?.length || 0) > 0 && (
            <Form.Repeatable<InitialValuesUpdate, 'variants'>
              label="Configuration"
              name="variants"
              defaultValue={{}}
            >
              {({ name, value }) => (
                <>
                  <Heading level="5">
                    {(value as any).options.map((o: any) => o.value).join(' / ')}
                  </Heading>

                  <Form.Row>
                    <FormTextInput
                      ariaLabel="Title"
                      name={`${name}.title`}
                      label="Title"
                      required
                    />
                  </Form.Row>

                  <Form.Row>
                    <FormLongTextInput
                      ariaLabel="Description"
                      name={`${name}.description`}
                      height={6}
                      label="Description"
                    />
                  </Form.Row>

                  <Form.Row>
                    <FormTextInput
                      ariaLabel="Mailchimp tag"
                      name={`${name}.mailchimpTag`}
                      label="Mailchimp Tag"
                    />
                  </Form.Row>

                  <Form.Row>
                    <FulfillableSelect
                      ariaLabel="Fulfillables"
                      label="Fulfillables"
                      name={`${name}.fulfillableIds`}
                    />
                  </Form.Row>

                  <Form.Split ratios={{ small: 'full', medium: [50, 50], large: [50, 50] }}>
                    <Form.Row>
                      <FormTextInput
                        ariaLabel="Max quantity"
                        type="number"
                        label="Max quantity"
                        name={`${name}.maxQuantity`}
                        min={1}
                        required
                      />
                    </Form.Row>

                    <Form.Row>
                      <PriceSelect
                        ariaLabel="Price"
                        label="Price"
                        name={`${name}.priceId`}
                        required
                      />
                    </Form.Row>
                  </Form.Split>
                </>
              )}
            </Form.Repeatable>
          )
        }
      </Form.Peek>
    </Form.Legend>
  )
}

export default VariantsManager
