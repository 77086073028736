import React from 'react'

import { API } from '../../../../api'
import Form from '../Form'

import MUTATION from './mutations'

const DiscountCreate: React.FC = () => (
  <Form<API.DiscountCreateVariables>
    initialValues={{
      name: '',
      type: API.DiscountType.AUTOMATIC,
      code: '',
      description: '',

      isPaused: false,
      isStackable: false,

      percent: 0,

      clauses: [
        {
          typeExpressions: [],
          countExpressions: [],
          productExpressions: [],
        },
      ],

      hasStartAt: false,
      startAt: '',
      hasEndAt: false,
      endAt: '',
    }}
    mutation={MUTATION}
    createVariablesFn={({ hasStartAt, hasEndAt, ...formValues }) => ({
      ...formValues,
      startAt:
        hasStartAt && formValues.startAt ? new Date(formValues.startAt).toISOString() : undefined,
      endAt: hasEndAt && formValues.endAt ? new Date(formValues.endAt).toISOString() : undefined,
    })}
  />
)

export default DiscountCreate
