import { UserChip } from '@superhi/design'

import { API } from '../../api'

type Props = API.ProductVariantPreview

const ProductVariantPreview: React.FC<Props> = ({ id, title, product }) => (
  <UserChip
    image={{ src: product.image.url || '' }}
    name={title}
    link={{
      href: `/product-${product.__typename === 'ProductCourse' ? 'course' : 'book'}/${product.id}`,
    }}
  />
)

export { default as FRAGMENT } from './fragments'
export default ProductVariantPreview
