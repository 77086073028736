import { Price } from '@superhi/design'

import { API } from '../../api'
import StringPreview from '../StringPreview'

type Props = API.MoneyPreview

const MoneyPreview: React.FC<Props> = ({ value, currencyCode }) => (
  <StringPreview>{`${Price.format(value, currencyCode)}`}</StringPreview>
)

export { default as FRAGMENT } from './fragments'
export default MoneyPreview
