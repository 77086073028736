import React from 'react'
import { useQuery } from '@apollo/client'
import { FormSelectInput } from '@superhi/design'

import { API } from '../../api'

import QUERY from './queries'

type Props = Omit<React.ComponentProps<typeof FormSelectInput>, 'options'>

const SelectVariant: React.FC<Props> = (props) => {
  const { data } = useQuery<API.SelectVariant>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.productVariants) {
    return null
  }

  const OPTIONS = [
    { display: 'Choose one', value: undefined },
    ...(data.productVariants?.nodes.map((item) => ({
      value: item.id,
      display: item.title,
    })) || []),
  ]

  return <FormSelectInput {...props} options={OPTIONS} />
}

export default SelectVariant
