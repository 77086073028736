import React from 'react'
import { string, object, mixed, boolean } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import * as config from '../config'
import { configToInput } from '../../utils'

import MUTATION from './mutations'

type FormValues = {
  name: string
  slug: string
  description: string
  isSuperhi: boolean
  startedOn?: string
  image: {
    url?: string
    value: string
    file: any
  }
  jobTitle: string
  location?: string
  facebook?: string
  instagram?: string
  twitter?: string
}

const PricesCreate: React.FC = () => {
  return (
    <BaseForm<FormValues, API.PersonCreateMutationVariables>
      initialValues={{
        name: '',
        slug: '',
        description: '',
        jobTitle: '',
        isSuperhi: false,
        location: undefined,
        startedOn: undefined,
        facebook: undefined,
        instagram: undefined,
        twitter: undefined,
        image: {
          url: '',
          value: '',
          file: undefined,
        },
      }}
      validationSchema={{
        name: string().required(),
        slug: string().required(),
        description: string().required(),
        isSuperhi: boolean().required(),
        jobTitle: string().required(),
        location: string(),
        startedOn: string(),
        facebook: string(),
        instagram: string(),
        twitter: string(),
        image: object({
          url: string(),
          value: string().required(),
          file: mixed().required(),
        }).required(),
      }}
      mutation={MUTATION}
      createVariablesFn={(formValues) => ({
        ...formValues,
        image: formValues.image.file,
      })}
    >
      <Form.Row>{configToInput(config.NAME)}</Form.Row>

      <Form.Row>{configToInput(config.SLUG)}</Form.Row>

      <Form.Row>{configToInput(config.DESCRIPTION)}</Form.Row>

      <Form.Row>{configToInput(config.IS_SUPER_HI)}</Form.Row>

      <Form.Row>{configToInput(config.STARTED_ON)}</Form.Row>

      <Form.Row>{configToInput(config.IMAGE)}</Form.Row>

      <Form.Row>{configToInput(config.JOB_TITLE)}</Form.Row>

      <Form.Row>{configToInput(config.LOCATION)}</Form.Row>

      <Form.Row>{configToInput(config.FACEBOOK)}</Form.Row>

      <Form.Row>{configToInput(config.INSTAGRAM)}</Form.Row>

      <Form.Row>{configToInput(config.TWITTER)}</Form.Row>
    </BaseForm>
  )
}

export default PricesCreate
