import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@superhi/design'
import { useParams } from 'react-router-dom'

import { API } from '../../../../../../../api'

import MUTATION from './mutations'
import QUERY from './queries'

const AdminButton: React.FC = () => {
  const params = useParams<any>()
  const { data } = useQuery<API.users_isAdminButton, API.users_isAdminButtonVariables>(QUERY, {
    variables: {
      id: params.id,
    },
  })
  const [mutationFn] = useMutation<API.UserAdmin, API.UserAdminVariables>(MUTATION, {
    refetchQueries: ['users_view'],
  })

  const onSubmit = async () => {
    const confirmed = !data?.user?.isAdmin
      ? window.confirm('Are you sure you want to make this user an admin?')
      : true

    if (confirmed) {
      try {
        const response = await mutationFn({
          variables: { id: params.id, isAdmin: !data?.user?.isAdmin },
        })

        window.alert(
          `User is now ${response.data?.userUpdateAdmin?.isAdmin ? 'an admin' : 'a normal user'}.`,
        )
      } catch (e) {
        window.alert('Something went wrong.')
      }
    }
  }

  return (
    <Button version="outline" fullWidth onClick={onSubmit}>
      {data?.user?.isAdmin ? 'Remove' : 'Give'} admin privileges
    </Button>
  )
}

export default AdminButton
