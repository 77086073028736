import React from 'react'
import { string, object, mixed, boolean } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import * as config from '../config'
import { configToInput } from '../../utils'

import MUTATION from './mutations'

type FormValues = {
  stripeProductId: string
  title: string
  slug: string
  description: string
  mailchimpTag: string
}

const MembershipProductsCreate: React.FC = () => (
  <BaseForm<FormValues, API.membership_products_create_mutationVariables>
    initialValues={{
      stripeProductId: '',
      title: '',
      slug: '',
      description: '',
      mailchimpTag: '',
    }}
    validationSchema={{
      stripeProductId: string().required(),
      title: string().required(),
      slug: string().required(),
      description: string().required(),
      mailchimpTag: string().required(),
    }}
    mutation={MUTATION}
    createVariablesFn={(formValues) => formValues}
  >
    <Form.Row>{configToInput(config.STRIPE_PRODUCT_ID)}</Form.Row>

    <Form.Row>{configToInput(config.TITLE)}</Form.Row>

    <Form.Row>{configToInput(config.SLUG)}</Form.Row>

    <Form.Row>{configToInput(config.DESCRIPTION)}</Form.Row>

    <Form.Row>{configToInput(config.MAILCHIMP_TAG)}</Form.Row>
  </BaseForm>
)

export default MembershipProductsCreate
