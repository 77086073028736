import { UserChip } from '@superhi/design'
import React from 'react'

import { API } from '../../../../../api'

type Props = API.CoursePreview

const CoursePreview: React.FC<Props> = ({ id, title, image }) => (
  <UserChip
    name={title}
    image={{ src: image.url || '' }}
    link={{
      href: `/courses/${id}`,
    }}
  />
)

export { default as FRAGMENT } from './fragments'
export default CoursePreview
