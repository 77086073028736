import React from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { array, object, string, number, boolean } from 'yup'
import {
  Button,
  Form,
  FormCheckboxInput,
  FormTextInput,
  FormSelectInput,
  Link,
  Price,
} from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import { formatDateTime } from '../../../../utils'
import SelectVariant from '../../../../components/SelectVariant'
import { configToInput } from '../../utils'

import MUTATION from './mutations'
import { ORDER } from './queries'
import Draft from './Draft'
import { EmptyCheckbox } from './styles'

import querystring from 'querystring'

const REASON_OPTIONS = [
  {
    display: 'Not needed',
    value: 'not-needed',
  },
  {
    display: 'Not as described',
    value: 'not-as-described',
  },
  {
    display: 'None',
    value: 'none',
  },
]

const VALID_ORDER_VERSIONS: API.OrderVersion[] = [API.OrderVersion.THREE_ZERO]

const VALID_ORDER_TYPES: API.OrderType[] = [API.OrderType.NORMAL, API.OrderType.MANUAL]

type RefundLineItem = {
  include: boolean
  maxQuantity: number
  id: string
  quantity: number
  isRefunded: boolean
  reason: string
}

type ExchangeItem = {
  id: string
  quantity: number
}

export type FormData = {
  lineItems: RefundLineItem[]
  exchangeItems?: ExchangeItem[]
  notes?: string
}

const createEmptyExchangeItem = (): ExchangeItem => ({
  id: '',
  quantity: 1,
})

const getQuantityRefunded = (
  orderLineItemId: string,
  refunds: API.refund_create_order_refunds_nodes[],
) =>
  refunds.reduce(
    (acc, refund) =>
      acc +
      (refund.lineItems?.nodes.reduce(
        (acc2, l) => (l.orderLineItem.id === orderLineItemId ? acc2 + l.quantity : acc2),
        0,
      ) || 0),
    0,
  )

const getIsFullyRefunded = (
  orderLineItem: API.refund_create_order_lineItems_nodes,
  refunds: API.refund_create_order_refunds_nodes[],
) => orderLineItem.quantity === getQuantityRefunded(orderLineItem.id, refunds)

const RefundCreate: React.FC = () => {
  const query = querystring.parse(useLocation().search.substr(1))

  const orderId = Array.isArray(query.orderId) ? query.orderId[0] : query.orderId!

  const { data } = useQuery<API.refund_create>(ORDER, {
    variables: {
      id: orderId,
    },
  })

  if (!data?.order) {
    return null
  }

  if (
    !VALID_ORDER_VERSIONS.includes(data.order.version) ||
    !VALID_ORDER_TYPES.includes(data.order.type)
  ) {
    return <div>You can not create a refund for this order.</div>
  }

  return (
    <BaseForm<FormData, API.RefundCreateVariables>
      mutation={MUTATION}
      initialValues={{
        lineItems:
          data.order.lineItems?.nodes.map((lineItem) => ({
            id: lineItem.id,
            isRefunded: getIsFullyRefunded(lineItem, data.order?.refunds?.nodes || []),
            maxQuantity:
              lineItem.quantity -
              getQuantityRefunded(lineItem.id, data.order?.refunds?.nodes || []),
            include: false,
            quantity: 1,
            reason: REASON_OPTIONS[0].value,
          })) || [],
        exchangeItems: [],
        notes: '',
      }}
      validationSchema={{
        lineItems: array()
          .of(
            object({
              id: string().required(),
              include: boolean().required(),
              isRefunded: boolean().required(),
              quantity: number().required(),
              maxQuantity: number().required(),
              reason: string().required(),
              exchangeItem: string(),
            }).required(),
          )
          .required(),
        exchangeItems: array().of(
          object({
            id: string().required(),
            quantity: number().required(),
          }).required(),
        ),
        notes: string(),
      }}
      createVariablesFn={(values) => ({
        orderId,
        lineItems: values.lineItems
          .filter((lineItem) => lineItem.include)
          .map((lineItem) => ({
            id: lineItem.id,
            quantity: lineItem.quantity,
            reason: lineItem.reason,
          })),
        exchangeItems: values.exchangeItems,
        notes: values.notes,
      })}
    >
      <Form.Legend id="xx" label="Customer">
        <div>Name: {data.order.customerDetails.name}</div>
        <div>Email: {data.order.customerDetails.email}</div>

        {data.order.user?.id && (
          <Button version="outline" href={`/users/${data.order.user.id}`}>
            View customer
          </Button>
        )}
      </Form.Legend>

      <Form.Legend id="xx" label="Order">
        <div>ID: #{data.order.id}</div>
        <div>Placed on: {formatDateTime(data.order.insertedAt)}</div>
        <div>
          Discounts:{' '}
          {data.order.discounts?.nodes
            .map((discount) => `${discount.code} - ${discount.percent}%`)
            .join('. ') || 'None'}
        </div>
        <div>
          Total:{' '}
          {data.order ? Price.format(data.order.total.value, data.order.total.currencyCode) : ''}
        </div>

        <Button version="outline" href={`/orders/${data.order.id}`}>
          View order
        </Button>
      </Form.Legend>

      <Form.Legend id="xx" label="Previous refunds">
        {(data.order.refunds?.nodes?.length || 0) > 0
          ? data.order.refunds?.nodes.map((pr) => (
              <div key={pr.id}>
                <Link href={`/refunds/${pr.id}`}>
                  #{pr.id} on {pr.updatedAt}
                </Link>
              </div>
            ))
          : 'None'}
      </Form.Legend>

      <Form.Legend id="xx" label="Line items">
        <Form.Split ratios={{ small: 'full', medium: 'full', large: [10, 30, 10, 30, 20] }}>
          <div>Include</div>
          <div>Product</div>
          <div>Quantity</div>
          <div>Reason</div>
        </Form.Split>

        <Form.Peek<FormData, FormData['lineItems']> name="lineItems">
          {({ meta }) => (
            <>
              {meta.value.map((lineItem, i) => (
                <Form.Split
                  key={lineItem.id}
                  ratios={{ small: 'full', medium: 'full', large: [10, 30, 10, 30, 20] }}
                >
                  <div>
                    {!lineItem.isRefunded ? (
                      <FormCheckboxInput ariaLabel="include" name={`lineItems.${i}.include`} />
                    ) : (
                      <EmptyCheckbox />
                    )}
                  </div>

                  <div>
                    <Link
                      color="BLUE_50"
                      href={`/product-${
                        data.order?.lineItems?.nodes[i].product.__typename === 'ProductCourse'
                          ? 'courses'
                          : 'books'
                      }/${data.order?.lineItems?.nodes[i].product.id}`}
                    >
                      {data.order?.lineItems?.nodes[i].title}
                    </Link>
                  </div>

                  <div>
                    {meta.value[i].include && (
                      <FormTextInput
                        name={`lineItems.${i}.quantity`}
                        type="number"
                        ariaLabel="quantity"
                        min={1}
                        max={lineItem.maxQuantity}
                      />
                    )}
                  </div>
                  <div>
                    {meta.value[i].include && (
                      <FormSelectInput
                        ariaLabel="reason"
                        name={`lineItems.${i}.reason`}
                        options={REASON_OPTIONS}
                      />
                    )}
                  </div>
                </Form.Split>
              ))}
            </>
          )}
        </Form.Peek>
      </Form.Legend>

      <Form.Peek<FormData, FormData['lineItems']> name="lineItems">
        {({ meta }) =>
          meta.value.filter((lineItem) => lineItem.include).length > 0 && (
            <Form.Repeatable<FormData, 'exchangeItems'>
              name="exchangeItems"
              label="Exchange items"
              defaultValue={createEmptyExchangeItem()}
              required={false}
            >
              {(clause) => (
                <SelectVariant
                  ariaLabel="variant"
                  name={`${clause.name}.id`}
                  placeholder="Pick one"
                />
              )}
            </Form.Repeatable>
          )
        }
      </Form.Peek>

      <Form.Row>
        {configToInput({
          label: 'Notes',
          placeholder: "E.g. the customer didn't like the course because of x and y",
          type: 'longtext',
          ariaLabel: 'Notes',
          name: 'notes',
          height: 6,
          required: false,
        })}
      </Form.Row>

      <Draft orderId={orderId} />
    </BaseForm>
  )
}

export default RefundCreate
