import { Price, UserChip } from '@superhi/design'
import React from 'react'

import { API } from '../../../../../api'

const FREQUENCY: Record<API.StripePriceRecurringInterval, string> = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
}
type Props = API.MembershipProductPreview

const MembershipProductPreview: React.FC<Props> = ({ id, title, price }) => (
  <UserChip
    image={{ src: '' }}
    name={title}
    jobTitle={`${Price.format(price.value, price.currencyCode)}${
      price.recurring?.interval ? `per ${FREQUENCY[price.recurring.interval]}` : ''
    }`}
    link={{ href: `/membership-products/${id}` }}
  />
)

export { default as FRAGMENT } from './fragments'
export default MembershipProductPreview
