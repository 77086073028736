import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { string, array, object, number } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import { configToInput } from '../../utils'
import * as config from '../config'

import QUERY, { PRICES } from './queries'
import MUTATION from './mutations'

type FormValues = {
  name: string
  code: string
  conversions: {
    priceId: string
    value: number
  }[]
}

const CurrencyUpdate: React.FC = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.currencies_update, API.currencies_updateVariables>(QUERY, {
    variables: {
      id,
    },
  })
  const { data: pricesData } = useQuery<API.CurrencyUpdatePrices>(PRICES)

  if (!data?.currency || !pricesData?.prices) {
    return null
  }

  return (
    <BaseForm<FormValues, API.CurrencyUpdateMutationVariables>
      mutation={MUTATION}
      validationSchema={{
        name: string().required(),
        code: string().required(),
        conversions: array()
          .of(
            object({
              priceId: string().required(),
              value: number().required(),
            }).required(),
          )
          .required(),
      }}
      initialValues={{
        name: data.currency.name,
        code: data.currency.code,
        conversions: pricesData.prices.nodes.map((price) => ({
          priceId: price.id,
          value: price.conversions.find((conversion) => conversion.currency.id === id)?.value!,
        })),
      }}
      createVariablesFn={(values) => ({
        ...values,
        id,
        conversions: values.conversions.map((conversion) => ({
          priceId: conversion.priceId,
          value: conversion.value,
        })),
      })}
    >
      <Form.Row>{configToInput(config.NAME)}</Form.Row>

      <Form.Row>{configToInput({ ...config.CODE, disabled: true })}</Form.Row>

      <Form.Legend label="Prices">
        {pricesData.prices.nodes.map((price, i) => (
          <Form.Row key={price.id}>
            {configToInput(config.CONVERSIONS_VALUE(`conversions.${i}`, price.name))}
          </Form.Row>
        ))}
      </Form.Legend>
    </BaseForm>
  )
}

export default CurrencyUpdate
