import React from 'react'
import { FormSelectInput } from '@superhi/design'
import countryList from 'country-list'

countryList.overwrite([
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'US',
    name: 'United States',
  },
])

const COUNTRY_OPTIONS = [
  { display: 'Choose one', value: undefined },
  ...countryList
    .getData()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((country) => ({
      display: country.name,
      value: country.name,
    })),
]

type Props = Omit<React.ComponentProps<typeof FormSelectInput>, 'options'>

const SelectCountry: React.FC<Props> = (props) => (
  <FormSelectInput {...props} options={COUNTRY_OPTIONS} />
)

export default SelectCountry
