import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { string } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import { configToInput } from '../../utils'
import Page from '../../../../components/Page'

import QUERY from './queries'
import MUTATION from './mutations'

type FormValues = {
  giftDate: string
}

const OrderUpdate: React.FC = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.orders_update>(QUERY, { variables: { id } })

  if (!data?.order) {
    return null
  }

  const isGift = !!data.order.giftDetails?.email

  if (!isGift) {
    return <Page>Not editable.</Page>
  }

  return (
    <BaseForm<FormValues, API.orders_update_mutationVariables>
      initialValues={{
        giftDate: data.order.giftDetails?.date || '',
      }}
      validationSchema={{
        giftDate: string().required(),
      }}
      mutation={MUTATION}
      createVariablesFn={(formValues) => ({
        id,
        giftDate: formValues.giftDate,
      })}
    >
      <Form.Row>
        {configToInput({
          type: 'date',
          ariaLabel: 'gift date',
          name: 'giftDate',
          required: true,
        })}
      </Form.Row>
    </BaseForm>
  )
}

export default OrderUpdate
