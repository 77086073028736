import React from 'react'
import { useQuery } from '@apollo/client'
import { useFormikContext } from 'formik'
import { Body, CheckboxInput, Form, FormMultiSelectInput } from '@superhi/design'
import FormField from '@superhi/design/dist/cjs/components/forms/inputs/Field/FormField'

import { API } from '../../api'

import QUERY from './queries'

const MultiSelectCourse: React.FC<
  Omit<React.ComponentProps<typeof FormMultiSelectInput>, 'options'> & {
    disabledIds?: string[]
  }
> = ({ disabledIds = [], ...props }) => {
  const formik = useFormikContext()
  const fieldValue = (formik.values as any)[props.name] as string[] | undefined

  const { data } = useQuery<API.MultiSelectCourse>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.courses) {
    return null
  }

  const OPTIONS = data.courses.nodes.map((course) => ({
    value: course.id,
    display: course.title,
  }))

  const handleChange = (value: string) => {
    if (fieldValue?.includes(value)) {
      formik.setFieldValue(
        props.name,
        fieldValue?.filter((v) => v !== value),
      )
    } else {
      formik.setFieldValue(props.name, [...(fieldValue ? fieldValue : []), value])
    }
  }

  return (
    <Form.Legend label={props.label} hint={props.hint}>
      <Form.Rows>
        <Body level="3" color="GREY_70">
          {props.hint}
        </Body>
        <FormField<JSX.IntrinsicElements['select']> {...props}>
          {({ field, helpers, ...rest }) => (
            <>
              {OPTIONS.map((course) => (
                <CheckboxInput
                  ariaLabel="select"
                  id={`${rest.id}_checkbox`}
                  value={fieldValue?.includes(course.value) ? true : false}
                  key={course.value}
                  onChange={() => handleChange(course.value)}
                  disabled={disabledIds.includes(course.value)}
                >
                  {course.display}
                </CheckboxInput>
              ))}
            </>
          )}
        </FormField>
      </Form.Rows>
    </Form.Legend>
  )
}

export default MultiSelectCourse
