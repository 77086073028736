import React from 'react'

import { Wrapper } from './styles'
import DataRow from './DataRow'

type Props = {
  data: Record<string, any>
}

const Data: React.FC<Props> = ({ data }) => (
  <Wrapper>
    <tbody>
      {Object.entries(data).map(([key, value], i) =>
        key === '__typename' ? null : (
          <DataRow key={i} title={key}>
            {value as any}
          </DataRow>
        ),
      )}
    </tbody>
  </Wrapper>
)

export default Data
