import { useMutation } from '@apollo/client'
import { Form, Price } from '@superhi/design'
import { useFormikContext } from 'formik'
import React from 'react'
import { useThrottle } from 'react-use'

import { API } from '../../../../../api'
import { FormData } from '../'

import { REFUND_DRAFT } from './mutations'

type Props = {
  orderId: string
}

const Draft: React.FC<Props> = ({ orderId }) => {
  const formik = useFormikContext()
  const [createDraft, createDraftResponse] = useMutation<
    API.refund_draft,
    API.refund_draftVariables
  >(REFUND_DRAFT)

  const lineItems = React.useMemo(
    () => (formik.values as FormData).lineItems.filter((lineItem) => lineItem.include),
    [formik.values],
  )

  const exchangeItems = React.useMemo(
    () =>
      (formik.values as FormData).exchangeItems?.filter((exchangeItem) => exchangeItem.id !== '') ||
      [],
    [formik.values],
  )

  const throttledLineItems = useThrottle(lineItems, 1000)
  const throttledExchangeItems = useThrottle(exchangeItems, 1000)

  React.useEffect(() => {
    if (throttledLineItems.length > 0) {
      try {
        createDraft({
          variables: {
            orderId,
            lineItems: throttledLineItems.map((lineItem) => ({
              id: lineItem.id,
              quantity: lineItem.quantity,
              reason: lineItem.reason,
            })),
            exchangeItems: throttledExchangeItems,
          },
        })
      } catch (e) {
        console.log(e)
      }
    }
    // }
    /* eslint-disable-next-line */
  }, [throttledLineItems, throttledExchangeItems])

  const refundDraft = lineItems.length > 0 ? createDraftResponse.data?.refundDraft : undefined

  return (
    <Form.Legend id="xx" label="Summary">
      <div>
        Total:{' '}
        {createDraftResponse.loading
          ? 'Loading...'
          : refundDraft && Price.format(refundDraft.total.value, refundDraft.total.currencyCode)}
      </div>
    </Form.Legend>
  )
}

export default Draft
