import React from 'react'
import { string, object, mixed } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import * as config from '../config'
import { configToInput } from '../../utils'

import MUTATION from './mutations'

type FormValues = {
  applicationUrl: string
  body: string
  closingAt: string
  image: {
    url?: string
    value: string
    file: any
  }
  intro: string
  publishedAt: string
  slug: string
  title: string
  winnersNotifiedAt: string
}

const ScholarshipCreate: React.FC = () => {
  return (
    <BaseForm<FormValues, API.ScholarshipCreateMutationVariables>
      initialValues={{
        applicationUrl: '',
        body: '',
        closingAt: '',
        image: {
          url: undefined,
          value: '',
          file: '',
        },
        intro: '',
        publishedAt: '',
        slug: '',
        title: '',
        winnersNotifiedAt: '',
      }}
      validationSchema={{
        applicationUrl: string().required(),
        body: string().required(),
        closingAt: string().required(),
        image: object({
          url: string(),
          value: string().required(),
          file: mixed().required(),
        }).required(),
        intro: string().required(),
        publishedAt: string().required(),
        slug: string().required(),
        title: string().required(),
        winnersNotifiedAt: string().required(),
      }}
      mutation={MUTATION}
      createVariablesFn={(formValues) => ({
        ...formValues,
        image: formValues.image.file,
        publishedAt: new Date(formValues.publishedAt).toISOString(),
        closingAt: new Date(formValues.closingAt).toISOString(),
        winnersNotifiedAt: new Date(formValues.winnersNotifiedAt).toISOString(),
      })}
    >
      <Form.Row>{configToInput(config.TITLE)}</Form.Row>

      <Form.Row>{configToInput(config.SLUG)}</Form.Row>

      <Form.Row>{configToInput(config.INTRO)}</Form.Row>

      <Form.Row>{configToInput(config.IMAGE)}</Form.Row>

      <Form.Row>{configToInput(config.BODY)}</Form.Row>

      <Form.Row>{configToInput(config.APPLICATION_URL)}</Form.Row>

      <Form.Row>{configToInput(config.WINNERS_NOTIFIED_AT)}</Form.Row>

      <Form.Row>{configToInput(config.PUBLISHED_AT)}</Form.Row>

      <Form.Row>{configToInput(config.CLOSING_AT)}</Form.Row>
    </BaseForm>
  )
}

export default ScholarshipCreate
