import { Body, Link, Price } from '@superhi/design'

import { API } from '../../../../../api'

type Props = API.OrderLineItemPreview

const OrderLineItemPreview: React.FC<Props> = ({ product, quantity, price }) => (
  <Body level="3">
    {quantity} x{' '}
    <Link
      href={`/product-${product.__typename === 'ProductCourse' ? 'courses' : 'books'}/${
        product.id
      }`}
    >
      {product.title || 'Unknown product'}
    </Link>{' '}
    – <Price currencyCode={price.currencyCode}>{price.value}</Price>
  </Body>
)

export { default as FRAGMENT } from './fragments'
export default OrderLineItemPreview
