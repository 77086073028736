import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { string, object, mixed } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import { IMAGE, SLUG, TITLE, TYPE, URL, USERS } from '../config'
import { configToInput } from '../../utils'

import QUERY from './queries'
import MUTATION from './mutations'

type FormValues = {
  slug: string
  title: string
  type: API.HallOfFamerType
  url?: string
  userIds?: string
  image: {
    url: string
    value?: string
    file?: any
  }
}

const CurrencyUpdate: React.FC = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.hall_of_fame_update>(QUERY, {
    variables: {
      id,
    },
  })

  if (!data?.hallOfFamer) {
    return null
  }

  return (
    <BaseForm<FormValues, API.HallOfFameItemUpdateVariables>
      mutation={MUTATION}
      validationSchema={{
        slug: string().required(),
        title: string().required(),
        type: string().oneOf(Object.values(API.HallOfFamerType)).required(),
        url: string().when('type', {
          is: API.HallOfFamerType.WEBSITE,
          then: string().required(),
        }),
        userIds: string(), //array().of(string().required()).required(),
        image: object({
          url: string().required(),
          value: string(),
          file: mixed(),
        }).required(),
      }}
      initialValues={{
        slug: data.hallOfFamer.slug,
        title: data.hallOfFamer.title,
        type: data.hallOfFamer.type,
        url: data.hallOfFamer.url || undefined,
        userIds: data.hallOfFamer.users?.map((user) => user.id).join(','),
        image: {
          url: data.hallOfFamer.image.url,
          value: '',
          file: undefined,
        },
      }}
      createVariablesFn={(values) => {
        const userIds = values.userIds?.trim().split(',').filter(Boolean)

        return {
          ...values,
          id,
          image: values.image.file,
          userIds: userIds && userIds.length > 0 ? userIds : undefined,
        }
      }}
    >
      <Form.Row>{configToInput(TITLE)}</Form.Row>

      <Form.Row>{configToInput(SLUG)}</Form.Row>

      <Form.Row>{configToInput(TYPE)}</Form.Row>

      <Form.Peek<FormValues, FormValues['type']> name="type">
        {({ meta }) => (
          <Form.Row>
            {configToInput({ ...URL, required: meta.value === API.HallOfFamerType.WEBSITE })}
          </Form.Row>
        )}
      </Form.Peek>

      <Form.Row>{configToInput(USERS)}</Form.Row>

      <Form.Row>{configToInput({ ...IMAGE, required: false })}</Form.Row>
    </BaseForm>
  )
}

export default CurrencyUpdate
