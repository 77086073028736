import { Body, Button, Link, Price, TextVerticalSpacer } from '@superhi/design'
import DateTime, { formatDateOnly } from '@superhi/design/dist/cjs/components/utils/DateTime'

import { API } from '../../../../../api'
import { getStripeUrl } from '../../../../../utils/stripe'
import { PageView } from '../../../utils'

import { QUERY } from './queries'

const View: PageView<API.teams_view_team> = {
  queryKey: 'team',
  query: QUERY,
  formatData: ({ adminEmail, plan, insertedAt, membership, ...data }) => ({
    ...data,
    admin: data.admin ? (
      <Link href={`/users/${data.admin.id}`}>{data.admin.email}</Link>
    ) : (
      adminEmail
    ),
    users: (
      <TextVerticalSpacer level="3">
        <div>
          {data.users.map((user) => (
            <div key={user.id}>
              <Link href={`/users/${user.id}`}>{user.email}</Link>
            </div>
          ))}
        </div>
        <div>
          <strong>Total: {data.users.length}</strong>
        </div>
      </TextVerticalSpacer>
    ),
    planType: plan,
    membership: membership ? (
      <TextVerticalSpacer level="3">
        <Body level="3">Status: {membership.status}</Body>
        <Body level="3">Auto-renewal: {!!membership.plan?.renewalCost ? 'ON' : 'OFF'}</Body>
        <Body level="3">
          Next payment:{' '}
          {!!membership.plan?.renewalCost
            ? `${Price.format(
                membership.plan?.renewalCost?.value,
                membership.plan?.renewalCost?.currencyCode,
              )} on ${formatDateOnly(membership.plan?.renewalDate)}`
            : 'None'}
        </Body>
        {membership?.plan?.stripeSubscriptionId && (
          <Button
            href={getStripeUrl(`/subscriptions/${membership.plan.stripeSubscriptionId}`)}
            fullWidth
            version="outline"
            isExternal
          >
            View in Subscription in Stripe
          </Button>
        )}
      </TextVerticalSpacer>
    ) : (
      <div>Never purchased</div>
    ),
    createdAt: DateTime.formatStandard(insertedAt),
  }),
}

export default View
