import { Form, FormTextInput } from '@superhi/design'
import React from 'react'
import { string, number } from 'yup'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import { FormValues } from '../types'

import MUTATION from './mutations'

const MembershipAffiliatesCreate: React.FC = () => (
  <BaseForm<FormValues, API.membership_affiliates_create_mutationVariables>
    mutation={MUTATION}
    validationSchema={{
      name: string().required(),
      slug: string().required(),
      stripeAccountId: string().required(),
      applicationFeePercent: number().required(),
      stripeCouponId: string().required(),
    }}
    initialValues={{
      name: '',
      slug: '',
      stripeAccountId: '',
      applicationFeePercent: 20,
      stripeCouponId: '',
    }}
    createVariablesFn={(values) => ({
      ...values,
      applicationFeePercent: values.applicationFeePercent,
    })}
  >
    <Form.Rows>
      <FormTextInput required name="name" label="Name" ariaLabel="Name" placeholder="e.g. AIGA" />

      <FormTextInput required name="slug" label="Slug" ariaLabel="Slug" placeholder="e.g. aiga" />

      <FormTextInput
        required
        name="stripeAccountId"
        placeholder="e.g. acct_1IlB9bPWKkrbl5MQ"
        label="Stripe account ID"
        ariaLabel="Stripe account ID"
      />

      <FormTextInput
        name="stripeCouponId"
        placeholder="e.g. szq0X4W3"
        label="Stripe coupon ID"
        ariaLabel="Stripe coupon ID"
        hint="This will apply a discount to the customers membership"
      />

      <FormTextInput
        required
        type="number"
        name="applicationFeePercent"
        label="Percentage"
        ariaLabel="Percentage"
        placeholder="e.g. 80"
        hint="NOTE: This is the percentage of each transaction that SuperHi will keep"
      />
    </Form.Rows>
  </BaseForm>
)

export default MembershipAffiliatesCreate
