import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { string, number } from 'yup'
import { Form, FormTextInput } from '@superhi/design'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import { FormValues } from '../types'

import QUERY from './queries'
import MUTATION from './mutations'

const MembershipAffiliatesUpdate: React.FC = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<
    API.membership_affiliates_update,
    API.membership_affiliates_updateVariables
  >(QUERY, {
    variables: {
      id,
    },
  })

  if (!data?.membershipAffiliate) {
    return null
  }

  return (
    <BaseForm<FormValues, API.membership_affiliates_update_mutationVariables>
      mutation={MUTATION}
      validationSchema={{
        name: string().required(),
        slug: string().required(),
        stripeAccountId: string().required(),
        applicationFeePercent: number().required(),
        stripeCouponId: string().required(),
      }}
      initialValues={{
        name: data.membershipAffiliate.name,
        slug: data.membershipAffiliate.slug,
        stripeAccountId: data.membershipAffiliate.stripeAccountId,
        applicationFeePercent: data.membershipAffiliate.applicationFeePercent,
        stripeCouponId: data.membershipAffiliate.stripeCouponId || '',
      }}
      createVariablesFn={(values) => ({
        ...values,
        id,
      })}
    >
      <Form.Rows>
        <FormTextInput required name="name" label="Name" ariaLabel="Name" placeholder="e.g. AIGA" />

        <FormTextInput required name="slug" label="Slug" ariaLabel="Slug" placeholder="e.g. aiga" />

        <FormTextInput
          required
          name="stripeAccountId"
          placeholder="e.g. acct_1IlB9bPWKkrbl5MQ"
          label="Stripe account ID"
          ariaLabel="Stripe account ID"
        />

        <FormTextInput
          name="stripeCouponId"
          placeholder="e.g. szq0X4W3"
          label="Stripe coupon ID"
          ariaLabel="Stripe coupon ID"
          hint="This will apply a discount to the customers membership"
        />

        <FormTextInput
          required
          type="number"
          name="applicationFeePercent"
          label="Percentage"
          ariaLabel="Percentage"
          placeholder="e.g. 80"
          hint="NOTE: This is the percentage of each transaction that SuperHi will keep"
        />
      </Form.Rows>
    </BaseForm>
  )
}

export default MembershipAffiliatesUpdate
