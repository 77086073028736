import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { API } from '../../../../api'
import Form from '../Form'
import { transformDiscountClauses } from '../utils'

import QUERY from './queries'
import MUTATION from './mutations'

const DiscountUpdate: React.FC = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.discounts_update>(QUERY, {
    variables: {
      id,
    },
  })

  if (!data?.discount) {
    return null
  }

  return (
    <Form<API.DiscountUpdateMutationVariables>
      initialValues={{
        name: data.discount.name,
        type: data.discount.type,
        code: data.discount.code,
        description: data.discount.description,

        isPaused: data.discount.isPaused,
        isStackable: data.discount.isStackable,

        percent: data.discount.percent,

        clauses: transformDiscountClauses(data.discount.clauses),

        hasStartAt: !!data.discount.startAt,
        startAt: data.discount.startAt ? data.discount.startAt.slice(0, -1) : undefined,
        hasEndAt: !!data.discount.endAt,
        endAt: data.discount.endAt ? data.discount.endAt.slice(0, -1) : undefined,
      }}
      mutation={MUTATION}
      createVariablesFn={({ hasEndAt, hasStartAt, ...formValues }) => ({
        ...formValues,
        id,
        startAt:
          hasStartAt && formValues.startAt ? new Date(formValues.startAt).toISOString() : undefined,
        endAt: hasEndAt && formValues.endAt ? new Date(formValues.endAt).toISOString() : undefined,
      })}
    />
  )
}

export default DiscountUpdate
