import { UserChip } from '@superhi/design'
import React from 'react'

import { API } from '../../../../../api'

type Props = API.UserPreviewComponent

const UserPreview: React.FC<Props> = ({ id, email, name, avatar, type }) => (
  <UserChip
    name={`${name || 'No name'} (${id}) (${type})`}
    level="2"
    jobTitle={email}
    link={{ href: `/users/${id}` }}
    image={{ src: avatar?.url || '' }}
  />
)
//   {/* {membership?.status && <Tag level="2">Membership {membership?.status}</Tag>}
//   {isFrozen && (
//     <Tag color="red" level="2">
//       FROZEN
//     </Tag>
//   )}
// </div>
// </HorizontalSpacer>
// </Card> */}

export { default as FRAGMENT } from './fragments'
export default UserPreview
