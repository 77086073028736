import { Button } from '@superhi/design'
import React from 'react'
// import { useQuery } from '@apollo/client';

import Page from '../../components/Page'
// import { API } from '../../api';
// import { formatDateTimeDistance } from '../../utils';

// import QUERY from './queries';
// import * as styles from './styles';

const Deploy: React.FC = () => {
  return (
    <Page>
      <Button
        onClick={() => {
          window
            .fetch(
              'https://api.vercel.com/v1/integrations/deploy/prj_jAeLZw40POhwf79X0BewfUf3H2ft/N5ugZhk6et',
              {
                method: 'post',
              },
            )
            .then(() => {
              alert('Deployment triggered! This will take a few minutes to complete.')
            })
        }}
      >
        Re-deploy marketing site
      </Button>
    </Page>
  )
}

export default Deploy
