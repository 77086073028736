/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HeaderSession
// ====================================================

export interface HeaderSession_session_avatar {
  __typename: 'Image'
  url: string
}

export interface HeaderSession_session {
  __typename: 'Session'
  id: string
  name: string | null
  username: string
  email: string
  token: string | null
  avatar: HeaderSession_session_avatar | null
}

export interface HeaderSession {
  /**
   * Get the session of the current user
   */
  session: HeaderSession_session | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: URGH
// ====================================================

export interface URGH_session {
  __typename: 'Session'
  id: string
  isAdmin: boolean
}

export interface URGH {
  /**
   * Get the session of the current user
   */
  session: URGH_session | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MultiSelectCourseChapters
// ====================================================

export interface MultiSelectCourseChapters_courseChapters_nodes {
  __typename: 'CourseChapter'
  id: string
  title: string
}

export interface MultiSelectCourseChapters_courseChapters {
  __typename: 'CourseChapterList'
  nodes: MultiSelectCourseChapters_courseChapters_nodes[]
}

export interface MultiSelectCourseChapters {
  /**
   * Get all course chapters
   */
  courseChapters: MultiSelectCourseChapters_courseChapters | null
}

export interface MultiSelectCourseChaptersVariables {
  ids?: string[] | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MultiSelectCourseChapters_Search
// ====================================================

export interface MultiSelectCourseChapters_Search_courseChapters_nodes {
  __typename: 'CourseChapter'
  id: string
  title: string
}

export interface MultiSelectCourseChapters_Search_courseChapters {
  __typename: 'CourseChapterList'
  nodes: MultiSelectCourseChapters_Search_courseChapters_nodes[]
}

export interface MultiSelectCourseChapters_Search {
  /**
   * Get all course chapters
   */
  courseChapters: MultiSelectCourseChapters_Search_courseChapters | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MultiSelectCourseLessons
// ====================================================

export interface MultiSelectCourseLessons_courseLessons_nodes {
  __typename: 'CourseLesson'
  id: string
  title: string
}

export interface MultiSelectCourseLessons_courseLessons {
  __typename: 'CourseLessonList'
  nodes: MultiSelectCourseLessons_courseLessons_nodes[]
}

export interface MultiSelectCourseLessons {
  /**
   * Get all course lessons
   */
  courseLessons: MultiSelectCourseLessons_courseLessons | null
}

export interface MultiSelectCourseLessonsVariables {
  ids?: string[] | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MultiSelectCourseLessons_Search
// ====================================================

export interface MultiSelectCourseLessons_Search_courseLessons_nodes {
  __typename: 'CourseLesson'
  id: string
  title: string
}

export interface MultiSelectCourseLessons_Search_courseLessons {
  __typename: 'CourseLessonList'
  nodes: MultiSelectCourseLessons_Search_courseLessons_nodes[]
}

export interface MultiSelectCourseLessons_Search {
  /**
   * Get all course lessons
   */
  courseLessons: MultiSelectCourseLessons_Search_courseLessons | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormSelectCourseHomework
// ====================================================

export interface FormSelectCourseHomework_courseHomeworks_nodes {
  __typename: 'CourseHomework'
  id: string
  title: string
}

export interface FormSelectCourseHomework_courseHomeworks {
  __typename: 'CourseHomeworkList'
  nodes: FormSelectCourseHomework_courseHomeworks_nodes[]
}

export interface FormSelectCourseHomework {
  /**
   * Get all course homeworks
   */
  courseHomeworks: FormSelectCourseHomework_courseHomeworks | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormSelectCourseProject
// ====================================================

export interface FormSelectCourseProject_courseProjects_nodes {
  __typename: 'CourseProject'
  id: string
  title: string
}

export interface FormSelectCourseProject_courseProjects {
  __typename: 'CourseProjectList'
  nodes: FormSelectCourseProject_courseProjects_nodes[]
}

export interface FormSelectCourseProject {
  /**
   * Get all course projects
   */
  courseProjects: FormSelectCourseProject_courseProjects | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MultiSelectCourse
// ====================================================

export interface MultiSelectCourse_courses_nodes {
  __typename: 'Course'
  id: string
  title: string
}

export interface MultiSelectCourse_courses {
  __typename: 'CourseList'
  nodes: MultiSelectCourse_courses_nodes[]
}

export interface MultiSelectCourse {
  /**
   * Get the current user's courses
   */
  courses: MultiSelectCourse_courses | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MultiSelectPerson
// ====================================================

export interface MultiSelectPerson_people_nodes {
  __typename: 'Person'
  id: string
  name: string
}

export interface MultiSelectPerson_people {
  __typename: 'PersonList'
  nodes: MultiSelectPerson_people_nodes[]
}

export interface MultiSelectPerson {
  /**
   * Get all people
   */
  people: MultiSelectPerson_people | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MultiSelectProduct
// ====================================================

export interface MultiSelectProduct_products_nodes {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
  title: string
}

export interface MultiSelectProduct_products {
  __typename: 'ProductList'
  nodes: MultiSelectProduct_products_nodes[]
}

export interface MultiSelectProduct {
  /**
   * Get all products
   */
  products: MultiSelectProduct_products | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MultiSelectProductCourse
// ====================================================

export interface MultiSelectProductCourse_productCourses_nodes {
  __typename: 'ProductCourse'
  id: string
  title: string
}

export interface MultiSelectProductCourse_productCourses {
  __typename: 'ProductCourseList'
  nodes: MultiSelectProductCourse_productCourses_nodes[]
}

export interface MultiSelectProductCourse {
  /**
   * Get all product courses
   */
  productCourses: MultiSelectProductCourse_productCourses | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MultiSelectProductFulfillable
// ====================================================

export interface MultiSelectProductFulfillable_productFulfillables_nodes {
  __typename: 'ProductFulfillable'
  id: string
  title: string
}

export interface MultiSelectProductFulfillable_productFulfillables {
  __typename: 'ProductFulfillableList'
  nodes: MultiSelectProductFulfillable_productFulfillables_nodes[]
}

export interface MultiSelectProductFulfillable {
  /**
   * Get all product fulfillables
   */
  productFulfillables: MultiSelectProductFulfillable_productFulfillables | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MultiSelectProductVariant
// ====================================================

export interface MultiSelectProductVariant_productVariants_nodes {
  __typename: 'ProductVariant'
  id: string
  title: string
}

export interface MultiSelectProductVariant_productVariants {
  __typename: 'ProductVariantList'
  nodes: MultiSelectProductVariant_productVariants_nodes[]
}

export interface MultiSelectProductVariant {
  /**
   * Get all product variants
   */
  productVariants: MultiSelectProductVariant_productVariants | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectCourse
// ====================================================

export interface SelectCourse_courses_nodes {
  __typename: 'Course'
  id: string
  title: string
}

export interface SelectCourse_courses {
  __typename: 'CourseList'
  nodes: SelectCourse_courses_nodes[]
}

export interface SelectCourse {
  /**
   * Get the current user's courses
   */
  courses: SelectCourse_courses | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectCurrency
// ====================================================

export interface SelectCurrency_currencies_nodes {
  __typename: 'Currency'
  id: string
  name: string
  code: CurrencyCode
}

export interface SelectCurrency_currencies {
  __typename: 'CurrencyList'
  nodes: SelectCurrency_currencies_nodes[]
}

export interface SelectCurrency {
  /**
   * Get all currencies
   */
  currencies: SelectCurrency_currencies | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectPrice
// ====================================================

export interface SelectPrice_prices_nodes {
  __typename: 'Price'
  id: string
  name: string
}

export interface SelectPrice_prices {
  __typename: 'PriceList'
  nodes: SelectPrice_prices_nodes[]
}

export interface SelectPrice {
  /**
   * Get all prices
   */
  prices: SelectPrice_prices | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectVariant
// ====================================================

export interface SelectVariant_productVariants_nodes {
  __typename: 'ProductVariant'
  id: string
  title: string
}

export interface SelectVariant_productVariants {
  __typename: 'ProductVariantList'
  nodes: SelectVariant_productVariants_nodes[]
}

export interface SelectVariant {
  /**
   * Get all product variants
   */
  productVariants: SelectVariant_productVariants | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Home
// ====================================================

export interface Home_users_nodes {
  __typename: 'User'
  id: string
  name: string | null
  email: string
  insertedAt: any
}

export interface Home_users {
  __typename: 'UserList'
  totalCount: number
  nodes: Home_users_nodes[]
}

export interface Home_orders_nodes_customerDetails {
  __typename: 'OrderCustomerDetails'
  email: string
}

export interface Home_orders_nodes_lineItems_nodes_product {
  __typename: 'ProductBook' | 'ProductCourse'
  title: string
}

export interface Home_orders_nodes_lineItems_nodes {
  __typename: 'OrderLineItem'
  product: Home_orders_nodes_lineItems_nodes_product
}

export interface Home_orders_nodes_lineItems {
  __typename: 'OrderLineItemList'
  nodes: Home_orders_nodes_lineItems_nodes[]
}

export interface Home_orders_nodes_total {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface Home_orders_nodes {
  __typename: 'Order'
  id: string
  customerDetails: Home_orders_nodes_customerDetails
  lineItems: Home_orders_nodes_lineItems | null
  total: Home_orders_nodes_total
  insertedAt: any
}

export interface Home_orders {
  __typename: 'OrderList'
  totalCount: number
  nodes: Home_orders_nodes[]
}

export interface Home {
  /**
   * All users
   */
  users: Home_users | null
  /**
   * Get the current user's orders
   */
  orders: Home_orders | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: collaborators_index
// ====================================================

export interface collaborators_index_collaborators_productCourses {
  __typename: 'ProductCourse'
  id: string
  title: string
}

export interface collaborators_index_collaborators {
  __typename: 'Collaborator'
  id: string
  name: string
  productCourses: collaborators_index_collaborators_productCourses[]
}

export interface collaborators_index {
  /**
   * Get collaborators
   */
  collaborators: collaborators_index_collaborators[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: collaborators_view
// ====================================================

export interface collaborators_view_collaborator_payouts_values {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface collaborators_view_collaborator_payouts_orders {
  __typename: 'Order'
  id: string
}

export interface collaborators_view_collaborator_payouts {
  __typename: 'CollaboratorPayout'
  startDate: any
  endDate: any
  values: collaborators_view_collaborator_payouts_values[]
  orders: collaborators_view_collaborator_payouts_orders[]
}

export interface collaborators_view_collaborator_productCourses_image {
  __typename: 'Image'
  url: string
}

export interface collaborators_view_collaborator_productCourses {
  __typename: 'ProductCourse'
  id: string
  title: string
  image: collaborators_view_collaborator_productCourses_image
}

export interface collaborators_view_collaborator {
  __typename: 'Collaborator'
  id: string
  name: string
  payouts: collaborators_view_collaborator_payouts[]
  productCourses: collaborators_view_collaborator_productCourses[]
}

export interface collaborators_view {
  /**
   * Get collaborator by it
   */
  collaborator: collaborators_view_collaborator | null
}

export interface collaborators_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_chapters_create_mutation
// ====================================================

export interface course_chapters_create_mutation_courseChapterCreate {
  __typename: 'CourseChapter'
  id: string
}

export interface course_chapters_create_mutation {
  courseChapterCreate: course_chapters_create_mutation_courseChapterCreate | null
}

export interface course_chapters_create_mutationVariables {
  homeworkId?: string | null
  projectId?: string | null
  resourceUrl?: string | null
  wistiaProjectId: string
  isLocked: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_chapters_delete
// ====================================================

export interface course_chapters_delete_courseChapterDelete {
  __typename: 'CourseChapter'
  id: string
}

export interface course_chapters_delete {
  courseChapterDelete: course_chapters_delete_courseChapterDelete | null
}

export interface course_chapters_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_chapters_index
// ====================================================

export interface course_chapters_index_courseChapters_nodes {
  __typename: 'CourseChapter'
  id: string
  title: string
}

export interface course_chapters_index_courseChapters_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface course_chapters_index_courseChapters {
  __typename: 'CourseChapterList'
  nodes: course_chapters_index_courseChapters_nodes[]
  totalCount: number
  pageInfo: course_chapters_index_courseChapters_pageInfo
}

export interface course_chapters_index {
  /**
   * Get all course chapters
   */
  courseChapters: course_chapters_index_courseChapters | null
}

export interface course_chapters_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_chapters_update_mutation
// ====================================================

export interface course_chapters_update_mutation_courseChapterUpdate {
  __typename: 'CourseChapter'
  id: string
}

export interface course_chapters_update_mutation {
  courseChapterUpdate: course_chapters_update_mutation_courseChapterUpdate | null
}

export interface course_chapters_update_mutationVariables {
  id: string
  slug: string
  title: string
  description: string
  lessons: string[]
  homeworkId?: string | null
  projectId?: string | null
  resourceUrl?: string | null
  isLocked: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_chapters_update
// ====================================================

export interface course_chapters_update_courseChapter_homework {
  __typename: 'CourseHomework'
  id: string
}

export interface course_chapters_update_courseChapter_lessons_nodes {
  __typename: 'CourseLesson'
  id: string
}

export interface course_chapters_update_courseChapter_lessons {
  __typename: 'CourseLessonList'
  nodes: course_chapters_update_courseChapter_lessons_nodes[]
}

export interface course_chapters_update_courseChapter_project {
  __typename: 'CourseProject'
  id: string
}

export interface course_chapters_update_courseChapter {
  __typename: 'CourseChapter'
  id: string
  description: string
  isLocked: boolean
  homework: course_chapters_update_courseChapter_homework | null
  lessons: course_chapters_update_courseChapter_lessons | null
  position: number
  project: course_chapters_update_courseChapter_project | null
  resourceUrl: string | null
  slug: string
  title: string
  wistiaProjectId: string | null
}

export interface course_chapters_update {
  /**
   * Get a course chapter by it's id
   */
  courseChapter: course_chapters_update_courseChapter | null
}

export interface course_chapters_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_chapters_view
// ====================================================

export interface course_chapters_view_courseChapter_lessons_nodes {
  __typename: 'CourseLesson'
  id: string
  title: string
  position: number
}

export interface course_chapters_view_courseChapter_lessons {
  __typename: 'CourseLessonList'
  nodes: course_chapters_view_courseChapter_lessons_nodes[]
}

export interface course_chapters_view_courseChapter_project {
  __typename: 'CourseProject'
  id: string
  title: string
}

export interface course_chapters_view_courseChapter_homework {
  __typename: 'CourseHomework'
  id: string
  title: string
}

export interface course_chapters_view_courseChapter {
  __typename: 'CourseChapter'
  id: string
  slug: string
  title: string
  description: string
  resourceUrl: string | null
  wistiaProjectId: string | null
  position: number
  isLocked: boolean
  lessons: course_chapters_view_courseChapter_lessons | null
  project: course_chapters_view_courseChapter_project | null
  homework: course_chapters_view_courseChapter_homework | null
}

export interface course_chapters_view {
  /**
   * Get a course chapter by it's id
   */
  courseChapter: course_chapters_view_courseChapter | null
}

export interface course_chapters_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_homeworks_create_mutation
// ====================================================

export interface course_homeworks_create_mutation_courseHomeworkCreate {
  __typename: 'CourseHomework'
  id: string
}

export interface course_homeworks_create_mutation {
  courseHomeworkCreate: course_homeworks_create_mutation_courseHomeworkCreate | null
}

export interface course_homeworks_create_mutationVariables {
  description: string
  files?: CourseAssetFileCreateInput[] | null
  finishedUrl?: string | null
  briefUrl?: string | null
  image: any
  links?: CourseAssetLinkCreateInput[] | null
  slug: string
  title: string
  isRequired: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_homeworks_delete
// ====================================================

export interface course_homeworks_delete_courseHomeworkDelete {
  __typename: 'CourseHomework'
  id: string
}

export interface course_homeworks_delete {
  courseHomeworkDelete: course_homeworks_delete_courseHomeworkDelete | null
}

export interface course_homeworks_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_homeworks_index
// ====================================================

export interface course_homeworks_index_courseHomeworks_nodes {
  __typename: 'CourseHomework'
  id: string
  title: string
}

export interface course_homeworks_index_courseHomeworks_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface course_homeworks_index_courseHomeworks {
  __typename: 'CourseHomeworkList'
  nodes: course_homeworks_index_courseHomeworks_nodes[]
  totalCount: number
  pageInfo: course_homeworks_index_courseHomeworks_pageInfo
}

export interface course_homeworks_index {
  /**
   * Get all course homeworks
   */
  courseHomeworks: course_homeworks_index_courseHomeworks | null
}

export interface course_homeworks_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_homeworks_update_mutation
// ====================================================

export interface course_homeworks_update_mutation_courseHomeworkUpdate {
  __typename: 'CourseHomework'
  id: string
}

export interface course_homeworks_update_mutation {
  courseHomeworkUpdate: course_homeworks_update_mutation_courseHomeworkUpdate | null
}

export interface course_homeworks_update_mutationVariables {
  id: string
  description: string
  finishedUrl?: string | null
  briefUrl?: string | null
  image?: any | null
  slug: string
  title: string
  isRequired: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_homeworks_update
// ====================================================

export interface course_homeworks_update_courseHomework_files {
  __typename: 'CourseAssetFile'
  url: string
}

export interface course_homeworks_update_courseHomework_image {
  __typename: 'Image'
  id: string
  url: string
}

export interface course_homeworks_update_courseHomework_links {
  __typename: 'CourseAssetLink'
  id: string
  url: string
}

export interface course_homeworks_update_courseHomework {
  __typename: 'CourseHomework'
  id: string
  description: string
  files: course_homeworks_update_courseHomework_files[] | null
  finishedUrl: string | null
  briefUrl: string | null
  isRequired: boolean
  image: course_homeworks_update_courseHomework_image
  links: course_homeworks_update_courseHomework_links[] | null
  slug: string
  title: string
}

export interface course_homeworks_update {
  /**
   * Get a course homework by it's id
   */
  courseHomework: course_homeworks_update_courseHomework | null
}

export interface course_homeworks_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_homeworks_view
// ====================================================

export interface course_homeworks_view_courseHomework_image {
  __typename: 'Image'
  url: string
}

export interface course_homeworks_view_courseHomework {
  __typename: 'CourseHomework'
  id: string
  slug: string
  title: string
  description: string
  briefUrl: string | null
  finishedUrl: string | null
  image: course_homeworks_view_courseHomework_image
}

export interface course_homeworks_view {
  /**
   * Get a course homework by it's id
   */
  courseHomework: course_homeworks_view_courseHomework | null
}

export interface course_homeworks_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_lessons_create_mutation
// ====================================================

export interface course_lessons_create_mutation_courseLessonCreate {
  __typename: 'CourseLesson'
  id: string
}

export interface course_lessons_create_mutation {
  courseLessonCreate: course_lessons_create_mutation_courseLessonCreate | null
}

export interface course_lessons_create_mutationVariables {
  wistiaVideoId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_lessons_delete
// ====================================================

export interface course_lessons_delete_courseLessonDelete {
  __typename: 'CourseLesson'
  id: string
}

export interface course_lessons_delete {
  courseLessonDelete: course_lessons_delete_courseLessonDelete | null
}

export interface course_lessons_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_lessons_index
// ====================================================

export interface course_lessons_index_courseLessons_nodes {
  __typename: 'CourseLesson'
  id: string
  title: string
}

export interface course_lessons_index_courseLessons_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface course_lessons_index_courseLessons {
  __typename: 'CourseLessonList'
  nodes: course_lessons_index_courseLessons_nodes[]
  totalCount: number
  pageInfo: course_lessons_index_courseLessons_pageInfo
}

export interface course_lessons_index {
  /**
   * Get all course lessons
   */
  courseLessons: course_lessons_index_courseLessons | null
}

export interface course_lessons_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_lessons_update_mutation
// ====================================================

export interface course_lessons_update_mutation_courseLessonUpdate {
  __typename: 'CourseLesson'
  id: string
}

export interface course_lessons_update_mutation {
  courseLessonUpdate: course_lessons_update_mutation_courseLessonUpdate | null
}

export interface course_lessons_update_mutationVariables {
  id: string
  description?: string | null
  slug: string
  title: string
  wistiaVideoId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_lessons_update
// ====================================================

export interface course_lessons_update_courseLesson {
  __typename: 'CourseLesson'
  description: string | null
  id: string
  slug: string
  title: string
  wistiaId: string
}

export interface course_lessons_update {
  /**
   * Get a course lesson by it's id
   */
  courseLesson: course_lessons_update_courseLesson | null
}

export interface course_lessons_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_lessons_view
// ====================================================

export interface course_lessons_view_courseLesson {
  __typename: 'CourseLesson'
  id: string
  slug: string
  title: string
  description: string | null
  duration: number
  position: number
  wistiaId: string
}

export interface course_lessons_view {
  /**
   * Get a course lesson by it's id
   */
  courseLesson: course_lessons_view_courseLesson | null
}

export interface course_lessons_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_projects_create_mutation
// ====================================================

export interface course_projects_create_mutation_courseProjectCreate {
  __typename: 'CourseProject'
  id: string
}

export interface course_projects_create_mutation {
  courseProjectCreate: course_projects_create_mutation_courseProjectCreate | null
}

export interface course_projects_create_mutationVariables {
  description?: string | null
  finishedUrl?: string | null
  image: any
  slug: string
  title: string
  briefUrl?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_projects_delete
// ====================================================

export interface course_projects_delete_courseProjectDelete {
  __typename: 'CourseProject'
  id: string
}

export interface course_projects_delete {
  courseProjectDelete: course_projects_delete_courseProjectDelete | null
}

export interface course_projects_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_projects_index
// ====================================================

export interface course_projects_index_courseProjects_nodes {
  __typename: 'CourseProject'
  id: string
  title: string
}

export interface course_projects_index_courseProjects_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface course_projects_index_courseProjects {
  __typename: 'CourseProjectList'
  nodes: course_projects_index_courseProjects_nodes[]
  totalCount: number
  pageInfo: course_projects_index_courseProjects_pageInfo
}

export interface course_projects_index {
  /**
   * Get all course projects
   */
  courseProjects: course_projects_index_courseProjects | null
}

export interface course_projects_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: course_projects_update_mutation
// ====================================================

export interface course_projects_update_mutation_courseProjectUpdate {
  __typename: 'CourseProject'
  id: string
}

export interface course_projects_update_mutation {
  courseProjectUpdate: course_projects_update_mutation_courseProjectUpdate | null
}

export interface course_projects_update_mutationVariables {
  id: string
  description?: string | null
  finishedUrl?: string | null
  image?: any | null
  slug: string
  title: string
  briefUrl?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_projects_update
// ====================================================

export interface course_projects_update_courseProject_files {
  __typename: 'CourseAssetFile'
  url: string
}

export interface course_projects_update_courseProject_image {
  __typename: 'Image'
  id: string
  url: string
}

export interface course_projects_update_courseProject_links {
  __typename: 'CourseAssetLink'
  id: string
  url: string
}

export interface course_projects_update_courseProject {
  __typename: 'CourseProject'
  id: string
  description: string | null
  files: course_projects_update_courseProject_files[] | null
  finishedUrl: string | null
  briefUrl: string | null
  image: course_projects_update_courseProject_image
  links: course_projects_update_courseProject_links[] | null
  slug: string
  title: string
}

export interface course_projects_update {
  /**
   * Get a course project by it's id
   */
  courseProject: course_projects_update_courseProject | null
}

export interface course_projects_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_projects_view
// ====================================================

export interface course_projects_view_courseProject_image {
  __typename: 'Image'
  url: string
}

export interface course_projects_view_courseProject {
  __typename: 'CourseProject'
  id: string
  slug: string
  title: string
  description: string | null
  briefUrl: string | null
  finishedUrl: string | null
  image: course_projects_view_courseProject_image
}

export interface course_projects_view {
  /**
   * Get a course project by it's id
   */
  courseProject: course_projects_view_courseProject | null
}

export interface course_projects_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: courses_create_mutation
// ====================================================

export interface courses_create_mutation_courseCreate {
  __typename: 'Course'
  id: string
}

export interface courses_create_mutation {
  courseCreate: courses_create_mutation_courseCreate | null
}

export interface courses_create_mutationVariables {
  description: string
  difficultyLevel: CourseDifficultyLevel
  image: any
  instructors: string[]
  length: CourseLength
  mailchimpTag: string
  order: number
  shortDescription: string
  slackChannelId: string
  slackChannelName: string
  syllabusUrl: string
  slug: string
  subjects: CourseSubject[]
  title: string
  wistiaProjectIds?: string[] | null
  isBeta: boolean
  overview: string[]
  targetAudience: string
  requirements: string
  resourceUrl?: string | null
  hasAllChapters: boolean
  status: CourseStatus
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: courses_index
// ====================================================

export interface courses_index_courses_nodes {
  __typename: 'Course'
  id: string
  title: string
  isBeta: boolean
}

export interface courses_index_courses_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface courses_index_courses {
  __typename: 'CourseList'
  nodes: courses_index_courses_nodes[]
  totalCount: number
  pageInfo: courses_index_courses_pageInfo
}

export interface courses_index {
  /**
   * Get the current user's courses
   */
  courses: courses_index_courses | null
}

export interface courses_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: courses_update_mutation
// ====================================================

export interface courses_update_mutation_courseUpdate {
  __typename: 'Course'
  id: string
}

export interface courses_update_mutation {
  courseUpdate: courses_update_mutation_courseUpdate | null
}

export interface courses_update_mutationVariables {
  id: string
  description: string
  difficultyLevel: CourseDifficultyLevel
  image?: any | null
  instructors: string[]
  length: CourseLength
  mailchimpTag: string
  order: number
  requirements: string
  shortDescription: string
  slackChannelId: string
  slackChannelName: string
  syllabusUrl: string
  slug: string
  subjects: CourseSubject[]
  title: string
  chapterIds?: string[] | null
  isBeta: boolean
  overview: string[]
  targetAudience: string
  resourceUrl?: string | null
  hasAllChapters: boolean
  status: CourseStatus
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: courses_update
// ====================================================

export interface courses_update_course_image {
  __typename: 'Image'
  url: string
}

export interface courses_update_course_instructors {
  __typename: 'Person'
  id: string
}

export interface courses_update_course_chapters_nodes {
  __typename: 'CourseChapter'
  id: string
}

export interface courses_update_course_chapters {
  __typename: 'CourseChapterList'
  nodes: courses_update_course_chapters_nodes[]
}

export interface courses_update_course {
  __typename: 'Course'
  id: string
  description: string
  difficultyLevel: CourseDifficultyLevel
  image: courses_update_course_image
  instructors: courses_update_course_instructors[]
  isBeta: boolean
  length: CourseLength
  resourceUrl: string | null
  mailchimpTag: string
  requirements: string
  shortDescription: string
  status: CourseStatus
  order: number
  hasAllChapters: boolean
  slackChannelId: string
  slackChannelName: string
  syllabusUrl: string
  overview: string[]
  targetAudience: string
  slug: string
  subjects: CourseSubject[]
  title: string
  chapters: courses_update_course_chapters | null
}

export interface courses_update {
  /**
   * Get course
   */
  course: courses_update_course | null
}

export interface courses_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: courses_view
// ====================================================

export interface courses_view_course_chapters_nodes_lessons_nodes {
  __typename: 'CourseLesson'
  id: string
  title: string
  position: number
}

export interface courses_view_course_chapters_nodes_lessons {
  __typename: 'CourseLessonList'
  nodes: courses_view_course_chapters_nodes_lessons_nodes[]
}

export interface courses_view_course_chapters_nodes_project {
  __typename: 'CourseProject'
  id: string
  title: string
}

export interface courses_view_course_chapters_nodes_homework {
  __typename: 'CourseHomework'
  id: string
  title: string
}

export interface courses_view_course_chapters_nodes {
  __typename: 'CourseChapter'
  id: string
  title: string
  position: number
  lessons: courses_view_course_chapters_nodes_lessons | null
  project: courses_view_course_chapters_nodes_project | null
  homework: courses_view_course_chapters_nodes_homework | null
}

export interface courses_view_course_chapters {
  __typename: 'CourseChapterList'
  nodes: courses_view_course_chapters_nodes[]
}

export interface courses_view_course_image {
  __typename: 'Image'
  url: string
}

export interface courses_view_course_instructors_image {
  __typename: 'Image'
  url: string
}

export interface courses_view_course_instructors {
  __typename: 'Person'
  id: string
  name: string
  image: courses_view_course_instructors_image
}

export interface courses_view_course {
  __typename: 'Course'
  id: string
  title: string
  slug: string
  shortDescription: string
  description: string
  subjects: CourseSubject[]
  difficultyLevel: CourseDifficultyLevel
  slackChannelId: string
  slackChannelName: string
  resourceUrl: string | null
  length: CourseLength
  isBeta: boolean
  chapters: courses_view_course_chapters | null
  image: courses_view_course_image
  instructors: courses_view_course_instructors[]
  mailchimpTag: string
  requirements: string
}

export interface courses_view {
  /**
   * Get course
   */
  course: courses_view_course | null
}

export interface courses_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_watched_summaries_index
// ====================================================

export interface course_watched_summaries_index_courses_nodes {
  __typename: 'Course'
  id: string
  title: string
}

export interface course_watched_summaries_index_courses {
  __typename: 'CourseList'
  nodes: course_watched_summaries_index_courses_nodes[]
}

export interface course_watched_summaries_index {
  /**
   * Get the current user's courses
   */
  courses: course_watched_summaries_index_courses | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: course_watched_summaries_view
// ====================================================

export interface course_watched_summaries_view_courseWatchedSummaries_course {
  __typename: 'Course'
  id: string
  title: string
  watchedPercentageThreshold: number
}

export interface course_watched_summaries_view_courseWatchedSummaries {
  __typename: 'CourseWatchedSummary'
  id: string
  course: course_watched_summaries_view_courseWatchedSummaries_course
  date: any
  total: number
}

export interface course_watched_summaries_view {
  /**
   * Get the coursw watched summaries for a given course
   */
  courseWatchedSummaries: course_watched_summaries_view_courseWatchedSummaries[] | null
}

export interface course_watched_summaries_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCurrency
// ====================================================

export interface CreateCurrency_currencyCreate {
  __typename: 'Currency'
  id: string
}

export interface CreateCurrency {
  /**
   * Create a currency
   */
  currencyCreate: CreateCurrency_currencyCreate | null
}

export interface CreateCurrencyVariables {
  name: string
  code: string
  conversions: CurrencyConversionInput[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrencyCreatePrices
// ====================================================

export interface CurrencyCreatePrices_prices_nodes {
  __typename: 'Price'
  id: string
  name: string
}

export interface CurrencyCreatePrices_prices {
  __typename: 'PriceList'
  nodes: CurrencyCreatePrices_prices_nodes[]
}

export interface CurrencyCreatePrices {
  /**
   * Get all prices
   */
  prices: CurrencyCreatePrices_prices | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: currency_delete
// ====================================================

export interface currency_delete_currencyDelete {
  __typename: 'Currency'
  id: string
}

export interface currency_delete {
  /**
   * Delete a currency
   */
  currencyDelete: currency_delete_currencyDelete | null
}

export interface currency_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: currencies_index
// ====================================================

export interface currencies_index_currencies_nodes {
  __typename: 'Currency'
  id: string
  name: string
  code: CurrencyCode
}

export interface currencies_index_currencies_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface currencies_index_currencies {
  __typename: 'CurrencyList'
  nodes: currencies_index_currencies_nodes[]
  totalCount: number
  pageInfo: currencies_index_currencies_pageInfo
}

export interface currencies_index {
  /**
   * Get all currencies
   */
  currencies: currencies_index_currencies | null
}

export interface currencies_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: currencies_view
// ====================================================

export interface currencies_view_currency {
  __typename: 'Currency'
  id: string
  name: string
  code: CurrencyCode
}

export interface currencies_view {
  /**
   * Get a currency by it's id
   */
  currency: currencies_view_currency | null
}

export interface currencies_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CurrencyUpdateMutation
// ====================================================

export interface CurrencyUpdateMutation_currencyUpdate {
  __typename: 'Currency'
  id: string
}

export interface CurrencyUpdateMutation {
  /**
   * Update a currency
   */
  currencyUpdate: CurrencyUpdateMutation_currencyUpdate | null
}

export interface CurrencyUpdateMutationVariables {
  id: string
  name: string
  conversions: CurrencyConversionInput[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrencyUpdatePrices
// ====================================================

export interface CurrencyUpdatePrices_prices_nodes_conversions_currency {
  __typename: 'Currency'
  id: string
}

export interface CurrencyUpdatePrices_prices_nodes_conversions {
  __typename: 'PriceConversion'
  currency: CurrencyUpdatePrices_prices_nodes_conversions_currency
  value: number
}

export interface CurrencyUpdatePrices_prices_nodes {
  __typename: 'Price'
  id: string
  name: string
  conversions: CurrencyUpdatePrices_prices_nodes_conversions[]
}

export interface CurrencyUpdatePrices_prices {
  __typename: 'PriceList'
  nodes: CurrencyUpdatePrices_prices_nodes[]
}

export interface CurrencyUpdatePrices {
  /**
   * Get all prices
   */
  prices: CurrencyUpdatePrices_prices | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: currencies_update
// ====================================================

export interface currencies_update_currency {
  __typename: 'Currency'
  id: string
  code: CurrencyCode
  name: string
}

export interface currencies_update {
  /**
   * Get a currency by it's id
   */
  currency: currencies_update_currency | null
}

export interface currencies_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DiscountCreate
// ====================================================

export interface DiscountCreate_discountCreate {
  __typename: 'Discount'
  id: string
}

export interface DiscountCreate {
  /**
   * Create a discount
   */
  discountCreate: DiscountCreate_discountCreate | null
}

export interface DiscountCreateVariables {
  name: string
  description?: string | null
  type: DiscountType
  code: string
  percent: number
  isPaused?: boolean | null
  clauses: DiscountClauseInput[]
  startAt?: any | null
  endAt?: any | null
  isStackable: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: discount_delete
// ====================================================

export interface discount_delete {
  /**
   * Delete a discount
   */
  discountDelete: boolean | null
}

export interface discount_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discounts_index
// ====================================================

export interface discounts_index_discounts_nodes {
  __typename: 'Discount'
  id: string
  name: string
  type: DiscountType
  isStackable: boolean
  isActive: boolean
  percent: number
}

export interface discounts_index_discounts_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface discounts_index_discounts {
  __typename: 'DiscountList'
  nodes: discounts_index_discounts_nodes[]
  totalCount: number
  pageInfo: discounts_index_discounts_pageInfo
}

export interface discounts_index {
  /**
   * Get all discounts
   */
  discounts: discounts_index_discounts | null
}

export interface discounts_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discounts_view
// ====================================================

export interface discounts_view_discount_clauses_expressions_DiscountExpressionType_typeValue {
  __typename: 'DiscountValueTypeIn'
  operator: DiscountOperator
  value: ExpressionTypeValue[]
}

export interface discounts_view_discount_clauses_expressions_DiscountExpressionType {
  __typename: 'DiscountExpressionType'
  key: string
  typeValue: discounts_view_discount_clauses_expressions_DiscountExpressionType_typeValue
}

export interface discounts_view_discount_clauses_expressions_DiscountExpressionProduct_productValue {
  __typename: 'DiscountValueIntegerIn'
  operator: DiscountOperator
  value: string[]
}

export interface discounts_view_discount_clauses_expressions_DiscountExpressionProduct {
  __typename: 'DiscountExpressionProduct'
  key: string
  productValue: discounts_view_discount_clauses_expressions_DiscountExpressionProduct_productValue
}

export interface discounts_view_discount_clauses_expressions_DiscountExpressionCount_countValue {
  __typename: 'DiscountValueCount'
  operator: DiscountCountOperator
  value: number
}

export interface discounts_view_discount_clauses_expressions_DiscountExpressionCount {
  __typename: 'DiscountExpressionCount'
  key: string
  countValue: discounts_view_discount_clauses_expressions_DiscountExpressionCount_countValue
}

export type discounts_view_discount_clauses_expressions =
  | discounts_view_discount_clauses_expressions_DiscountExpressionType
  | discounts_view_discount_clauses_expressions_DiscountExpressionProduct
  | discounts_view_discount_clauses_expressions_DiscountExpressionCount

export interface discounts_view_discount_clauses {
  __typename: 'DiscountClause'
  expressions: discounts_view_discount_clauses_expressions[] | null
}

export interface discounts_view_discount {
  __typename: 'Discount'
  id: string
  name: string
  description: string
  type: DiscountType
  code: string
  percent: number
  isActive: boolean
  isPaused: boolean
  startAt: any | null
  endAt: any | null
  clauses: discounts_view_discount_clauses[]
}

export interface discounts_view {
  /**
   * Get a discount
   */
  discount: discounts_view_discount | null
}

export interface discounts_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DiscountUpdateMutation
// ====================================================

export interface DiscountUpdateMutation_discountUpdate {
  __typename: 'Discount'
  id: string
}

export interface DiscountUpdateMutation {
  /**
   * Update a discount
   */
  discountUpdate: DiscountUpdateMutation_discountUpdate | null
}

export interface DiscountUpdateMutationVariables {
  id: string
  clauses: DiscountClauseInput[]
  code: string
  description?: string | null
  endAt?: any | null
  isPaused?: boolean | null
  isStackable: boolean
  name: string
  percent: number
  startAt?: any | null
  type: DiscountType
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discounts_update
// ====================================================

export interface discounts_update_discount_clauses_expressions_DiscountExpressionType_typeValue {
  __typename: 'DiscountValueTypeIn'
  operator: DiscountOperator
  value: ExpressionTypeValue[]
}

export interface discounts_update_discount_clauses_expressions_DiscountExpressionType {
  __typename: 'DiscountExpressionType'
  key: string
  typeValue: discounts_update_discount_clauses_expressions_DiscountExpressionType_typeValue
}

export interface discounts_update_discount_clauses_expressions_DiscountExpressionProduct_productValue {
  __typename: 'DiscountValueIntegerIn'
  operator: DiscountOperator
  value: string[]
}

export interface discounts_update_discount_clauses_expressions_DiscountExpressionProduct {
  __typename: 'DiscountExpressionProduct'
  key: string
  productValue: discounts_update_discount_clauses_expressions_DiscountExpressionProduct_productValue
}

export interface discounts_update_discount_clauses_expressions_DiscountExpressionCount_countValue {
  __typename: 'DiscountValueCount'
  operator: DiscountCountOperator
  value: number
}

export interface discounts_update_discount_clauses_expressions_DiscountExpressionCount {
  __typename: 'DiscountExpressionCount'
  key: string
  countValue: discounts_update_discount_clauses_expressions_DiscountExpressionCount_countValue
}

export type discounts_update_discount_clauses_expressions =
  | discounts_update_discount_clauses_expressions_DiscountExpressionType
  | discounts_update_discount_clauses_expressions_DiscountExpressionProduct
  | discounts_update_discount_clauses_expressions_DiscountExpressionCount

export interface discounts_update_discount_clauses {
  __typename: 'DiscountClause'
  expressions: discounts_update_discount_clauses_expressions[] | null
}

export interface discounts_update_discount {
  __typename: 'Discount'
  id: string
  isStackable: boolean
  clauses: discounts_update_discount_clauses[]
  code: string
  description: string
  endAt: any | null
  isPaused: boolean
  name: string
  percent: number
  startAt: any | null
  type: DiscountType
}

export interface discounts_update {
  /**
   * Get a discount
   */
  discount: discounts_update_discount | null
}

export interface discounts_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HallOfFameItemCreate
// ====================================================

export interface HallOfFameItemCreate_hallOfFamerCreate {
  __typename: 'HallOfFamer'
  id: string
}

export interface HallOfFameItemCreate {
  /**
   * Create hall of famer
   */
  hallOfFamerCreate: HallOfFameItemCreate_hallOfFamerCreate | null
}

export interface HallOfFameItemCreateVariables {
  image: any
  slug: string
  title: string
  type: HallOfFamerType
  url?: string | null
  userIds?: string[] | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: hallOfFamerDelete
// ====================================================

export interface hallOfFamerDelete_hallOfFamerDelete {
  __typename: 'HallOfFamer'
  id: string
}

export interface hallOfFamerDelete {
  /**
   * Delete hall of famer
   */
  hallOfFamerDelete: hallOfFamerDelete_hallOfFamerDelete | null
}

export interface hallOfFamerDeleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: hall_of_fame_index
// ====================================================

export interface hall_of_fame_index_hallOfFamers_nodes {
  __typename: 'HallOfFamer'
  id: string
  title: string
  type: HallOfFamerType
}

export interface hall_of_fame_index_hallOfFamers_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface hall_of_fame_index_hallOfFamers {
  __typename: 'HallOfFamerList'
  nodes: hall_of_fame_index_hallOfFamers_nodes[]
  totalCount: number
  pageInfo: hall_of_fame_index_hallOfFamers_pageInfo
}

export interface hall_of_fame_index {
  /**
   * Hall of famers
   */
  hallOfFamers: hall_of_fame_index_hallOfFamers | null
}

export interface hall_of_fame_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: hall_of_fame_view
// ====================================================

export interface hall_of_fame_view_hallOfFamer {
  __typename: 'HallOfFamer'
  id: string
  title: string
  type: HallOfFamerType
  url: string | null
}

export interface hall_of_fame_view {
  /**
   * Hall of famer
   */
  hallOfFamer: hall_of_fame_view_hallOfFamer | null
}

export interface hall_of_fame_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HallOfFameItemUpdate
// ====================================================

export interface HallOfFameItemUpdate_hallOfFamerUpdate {
  __typename: 'HallOfFamer'
  id: string
}

export interface HallOfFameItemUpdate {
  /**
   * Update hall of famer
   */
  hallOfFamerUpdate: HallOfFameItemUpdate_hallOfFamerUpdate | null
}

export interface HallOfFameItemUpdateVariables {
  id: string
  image?: any | null
  slug: string
  title: string
  type: HallOfFamerType
  url?: string | null
  userIds?: string[] | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: hall_of_fame_update
// ====================================================

export interface hall_of_fame_update_hallOfFamer_image {
  __typename: 'Image'
  url: string
}

export interface hall_of_fame_update_hallOfFamer_users {
  __typename: 'User'
  id: string
}

export interface hall_of_fame_update_hallOfFamer {
  __typename: 'HallOfFamer'
  id: string
  slug: string
  image: hall_of_fame_update_hallOfFamer_image
  title: string
  type: HallOfFamerType
  url: string | null
  users: hall_of_fame_update_hallOfFamer_users[] | null
}

export interface hall_of_fame_update {
  /**
   * Hall of famer
   */
  hallOfFamer: hall_of_fame_update_hallOfFamer | null
}

export interface hall_of_fame_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: membership_affiliates_create_mutation
// ====================================================

export interface membership_affiliates_create_mutation_membershipAffiliateCreate {
  __typename: 'MembershipAffiliate'
  id: string
  applicationFeePercent: number
  name: string
  slug: string
  stripeAccountId: string
  stripeCouponId: string | null
}

export interface membership_affiliates_create_mutation {
  /**
   * Membership affiliate create
   */
  membershipAffiliateCreate: membership_affiliates_create_mutation_membershipAffiliateCreate | null
}

export interface membership_affiliates_create_mutationVariables {
  applicationFeePercent: number
  name: string
  slug: string
  stripeAccountId: string
  stripeCouponId?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_affiliates_index
// ====================================================

export interface membership_affiliates_index_membershipAffiliates_nodes {
  __typename: 'MembershipAffiliate'
  id: string
  name: string
  stripeAccountId: string
}

export interface membership_affiliates_index_membershipAffiliates_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface membership_affiliates_index_membershipAffiliates {
  __typename: 'MembershipAffiliateList'
  nodes: membership_affiliates_index_membershipAffiliates_nodes[]
  totalCount: number
  pageInfo: membership_affiliates_index_membershipAffiliates_pageInfo
}

export interface membership_affiliates_index {
  /**
   * Membership affiliates
   */
  membershipAffiliates: membership_affiliates_index_membershipAffiliates | null
}

export interface membership_affiliates_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: membership_affiliates_update_mutation
// ====================================================

export interface membership_affiliates_update_mutation_membershipAffiliateUpdate {
  __typename: 'MembershipAffiliate'
  id: string
  applicationFeePercent: number
  name: string
  slug: string
  stripeCouponId: string | null
  stripeAccountId: string
}

export interface membership_affiliates_update_mutation {
  /**
   * Membership affiliate update
   */
  membershipAffiliateUpdate: membership_affiliates_update_mutation_membershipAffiliateUpdate | null
}

export interface membership_affiliates_update_mutationVariables {
  id: string
  applicationFeePercent: number
  name: string
  slug: string
  stripeAccountId: string
  stripeCouponId?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_affiliates_update
// ====================================================

export interface membership_affiliates_update_membershipAffiliate {
  __typename: 'MembershipAffiliate'
  id: string
  name: string
  slug: string
  stripeAccountId: string
  stripeCouponId: string | null
  applicationFeePercent: number
}

export interface membership_affiliates_update {
  /**
   * Membership affiliate by id
   */
  membershipAffiliate: membership_affiliates_update_membershipAffiliate | null
}

export interface membership_affiliates_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_affiliates_view
// ====================================================

export interface membership_affiliates_view_membershipAffiliate {
  __typename: 'MembershipAffiliate'
  id: string
  applicationFeePercent: number
  name: string
  slug: string
  stripeAccountId: string
  stripeCouponId: string | null
}

export interface membership_affiliates_view {
  /**
   * Membership affiliate by id
   */
  membershipAffiliate: membership_affiliates_view_membershipAffiliate | null
}

export interface membership_affiliates_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_invoices_index
// ====================================================

export interface membership_invoices_index_membershipInvoices_nodes_user {
  __typename: 'User'
  id: string
  email: string
}

export interface membership_invoices_index_membershipInvoices_nodes_total {
  __typename: 'Money'
  value: number
  currencyCode: CurrencyCode
}

export interface membership_invoices_index_membershipInvoices_nodes {
  __typename: 'MembershipInvoice'
  id: string
  status: MembershipInvoiceStatus
  user: membership_invoices_index_membershipInvoices_nodes_user
  total: membership_invoices_index_membershipInvoices_nodes_total
}

export interface membership_invoices_index_membershipInvoices_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface membership_invoices_index_membershipInvoices {
  __typename: 'MembershipInvoiceList'
  nodes: membership_invoices_index_membershipInvoices_nodes[]
  totalCount: number
  pageInfo: membership_invoices_index_membershipInvoices_pageInfo
}

export interface membership_invoices_index {
  /**
   * Membership invoices
   */
  membershipInvoices: membership_invoices_index_membershipInvoices | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_invoices_view
// ====================================================

export interface membership_invoices_view_membershipInvoice_product_price_recurring {
  __typename: 'StripePriceRecurring'
  interval: StripePriceRecurringInterval
  intervalCount: number
}

export interface membership_invoices_view_membershipInvoice_product_price {
  __typename: 'StripePrice'
  currencyCode: CurrencyCode
  value: number
  recurring: membership_invoices_view_membershipInvoice_product_price_recurring | null
}

export interface membership_invoices_view_membershipInvoice_product {
  __typename: 'MembershipProduct'
  id: string
  title: string
  price: membership_invoices_view_membershipInvoice_product_price
}

export interface membership_invoices_view_membershipInvoice_value {
  __typename: 'Money'
  value: number
  currencyCode: CurrencyCode
}

export interface membership_invoices_view_membershipInvoice_payments_refunds_value {
  __typename: 'Money'
  currencyCode: CurrencyCode
  value: number
}

export interface membership_invoices_view_membershipInvoice_payments_refunds {
  __typename: 'MembershipInvoicePaymentRefund'
  insertedAt: any
  reason: string | null
  value: membership_invoices_view_membershipInvoice_payments_refunds_value
}

export interface membership_invoices_view_membershipInvoice_payments_value {
  __typename: 'Money'
  currencyCode: CurrencyCode
  value: number
}

export interface membership_invoices_view_membershipInvoice_payments {
  __typename: 'MembershipInvoicePayment'
  insertedAt: any
  status: MembershipInvoicePaymentStatus
  refunds: membership_invoices_view_membershipInvoice_payments_refunds[] | null
  value: membership_invoices_view_membershipInvoice_payments_value
}

export interface membership_invoices_view_membershipInvoice_totalDiscount {
  __typename: 'Money'
  value: number
  currencyCode: CurrencyCode
}

export interface membership_invoices_view_membershipInvoice_user_avatar {
  __typename: 'Image'
  url: string
}

export interface membership_invoices_view_membershipInvoice_user {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  avatar: membership_invoices_view_membershipInvoice_user_avatar | null
  type: UserType
}

export interface membership_invoices_view_membershipInvoice_total {
  __typename: 'Money'
  value: number
  currencyCode: CurrencyCode
}

export interface membership_invoices_view_membershipInvoice {
  __typename: 'MembershipInvoice'
  id: string
  product: membership_invoices_view_membershipInvoice_product
  value: membership_invoices_view_membershipInvoice_value
  status: MembershipInvoiceStatus
  payments: membership_invoices_view_membershipInvoice_payments[] | null
  totalDiscount: membership_invoices_view_membershipInvoice_totalDiscount
  user: membership_invoices_view_membershipInvoice_user
  total: membership_invoices_view_membershipInvoice_total
  createdAt: any
}

export interface membership_invoices_view {
  /**
   * Membership invoice
   */
  membershipInvoice: membership_invoices_view_membershipInvoice | null
}

export interface membership_invoices_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: membership_products_create_mutation
// ====================================================

export interface membership_products_create_mutation_membershipProductCreate {
  __typename: 'MembershipProduct'
  id: string
}

export interface membership_products_create_mutation {
  /**
   * Membership product create
   */
  membershipProductCreate: membership_products_create_mutation_membershipProductCreate | null
}

export interface membership_products_create_mutationVariables {
  description: string
  mailchimpTag: string
  slug: string
  stripeProductId: string
  title: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_products_index
// ====================================================

export interface membership_products_index_membershipProducts_nodes {
  __typename: 'MembershipProduct'
  id: string
  title: string
}

export interface membership_products_index_membershipProducts_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface membership_products_index_membershipProducts {
  __typename: 'MembershipProductList'
  nodes: membership_products_index_membershipProducts_nodes[]
  totalCount: number
  pageInfo: membership_products_index_membershipProducts_pageInfo
}

export interface membership_products_index {
  /**
   * Membership products
   */
  membershipProducts: membership_products_index_membershipProducts | null
}

export interface membership_products_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_products_view
// ====================================================

export interface membership_products_view_membershipProduct_price_recurring {
  __typename: 'StripePriceRecurring'
  interval: StripePriceRecurringInterval
  intervalCount: number
}

export interface membership_products_view_membershipProduct_price {
  __typename: 'StripePrice'
  value: number
  currencyCode: CurrencyCode
  recurring: membership_products_view_membershipProduct_price_recurring | null
}

export interface membership_products_view_membershipProduct {
  __typename: 'MembershipProduct'
  id: string
  slug: string
  stripeProductId: string
  title: string
  description: string
  mailchimpTag: string
  price: membership_products_view_membershipProduct_price
}

export interface membership_products_view {
  /**
   * Membership product by id
   */
  membershipProduct: membership_products_view_membershipProduct | null
}

export interface membership_products_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: membership_products_update_mutation
// ====================================================

export interface membership_products_update_mutation_membershipProductUpdate {
  __typename: 'MembershipProduct'
  id: string
}

export interface membership_products_update_mutation {
  /**
   * Membership product update
   */
  membershipProductUpdate: membership_products_update_mutation_membershipProductUpdate | null
}

export interface membership_products_update_mutationVariables {
  id: string
  description: string
  mailchimpTag: string
  slug: string
  stripeProductId: string
  title: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_products_update
// ====================================================

export interface membership_products_update_membershipProduct {
  __typename: 'MembershipProduct'
  id: string
  description: string
  mailchimpTag: string
  slug: string
  stripeProductId: string
  title: string
}

export interface membership_products_update {
  /**
   * Membership product by id
   */
  membershipProduct: membership_products_update_membershipProduct | null
}

export interface membership_products_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: membership_refunds_create_mutation
// ====================================================

export interface membership_refunds_create_mutation_membershipRefundCreate {
  __typename: 'Session'
  id: string
}

export interface membership_refunds_create_mutation {
  /**
   * Membership refund
   */
  membershipRefundCreate: membership_refunds_create_mutation_membershipRefundCreate | null
}

export interface membership_refunds_create_mutationVariables {
  membershipInvoiceId: string
  reason?: CreditNoteReason | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_refunds_create_invoice
// ====================================================

export interface membership_refunds_create_invoice_membershipInvoice_product {
  __typename: 'MembershipProduct'
  id: string
  title: string
}

export interface membership_refunds_create_invoice_membershipInvoice_discounts_MembershipDiscountCoupon {
  __typename: 'MembershipDiscountCoupon'
  name: string
}

export interface membership_refunds_create_invoice_membershipInvoice_discounts_MembershipDiscountPromoCode_coupon {
  __typename: 'MembershipDiscountCoupon'
  name: string
}

export interface membership_refunds_create_invoice_membershipInvoice_discounts_MembershipDiscountPromoCode {
  __typename: 'MembershipDiscountPromoCode'
  coupon: membership_refunds_create_invoice_membershipInvoice_discounts_MembershipDiscountPromoCode_coupon
}

export type membership_refunds_create_invoice_membershipInvoice_discounts =
  | membership_refunds_create_invoice_membershipInvoice_discounts_MembershipDiscountCoupon
  | membership_refunds_create_invoice_membershipInvoice_discounts_MembershipDiscountPromoCode

export interface membership_refunds_create_invoice_membershipInvoice_value {
  __typename: 'Money'
  currencyCode: CurrencyCode
  value: number
}

export interface membership_refunds_create_invoice_membershipInvoice_totalDiscount {
  __typename: 'Money'
  currencyCode: CurrencyCode
  value: number
}

export interface membership_refunds_create_invoice_membershipInvoice_total {
  __typename: 'Money'
  currencyCode: CurrencyCode
  value: number
}

export interface membership_refunds_create_invoice_membershipInvoice_user {
  __typename: 'User'
  id: string
  email: string
}

export interface membership_refunds_create_invoice_membershipInvoice {
  __typename: 'MembershipInvoice'
  id: string
  product: membership_refunds_create_invoice_membershipInvoice_product
  discounts: membership_refunds_create_invoice_membershipInvoice_discounts[] | null
  value: membership_refunds_create_invoice_membershipInvoice_value
  totalDiscount: membership_refunds_create_invoice_membershipInvoice_totalDiscount
  total: membership_refunds_create_invoice_membershipInvoice_total
  user: membership_refunds_create_invoice_membershipInvoice_user
  createdAt: any
}

export interface membership_refunds_create_invoice {
  /**
   * Membership invoice
   */
  membershipInvoice: membership_refunds_create_invoice_membershipInvoice | null
}

export interface membership_refunds_create_invoiceVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_refunds_index
// ====================================================

export interface membership_refunds_index_refunds_nodes_order {
  __typename: 'Order'
  id: string
}

export interface membership_refunds_index_refunds_nodes {
  __typename: 'Refund'
  id: string
  order: membership_refunds_index_refunds_nodes_order
  status: RefundStatus
}

export interface membership_refunds_index_refunds {
  __typename: 'RefundList'
  nodes: membership_refunds_index_refunds_nodes[]
}

export interface membership_refunds_index {
  /**
   * Get all refunds
   */
  refunds: membership_refunds_index_refunds | null
}

export interface membership_refunds_indexVariables {
  page?: number | null
  perPage?: number | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: membership_refunds_view
// ====================================================

export interface membership_refunds_view_refund_lineItems_nodes_orderLineItem_product {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
}

export interface membership_refunds_view_refund_lineItems_nodes_orderLineItem_variant {
  __typename: 'ProductVariant'
  title: string
}

export interface membership_refunds_view_refund_lineItems_nodes_orderLineItem {
  __typename: 'OrderLineItem'
  product: membership_refunds_view_refund_lineItems_nodes_orderLineItem_product
  variant: membership_refunds_view_refund_lineItems_nodes_orderLineItem_variant
}

export interface membership_refunds_view_refund_lineItems_nodes {
  __typename: 'RefundLineItem'
  id: string
  quantity: number
  status: RefundStatus
  orderLineItem: membership_refunds_view_refund_lineItems_nodes_orderLineItem
}

export interface membership_refunds_view_refund_lineItems {
  __typename: 'RefundLineItemList'
  nodes: membership_refunds_view_refund_lineItems_nodes[]
}

export interface membership_refunds_view_refund_order_customerDetails {
  __typename: 'OrderCustomerDetails'
  name: string
}

export interface membership_refunds_view_refund_order {
  __typename: 'Order'
  id: string
  insertedAt: any
  customerDetails: membership_refunds_view_refund_order_customerDetails
}

export interface membership_refunds_view_refund_total {
  __typename: 'MoneyV1'
  value: number
  currencyCode: CurrencyCodeV1
}

export interface membership_refunds_view_refund_exchangeItems_nodes_variant {
  __typename: 'ProductVariant'
  title: string
}

export interface membership_refunds_view_refund_exchangeItems_nodes {
  __typename: 'RefundExchangeItem'
  id: string
  quantity: number
  variant: membership_refunds_view_refund_exchangeItems_nodes_variant
}

export interface membership_refunds_view_refund_exchangeItems {
  __typename: 'RefundExchangeItemList'
  nodes: membership_refunds_view_refund_exchangeItems_nodes[]
}

export interface membership_refunds_view_refund_basket {
  __typename: 'Basket'
  id: string
  status: BasketStatus
}

export interface membership_refunds_view_refund_payments_nodes_value {
  __typename: 'MoneyV1'
  value: number
  currencyCode: CurrencyCodeV1
}

export interface membership_refunds_view_refund_payments_nodes {
  __typename: 'RefundPayment'
  id: string
  value: membership_refunds_view_refund_payments_nodes_value
  insertedAt: any
}

export interface membership_refunds_view_refund_payments {
  __typename: 'RefundPaymentList'
  nodes: membership_refunds_view_refund_payments_nodes[]
}

export interface membership_refunds_view_refund {
  __typename: 'Refund'
  id: string
  lineItems: membership_refunds_view_refund_lineItems | null
  order: membership_refunds_view_refund_order
  notes: string | null
  total: membership_refunds_view_refund_total
  exchangeItems: membership_refunds_view_refund_exchangeItems | null
  basket: membership_refunds_view_refund_basket | null
  payments: membership_refunds_view_refund_payments | null
  status: RefundStatus
  insertedAt: any
  updatedAt: any
}

export interface membership_refunds_view {
  /**
   * Get refund by it's id
   */
  refund: membership_refunds_view_refund | null
}

export interface membership_refunds_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: orders_create_mutation
// ====================================================

export interface orders_create_mutation_orderCreate {
  __typename: 'Order'
  id: string
}

export interface orders_create_mutation {
  /**
   * Create an order
   */
  orderCreate: orders_create_mutation_orderCreate | null
}

export interface orders_create_mutationVariables {
  customerDetails: OrderCustomerDetailsInput
  shippingDetails?: OrderAddressInput | null
  lineItems: OrderLineItemInput[]
  total: MoneyInput
  notes?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: order_delete
// ====================================================

export interface order_delete_orderDelete {
  __typename: 'Order'
  id: string
}

export interface order_delete {
  /**
   * Delete an order
   */
  orderDelete: order_delete_orderDelete | null
}

export interface order_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orders_index
// ====================================================

export interface orders_index_orders_nodes_customerDetails {
  __typename: 'OrderCustomerDetails'
  name: string
  email: string
}

export interface orders_index_orders_nodes {
  __typename: 'Order'
  id: string
  customerDetails: orders_index_orders_nodes_customerDetails
  insertedAt: any
}

export interface orders_index_orders_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface orders_index_orders {
  __typename: 'OrderList'
  nodes: orders_index_orders_nodes[]
  totalCount: number
  pageInfo: orders_index_orders_pageInfo
}

export interface orders_index {
  /**
   * Get the current user's orders
   */
  orders: orders_index_orders | null
}

export interface orders_indexVariables {
  page?: number | null
  perPage?: number | null
  search?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orders_view
// ====================================================

export interface orders_view_order_customerDetails {
  __typename: 'OrderCustomerDetails'
  name: string
  email: string
}

export interface orders_view_order_giftDetails {
  __typename: 'OrderGiftDetails'
  name: string
  email: string
  date: any | null
}

export interface orders_view_order_lineItems_nodes_price {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface orders_view_order_lineItems_nodes_product {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
  title: string
}

export interface orders_view_order_lineItems_nodes {
  __typename: 'OrderLineItem'
  id: string
  title: string
  price: orders_view_order_lineItems_nodes_price
  product: orders_view_order_lineItems_nodes_product
  quantity: number
}

export interface orders_view_order_lineItems {
  __typename: 'OrderLineItemList'
  nodes: orders_view_order_lineItems_nodes[]
}

export interface orders_view_order_payments_value {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface orders_view_order_payments {
  __typename: 'OrderPayment'
  value: orders_view_order_payments_value
  method: PaymentMethod
  paidAt: any | null
}

export interface orders_view_order_billingAddress {
  __typename: 'OrderAddress'
  name: string
  line1: string
  line2: string | null
  city: string
  state: string
  country: string
  postalCode: string
}

export interface orders_view_order_shippingAddress {
  __typename: 'OrderAddress'
  name: string
  line1: string
  line2: string | null
  city: string
  state: string
  country: string
  postalCode: string
}

export interface orders_view_order_total {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface orders_view_order_totalDiscount {
  __typename: 'MoneyV1'
  value: number
  currencyCode: CurrencyCodeV1
}

export interface orders_view_order_value {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface orders_view_order_refunds_nodes {
  __typename: 'Refund'
  id: string
  insertedAt: any
}

export interface orders_view_order_refunds {
  __typename: 'RefundList'
  nodes: orders_view_order_refunds_nodes[]
}

export interface orders_view_order {
  __typename: 'Order'
  id: string
  type: OrderType
  customerDetails: orders_view_order_customerDetails
  giftDetails: orders_view_order_giftDetails | null
  notes: string | null
  lineItems: orders_view_order_lineItems | null
  payments: orders_view_order_payments[]
  billingAddress: orders_view_order_billingAddress | null
  shippingAddress: orders_view_order_shippingAddress | null
  total: orders_view_order_total
  totalDiscount: orders_view_order_totalDiscount
  value: orders_view_order_value
  refunds: orders_view_order_refunds | null
  version: OrderVersion
  insertedAt: any
}

export interface orders_view {
  /**
   * Get an order by it's id
   */
  order: orders_view_order | null
}

export interface orders_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OrderReceiptSend
// ====================================================

export interface OrderReceiptSend {
  /**
   * Resend order reciept
   */
  orderReceiptSend: boolean | null
}

export interface OrderReceiptSendVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: orders_update_mutation
// ====================================================

export interface orders_update_mutation_orderUpdate {
  __typename: 'Order'
  id: string
}

export interface orders_update_mutation {
  /**
   * Update an order
   */
  orderUpdate: orders_update_mutation_orderUpdate | null
}

export interface orders_update_mutationVariables {
  id: string
  giftDate: any
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orders_update
// ====================================================

export interface orders_update_order_giftDetails {
  __typename: 'OrderGiftDetails'
  email: string
  date: any | null
}

export interface orders_update_order {
  __typename: 'Order'
  id: string
  giftDetails: orders_update_order_giftDetails | null
}

export interface orders_update {
  /**
   * Get an order by it's id
   */
  order: orders_update_order | null
}

export interface orders_updateVariables {
  id?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PersonCreateMutation
// ====================================================

export interface PersonCreateMutation_personCreate {
  __typename: 'Person'
  id: string
}

export interface PersonCreateMutation {
  /**
   * Create person
   */
  personCreate: PersonCreateMutation_personCreate | null
}

export interface PersonCreateMutationVariables {
  description: string
  image: any
  jobTitle: string
  location?: string | null
  name: string
  slug: string
  facebook?: string | null
  instagram?: string | null
  twitter?: string | null
  isSuperhi: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: person_delete
// ====================================================

export interface person_delete_personDelete {
  __typename: 'Person'
  id: string
}

export interface person_delete {
  /**
   * Delete person
   */
  personDelete: person_delete_personDelete | null
}

export interface person_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: people_index
// ====================================================

export interface people_index_people_nodes {
  __typename: 'Person'
  id: string
  name: string
}

export interface people_index_people_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface people_index_people {
  __typename: 'PersonList'
  nodes: people_index_people_nodes[]
  totalCount: number
  pageInfo: people_index_people_pageInfo
}

export interface people_index {
  /**
   * Get all people
   */
  people: people_index_people | null
}

export interface people_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PersonUpdateMutation
// ====================================================

export interface PersonUpdateMutation_personUpdate {
  __typename: 'Person'
  id: string
}

export interface PersonUpdateMutation {
  /**
   * Update person
   */
  personUpdate: PersonUpdateMutation_personUpdate | null
}

export interface PersonUpdateMutationVariables {
  id: string
  description: string
  image?: any | null
  jobTitle: string
  location?: string | null
  name: string
  slug: string
  facebook?: string | null
  instagram?: string | null
  twitter?: string | null
  isSuperhi: boolean
  startedOn?: any | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: people_update
// ====================================================

export interface people_update_person_image {
  __typename: 'Image'
  url: string
}

export interface people_update_person {
  __typename: 'Person'
  id: string
  description: string
  image: people_update_person_image
  startedOn: any | null
  jobTitle: string
  location: string | null
  name: string
  slug: string
  facebook: string | null
  instagram: string | null
  twitter: string | null
  isSuperhi: boolean
}

export interface people_update {
  /**
   * Get person by id
   */
  person: people_update_person | null
}

export interface people_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: people_view
// ====================================================

export interface people_view_person_image {
  __typename: 'Image'
  url: string
}

export interface people_view_person {
  __typename: 'Person'
  id: string
  name: string
  image: people_view_person_image
  description: string
  jobTitle: string
  location: string | null
  facebook: string | null
  instagram: string | null
  twitter: string | null
}

export interface people_view {
  /**
   * Get person by id
   */
  person: people_view_person | null
}

export interface people_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: popups_create_mutation
// ====================================================

export interface popups_create_mutation_popupCreate {
  __typename: 'Popup'
  id: string
}

export interface popups_create_mutation {
  /**
   * Create a popup
   */
  popupCreate: popups_create_mutation_popupCreate | null
}

export interface popups_create_mutationVariables {
  title: string
  isActive: boolean
  body: string
  buttonUrl: string
  buttonText: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: popups_index
// ====================================================

export interface popups_index_popups_nodes {
  __typename: 'Popup'
  id: string
  title: string
  isActive: boolean
}

export interface popups_index_popups_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface popups_index_popups {
  __typename: 'PopupList'
  nodes: popups_index_popups_nodes[]
  totalCount: number
  pageInfo: popups_index_popups_pageInfo
}

export interface popups_index {
  /**
   * Get all popups
   */
  popups: popups_index_popups | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: popups_view
// ====================================================

export interface popups_view_popup {
  __typename: 'Popup'
  id: string
  title: string
  isActive: boolean
  body: string
  buttonUrl: string
  createdAt: any
}

export interface popups_view {
  /**
   * Get a popup by it's id
   */
  popup: popups_view_popup | null
}

export interface popups_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: popups_update_mutation
// ====================================================

export interface popups_update_mutation_popupUpdate {
  __typename: 'Popup'
  id: string
}

export interface popups_update_mutation {
  popupUpdate: popups_update_mutation_popupUpdate | null
}

export interface popups_update_mutationVariables {
  id: string
  title: string
  isActive: boolean
  body: string
  buttonUrl: string
  buttonText: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: popup_update
// ====================================================

export interface popup_update_popup {
  __typename: 'Popup'
  id: string
  title: string
  isActive: boolean
  body: string
  buttonUrl: string
  buttonText: string
  createdAt: any
}

export interface popup_update {
  /**
   * Get a popup by it's id
   */
  popup: popup_update_popup | null
}

export interface popup_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PriceCreateMutation
// ====================================================

export interface PriceCreateMutation_priceCreate {
  __typename: 'Price'
  id: string
}

export interface PriceCreateMutation {
  /**
   * Create a price
   */
  priceCreate: PriceCreateMutation_priceCreate | null
}

export interface PriceCreateMutationVariables {
  name: string
  conversions: PriceConversionInput[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PricesCreateAllCurrencies
// ====================================================

export interface PricesCreateAllCurrencies_currencies_nodes {
  __typename: 'Currency'
  id: string
  name: string
}

export interface PricesCreateAllCurrencies_currencies {
  __typename: 'CurrencyList'
  nodes: PricesCreateAllCurrencies_currencies_nodes[]
}

export interface PricesCreateAllCurrencies {
  /**
   * Get all currencies
   */
  currencies: PricesCreateAllCurrencies_currencies | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: price_delete
// ====================================================

export interface price_delete_priceDelete {
  __typename: 'Price'
  id: string
}

export interface price_delete {
  /**
   * Delete a price
   */
  priceDelete: price_delete_priceDelete | null
}

export interface price_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: prices_index
// ====================================================

export interface prices_index_prices_nodes_conversions_currency {
  __typename: 'Currency'
  code: CurrencyCode
  id: string
  name: string
}

export interface prices_index_prices_nodes_conversions {
  __typename: 'PriceConversion'
  currency: prices_index_prices_nodes_conversions_currency
  value: number
}

export interface prices_index_prices_nodes {
  __typename: 'Price'
  id: string
  name: string
  conversions: prices_index_prices_nodes_conversions[]
}

export interface prices_index_prices_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface prices_index_prices {
  __typename: 'PriceList'
  nodes: prices_index_prices_nodes[]
  totalCount: number
  pageInfo: prices_index_prices_pageInfo
}

export interface prices_index {
  /**
   * Get all prices
   */
  prices: prices_index_prices | null
}

export interface prices_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: prices_view
// ====================================================

export interface prices_view_price_conversions_currency {
  __typename: 'Currency'
  code: CurrencyCode
  name: string
}

export interface prices_view_price_conversions {
  __typename: 'PriceConversion'
  currency: prices_view_price_conversions_currency
  value: number
}

export interface prices_view_price {
  __typename: 'Price'
  id: string
  name: string
  conversions: prices_view_price_conversions[]
}

export interface prices_view {
  /**
   * Get a price by it's id
   */
  price: prices_view_price | null
}

export interface prices_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PriceUpdateMutation
// ====================================================

export interface PriceUpdateMutation_priceUpdate {
  __typename: 'Price'
  id: string
}

export interface PriceUpdateMutation {
  /**
   * Update a price
   */
  priceUpdate: PriceUpdateMutation_priceUpdate | null
}

export interface PriceUpdateMutationVariables {
  id: string
  name: string
  conversions: PriceConversionInput[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: prices_update
// ====================================================

export interface prices_update_price_conversions_currency {
  __typename: 'Currency'
  id: string
  code: CurrencyCode
  name: string
}

export interface prices_update_price_conversions {
  __typename: 'PriceConversion'
  currency: prices_update_price_conversions_currency
  value: number
}

export interface prices_update_price {
  __typename: 'Price'
  id: string
  name: string
  conversions: prices_update_price_conversions[]
}

export interface prices_update {
  /**
   * Get a price by it's id
   */
  price: prices_update_price | null
}

export interface prices_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductCollectionCreate
// ====================================================

export interface ProductCollectionCreate_productCollectionCreate {
  __typename: 'ProductCollection'
  id: string
}

export interface ProductCollectionCreate {
  /**
   * Create a product collection
   */
  productCollectionCreate: ProductCollectionCreate_productCollectionCreate | null
}

export interface ProductCollectionCreateVariables {
  title: string
  slug: string
  description: string
  variantIds: string[]
  isBestSeller: boolean
  image: any
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: product_collection_delete
// ====================================================

export interface product_collection_delete_productCollectionDelete {
  __typename: 'ProductCollection'
  id: string
}

export interface product_collection_delete {
  /**
   * Delete a product collection
   */
  productCollectionDelete: product_collection_delete_productCollectionDelete | null
}

export interface product_collection_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_collections_index
// ====================================================

export interface product_collections_index_productCollections_nodes_variants_nodes {
  __typename: 'ProductVariant'
  title: string
}

export interface product_collections_index_productCollections_nodes_variants {
  __typename: 'ProductVariantList'
  nodes: product_collections_index_productCollections_nodes_variants_nodes[]
}

export interface product_collections_index_productCollections_nodes {
  __typename: 'ProductCollection'
  id: string
  title: string
  variants: product_collections_index_productCollections_nodes_variants | null
}

export interface product_collections_index_productCollections_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface product_collections_index_productCollections {
  __typename: 'ProductCollectionList'
  nodes: product_collections_index_productCollections_nodes[]
  totalCount: number
  pageInfo: product_collections_index_productCollections_pageInfo
}

export interface product_collections_index {
  /**
   * Get all product collections
   */
  productCollections: product_collections_index_productCollections | null
}

export interface product_collections_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_collections_view
// ====================================================

export interface product_collections_view_productCollection_variants_nodes_product_image {
  __typename: 'Image'
  url: string
}

export interface product_collections_view_productCollection_variants_nodes_product {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
  title: string
  image: product_collections_view_productCollection_variants_nodes_product_image
}

export interface product_collections_view_productCollection_variants_nodes {
  __typename: 'ProductVariant'
  id: string
  title: string
  product: product_collections_view_productCollection_variants_nodes_product
}

export interface product_collections_view_productCollection_variants {
  __typename: 'ProductVariantList'
  nodes: product_collections_view_productCollection_variants_nodes[]
}

export interface product_collections_view_productCollection_fullPrice {
  __typename: 'Money'
  value: number
  currencyCode: CurrencyCode
}

export interface product_collections_view_productCollection_price {
  __typename: 'Money'
  value: number
  currencyCode: CurrencyCode
}

export interface product_collections_view_productCollection {
  __typename: 'ProductCollection'
  id: string
  title: string
  description: string
  variants: product_collections_view_productCollection_variants | null
  fullPrice: product_collections_view_productCollection_fullPrice
  price: product_collections_view_productCollection_price
}

export interface product_collections_view {
  /**
   * Get product collection by id
   */
  productCollection: product_collections_view_productCollection | null
}

export interface product_collections_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductCollectionUpdateMutation
// ====================================================

export interface ProductCollectionUpdateMutation_productCollectionUpdate {
  __typename: 'ProductCollection'
  id: string
}

export interface ProductCollectionUpdateMutation {
  /**
   * Update a product collection
   */
  productCollectionUpdate: ProductCollectionUpdateMutation_productCollectionUpdate | null
}

export interface ProductCollectionUpdateMutationVariables {
  id: string
  title: string
  slug: string
  description: string
  variantIds: string[]
  isBestSeller: boolean
  image?: any | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_collections_update
// ====================================================

export interface product_collections_update_productCollection_image {
  __typename: 'Image'
  url: string
}

export interface product_collections_update_productCollection_variants_nodes {
  __typename: 'ProductVariant'
  id: string
}

export interface product_collections_update_productCollection_variants {
  __typename: 'ProductVariantList'
  nodes: product_collections_update_productCollection_variants_nodes[]
}

export interface product_collections_update_productCollection {
  __typename: 'ProductCollection'
  id: string
  title: string
  slug: string
  description: string
  isBestSeller: boolean
  image: product_collections_update_productCollection_image
  variants: product_collections_update_productCollection_variants | null
}

export interface product_collections_update {
  /**
   * Get product collection by id
   */
  productCollection: product_collections_update_productCollection | null
}

export interface product_collections_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductFulfillableCreate
// ====================================================

export interface ProductFulfillableCreate_productFulfillableCreate {
  __typename: 'ProductFulfillable'
  id: string
}

export interface ProductFulfillableCreate {
  /**
   * Create a fulfillable
   */
  productFulfillableCreate: ProductFulfillableCreate_productFulfillableCreate | null
}

export interface ProductFulfillableCreateVariables {
  title: string
  description?: string | null
  fulfillmentId: string
  sku: string
  allowMultiplePerOrder: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: product_fulfillable_delete
// ====================================================

export interface product_fulfillable_delete_productFulfillableDelete {
  __typename: 'Price'
  id: string
}

export interface product_fulfillable_delete {
  /**
   * Delete a product fulfillable
   */
  productFulfillableDelete: product_fulfillable_delete_productFulfillableDelete | null
}

export interface product_fulfillable_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_fulfillables_index
// ====================================================

export interface product_fulfillables_index_productFulfillables_nodes {
  __typename: 'ProductFulfillable'
  id: string
  title: string
  sku: string
  fulfillmentId: string
}

export interface product_fulfillables_index_productFulfillables_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface product_fulfillables_index_productFulfillables {
  __typename: 'ProductFulfillableList'
  nodes: product_fulfillables_index_productFulfillables_nodes[]
  totalCount: number
  pageInfo: product_fulfillables_index_productFulfillables_pageInfo
}

export interface product_fulfillables_index {
  /**
   * Get all product fulfillables
   */
  productFulfillables: product_fulfillables_index_productFulfillables | null
}

export interface product_fulfillables_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_fulfillables_view
// ====================================================

export interface product_fulfillables_view_productFulfillable_variants_nodes_product_image {
  __typename: 'Image'
  url: string
}

export interface product_fulfillables_view_productFulfillable_variants_nodes_product {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
  title: string
  image: product_fulfillables_view_productFulfillable_variants_nodes_product_image
}

export interface product_fulfillables_view_productFulfillable_variants_nodes {
  __typename: 'ProductVariant'
  id: string
  title: string
  product: product_fulfillables_view_productFulfillable_variants_nodes_product
}

export interface product_fulfillables_view_productFulfillable_variants {
  __typename: 'ProductVariantList'
  nodes: product_fulfillables_view_productFulfillable_variants_nodes[]
}

export interface product_fulfillables_view_productFulfillable {
  __typename: 'ProductFulfillable'
  id: string
  title: string
  sku: string
  description: string | null
  fulfillmentId: string
  variants: product_fulfillables_view_productFulfillable_variants | null
}

export interface product_fulfillables_view {
  /**
   * Get a product fulfillable by it's id
   */
  productFulfillable: product_fulfillables_view_productFulfillable | null
}

export interface product_fulfillables_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_fulfillables_delete_view
// ====================================================

export interface product_fulfillables_delete_view_productFulfillable_variants_nodes_product {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
  title: string
}

export interface product_fulfillables_delete_view_productFulfillable_variants_nodes {
  __typename: 'ProductVariant'
  id: string
  title: string
  product: product_fulfillables_delete_view_productFulfillable_variants_nodes_product
}

export interface product_fulfillables_delete_view_productFulfillable_variants {
  __typename: 'ProductVariantList'
  nodes: product_fulfillables_delete_view_productFulfillable_variants_nodes[]
}

export interface product_fulfillables_delete_view_productFulfillable {
  __typename: 'ProductFulfillable'
  id: string
  variants: product_fulfillables_delete_view_productFulfillable_variants | null
}

export interface product_fulfillables_delete_view {
  /**
   * Get a product fulfillable by it's id
   */
  productFulfillable: product_fulfillables_delete_view_productFulfillable | null
}

export interface product_fulfillables_delete_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductFulfillableUpdate
// ====================================================

export interface ProductFulfillableUpdate_productFulfillableUpdate {
  __typename: 'ProductFulfillable'
  id: string
}

export interface ProductFulfillableUpdate {
  /**
   * Update a fulfillable
   */
  productFulfillableUpdate: ProductFulfillableUpdate_productFulfillableUpdate | null
}

export interface ProductFulfillableUpdateVariables {
  id: string
  title: string
  description?: string | null
  fulfillmentId: string
  sku: string
  allowMultiplePerOrder: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_fulfillables_update
// ====================================================

export interface product_fulfillables_update_productFulfillable {
  __typename: 'ProductFulfillable'
  id: string
  title: string
  sku: string
  description: string | null
  fulfillmentId: string
  allowMultiplePerOrder: boolean
}

export interface product_fulfillables_update {
  /**
   * Get a product fulfillable by it's id
   */
  productFulfillable: product_fulfillables_update_productFulfillable | null
}

export interface product_fulfillables_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductBookCreate
// ====================================================

export interface ProductBookCreate_productBookCreate {
  __typename: 'ProductBook'
  id: string
}

export interface ProductBookCreate {
  /**
   * Create a product book
   */
  productBookCreate: ProductBookCreate_productBookCreate | null
}

export interface ProductBookCreateVariables {
  description: string
  image: any
  priceId?: string | null
  fulfillableIds?: string[] | null
  shortDescription: string
  slug: string
  title: string
  releaseDate?: any | null
  variants?: ProductVariantCreateInput[] | null
  contents: ProductContentInput[]
  isBestSeller: boolean
  previewDownloadUrl: string
  maxQuantity?: number | null
  mailchimpTag: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: product_book_delete
// ====================================================

export interface product_book_delete {
  /**
   * Delete a product
   */
  productDelete: boolean | null
}

export interface product_book_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_books_index
// ====================================================

export interface product_books_index_productBooks_nodes {
  __typename: 'ProductBook'
  id: string
  title: string
}

export interface product_books_index_productBooks_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface product_books_index_productBooks {
  __typename: 'ProductBookList'
  nodes: product_books_index_productBooks_nodes[]
  totalCount: number
  pageInfo: product_books_index_productBooks_pageInfo
}

export interface product_books_index {
  /**
   * Get all product books
   */
  productBooks: product_books_index_productBooks | null
}

export interface product_books_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_books_view
// ====================================================

export interface product_books_view_productBook_image {
  __typename: 'Image'
  url: string
}

export interface product_books_view_productBook {
  __typename: 'ProductBook'
  id: string
  slug: string
  title: string
  shortDescription: string
  previewDownloadUrl: string
  mailchimpTag: string
  description: string
  image: product_books_view_productBook_image
}

export interface product_books_view {
  /**
   * Get a product book by it's id or slug
   */
  productBook: product_books_view_productBook | null
}

export interface product_books_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductBookUpdateMutation
// ====================================================

export interface ProductBookUpdateMutation_productBookUpdate {
  __typename: 'ProductBook'
  id: string
}

export interface ProductBookUpdateMutation {
  /**
   * Update a product book
   */
  productBookUpdate: ProductBookUpdateMutation_productBookUpdate | null
}

export interface ProductBookUpdateMutationVariables {
  description: string
  fulfillableIds?: string[] | null
  id: string
  image?: any | null
  priceId?: string | null
  shortDescription: string
  slug: string
  title: string
  variants?: ProductVariantUpdateInput[] | null
  contents: ProductContentInput[]
  releaseDate?: any | null
  isBestSeller: boolean
  previewDownloadUrl: string
  maxQuantity?: number | null
  mailchimpTag: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_books_update
// ====================================================

export interface product_books_update_productBook_image {
  __typename: 'Image'
  url: string
}

export interface product_books_update_productBook_contents {
  __typename: 'ProductContent'
  title: string
  description: string | null
}

export interface product_books_update_productBook_options {
  __typename: 'ProductOption'
  name: string
  value: string
}

export interface product_books_update_productBook_variants_nodes_options {
  __typename: 'ProductOption'
  name: string
  value: string
}

export interface product_books_update_productBook_variants_nodes_fulfillables_nodes {
  __typename: 'ProductFulfillable'
  id: string
}

export interface product_books_update_productBook_variants_nodes_fulfillables {
  __typename: 'ProductFulfillableList'
  nodes: product_books_update_productBook_variants_nodes_fulfillables_nodes[]
}

export interface product_books_update_productBook_variants_nodes_price {
  __typename: 'Price'
  id: string
}

export interface product_books_update_productBook_variants_nodes {
  __typename: 'ProductVariant'
  id: string
  title: string
  description: string | null
  maxQuantity: number
  mailchimpTag: string | null
  options: product_books_update_productBook_variants_nodes_options[] | null
  fulfillables: product_books_update_productBook_variants_nodes_fulfillables | null
  price: product_books_update_productBook_variants_nodes_price
}

export interface product_books_update_productBook_variants {
  __typename: 'ProductVariantList'
  nodes: product_books_update_productBook_variants_nodes[]
}

export interface product_books_update_productBook {
  __typename: 'ProductBook'
  id: string
  title: string
  slug: string
  image: product_books_update_productBook_image
  description: string
  shortDescription: string
  isBestSeller: boolean
  releaseDate: any | null
  previewDownloadUrl: string
  mailchimpTag: string
  contents: product_books_update_productBook_contents[]
  options: product_books_update_productBook_options[] | null
  variants: product_books_update_productBook_variants
}

export interface product_books_update {
  /**
   * Get a product book by it's id or slug
   */
  productBook: product_books_update_productBook | null
}

export interface product_books_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductCourseCreate
// ====================================================

export interface ProductCourseCreate_productCourseCreate {
  __typename: 'ProductCourse'
  id: string
}

export interface ProductCourseCreate {
  /**
   * Create a product course
   */
  productCourseCreate: ProductCourseCreate_productCourseCreate | null
}

export interface ProductCourseCreateVariables {
  courseId: string
  description: string
  image: any
  priceId?: string | null
  fulfillableIds?: string[] | null
  instructorIds?: string[] | null
  shortDescription: string
  slug: string
  title: string
  variants?: ProductVariantCreateInput[] | null
  contents: ProductContentInput[]
  difficultyLevel: ProductCourseDifficultyLevel
  length: ProductCourseLength
  requirements: string
  subjects: ProductCourseSubject[]
  releaseDate?: any | null
  syllabusUrl: string
  targetAudience: string
  overview: string[]
  isBestSeller: boolean
  projects?: ProductCourseProjectCreateInput[] | null
  maxQuantity?: number | null
  order: number
  status: ProductStatus
  mailchimpTag: string
  isBeta: boolean
  trailerWistiaId?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: product_course_delete
// ====================================================

export interface product_course_delete {
  /**
   * Delete a product
   */
  productDelete: boolean | null
}

export interface product_course_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_courses_index
// ====================================================

export interface product_courses_index_productCourses_nodes {
  __typename: 'ProductCourse'
  id: string
  title: string
  status: ProductStatus
  order: number
}

export interface product_courses_index_productCourses_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface product_courses_index_productCourses {
  __typename: 'ProductCourseList'
  nodes: product_courses_index_productCourses_nodes[]
  totalCount: number
  pageInfo: product_courses_index_productCourses_pageInfo
}

export interface product_courses_index {
  /**
   * Get all product courses
   */
  productCourses: product_courses_index_productCourses | null
}

export interface product_courses_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_courses_view
// ====================================================

export interface product_courses_view_productCourse_image {
  __typename: 'Image'
  url: string
}

export interface product_courses_view_productCourse_lowestPrice {
  __typename: 'Money'
  value: number
  currencyCode: CurrencyCode
}

export interface product_courses_view_productCourse {
  __typename: 'ProductCourse'
  id: string
  slug: string
  title: string
  shortDescription: string
  description: string
  status: ProductStatus
  syllabusUrl: string
  mailchimpTag: string
  isBeta: boolean
  image: product_courses_view_productCourse_image
  lowestPrice: product_courses_view_productCourse_lowestPrice
}

export interface product_courses_view {
  /**
   * Get a product course by it's id or slug
   */
  productCourse: product_courses_view_productCourse | null
}

export interface product_courses_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProductCourseUpdateMutation
// ====================================================

export interface ProductCourseUpdateMutation_productCourseUpdate {
  __typename: 'ProductCourse'
  id: string
}

export interface ProductCourseUpdateMutation {
  /**
   * Update a product course
   */
  productCourseUpdate: ProductCourseUpdateMutation_productCourseUpdate | null
}

export interface ProductCourseUpdateMutationVariables {
  id: string
  slug: string
  title: string
  courseId: string
  description: string
  fulfillableIds?: string[] | null
  instructorIds?: string[] | null
  image?: any | null
  priceId?: string | null
  maxQuantity?: number | null
  releaseDate?: any | null
  shortDescription: string
  variants?: ProductVariantUpdateInput[] | null
  contents: ProductContentInput[]
  difficultyLevel: ProductCourseDifficultyLevel
  length: ProductCourseLength
  requirements: string
  subjects: ProductCourseSubject[]
  syllabusUrl: string
  targetAudience: string
  overview: string[]
  isBestSeller: boolean
  projects?: ProductCourseProjectUpdateInput[] | null
  order: number
  status: ProductStatus
  mailchimpTag: string
  isBeta: boolean
  trailerWistiaId?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product_courses_update
// ====================================================

export interface product_courses_update_productCourse_image {
  __typename: 'Image'
  url: string
  width: number
  height: number
}

export interface product_courses_update_productCourse_contents {
  __typename: 'ProductContent'
  title: string
  description: string | null
}

export interface product_courses_update_productCourse_projects_ProductCourseProjectImage_image {
  __typename: 'Image'
  url: string
}

export interface product_courses_update_productCourse_projects_ProductCourseProjectImage {
  __typename: 'ProductCourseProjectImage'
  id: string
  title: string
  description: string
  image: product_courses_update_productCourse_projects_ProductCourseProjectImage_image
}

export interface product_courses_update_productCourse_projects_ProductCourseProjectVideo {
  __typename: 'ProductCourseProjectVideo'
  id: string
  title: string
  description: string
  videoId: string
}

export type product_courses_update_productCourse_projects =
  | product_courses_update_productCourse_projects_ProductCourseProjectImage
  | product_courses_update_productCourse_projects_ProductCourseProjectVideo

export interface product_courses_update_productCourse_instructors {
  __typename: 'Person'
  id: string
}

export interface product_courses_update_productCourse_options {
  __typename: 'ProductOption'
  name: string
  value: string
}

export interface product_courses_update_productCourse_variants_nodes_options {
  __typename: 'ProductOption'
  name: string
  value: string
}

export interface product_courses_update_productCourse_variants_nodes_fulfillables_nodes {
  __typename: 'ProductFulfillable'
  id: string
}

export interface product_courses_update_productCourse_variants_nodes_fulfillables {
  __typename: 'ProductFulfillableList'
  nodes: product_courses_update_productCourse_variants_nodes_fulfillables_nodes[]
}

export interface product_courses_update_productCourse_variants_nodes_price {
  __typename: 'Price'
  id: string
}

export interface product_courses_update_productCourse_variants_nodes {
  __typename: 'ProductVariant'
  id: string
  title: string
  description: string | null
  maxQuantity: number
  mailchimpTag: string | null
  options: product_courses_update_productCourse_variants_nodes_options[] | null
  fulfillables: product_courses_update_productCourse_variants_nodes_fulfillables | null
  price: product_courses_update_productCourse_variants_nodes_price
}

export interface product_courses_update_productCourse_variants {
  __typename: 'ProductVariantList'
  nodes: product_courses_update_productCourse_variants_nodes[]
}

export interface product_courses_update_productCourse_course {
  __typename: 'Course'
  id: string
}

export interface product_courses_update_productCourse {
  __typename: 'ProductCourse'
  id: string
  title: string
  slug: string
  image: product_courses_update_productCourse_image
  isBeta: boolean
  description: string
  shortDescription: string
  mailchimpTag: string
  trailerWistiaId: string | null
  contents: product_courses_update_productCourse_contents[]
  projects: product_courses_update_productCourse_projects[] | null
  difficultyLevel: ProductCourseDifficultyLevel
  length: ProductCourseLength
  instructors: product_courses_update_productCourse_instructors[] | null
  order: number
  requirements: string
  subjects: ProductCourseSubject[]
  releaseDate: any | null
  syllabusUrl: string
  status: ProductStatus
  targetAudience: string
  overview: string[]
  isBestSeller: boolean
  options: product_courses_update_productCourse_options[] | null
  variants: product_courses_update_productCourse_variants
  course: product_courses_update_productCourse_course
}

export interface product_courses_update {
  /**
   * Get a product course by it's id or slug
   */
  productCourse: product_courses_update_productCourse | null
}

export interface product_courses_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RefundCancel
// ====================================================

export interface RefundCancel_refundCancel {
  __typename: 'Refund'
  id: string
  status: RefundStatus
}

export interface RefundCancel {
  /**
   * Cancel a refund
   */
  refundCancel: RefundCancel_refundCancel | null
}

export interface RefundCancelVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: refund_draft
// ====================================================

export interface refund_draft_refundDraft_total {
  __typename: 'MoneyV1'
  value: number
  currencyCode: CurrencyCodeV1
}

export interface refund_draft_refundDraft {
  __typename: 'RefundDraft'
  total: refund_draft_refundDraft_total
}

export interface refund_draft {
  /**
   * Create a draft refund
   */
  refundDraft: refund_draft_refundDraft | null
}

export interface refund_draftVariables {
  orderId: string
  lineItems: RefundLineItemInput[]
  exchangeItems?: RefundExchangeItemInput[] | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RefundCreate
// ====================================================

export interface RefundCreate_refundCreate {
  __typename: 'Refund'
  id: string
}

export interface RefundCreate {
  /**
   * Create a refund
   */
  refundCreate: RefundCreate_refundCreate | null
}

export interface RefundCreateVariables {
  orderId: string
  lineItems: RefundLineItemInput[]
  exchangeItems?: RefundExchangeItemInput[] | null
  notes?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: refund_create
// ====================================================

export interface refund_create_order_customerDetails {
  __typename: 'OrderCustomerDetails'
  name: string
  email: string
}

export interface refund_create_order_giftDetails {
  __typename: 'OrderGiftDetails'
  name: string
  email: string
}

export interface refund_create_order_user {
  __typename: 'User'
  id: string
}

export interface refund_create_order_lineItems_nodes_product {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
}

export interface refund_create_order_lineItems_nodes_variant {
  __typename: 'ProductVariant'
  id: string
}

export interface refund_create_order_lineItems_nodes {
  __typename: 'OrderLineItem'
  id: string
  title: string
  product: refund_create_order_lineItems_nodes_product
  variant: refund_create_order_lineItems_nodes_variant
  quantity: number
}

export interface refund_create_order_lineItems {
  __typename: 'OrderLineItemList'
  nodes: refund_create_order_lineItems_nodes[]
}

export interface refund_create_order_billingAddress {
  __typename: 'OrderAddress'
  name: string
  line1: string
  line2: string | null
  city: string
  state: string
  country: string
  postalCode: string
}

export interface refund_create_order_shippingAddress {
  __typename: 'OrderAddress'
  name: string
  line1: string
  line2: string | null
  city: string
  state: string
  country: string
  postalCode: string
}

export interface refund_create_order_refunds_nodes_lineItems_nodes_orderLineItem_variant {
  __typename: 'ProductVariant'
  id: string
}

export interface refund_create_order_refunds_nodes_lineItems_nodes_orderLineItem {
  __typename: 'OrderLineItem'
  id: string
  variant: refund_create_order_refunds_nodes_lineItems_nodes_orderLineItem_variant
}

export interface refund_create_order_refunds_nodes_lineItems_nodes {
  __typename: 'RefundLineItem'
  id: string
  orderLineItem: refund_create_order_refunds_nodes_lineItems_nodes_orderLineItem
  quantity: number
}

export interface refund_create_order_refunds_nodes_lineItems {
  __typename: 'RefundLineItemList'
  nodes: refund_create_order_refunds_nodes_lineItems_nodes[]
}

export interface refund_create_order_refunds_nodes {
  __typename: 'Refund'
  lineItems: refund_create_order_refunds_nodes_lineItems | null
  id: string
  updatedAt: any
}

export interface refund_create_order_refunds {
  __typename: 'RefundList'
  nodes: refund_create_order_refunds_nodes[]
}

export interface refund_create_order_total {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface refund_create_order_value {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface refund_create_order_discounts_nodes {
  __typename: 'OrderDiscount'
  code: string
  percent: number
}

export interface refund_create_order_discounts {
  __typename: 'OrderDiscountList'
  nodes: refund_create_order_discounts_nodes[]
}

export interface refund_create_order {
  __typename: 'Order'
  id: string
  customerDetails: refund_create_order_customerDetails
  giftDetails: refund_create_order_giftDetails | null
  user: refund_create_order_user | null
  type: OrderType
  lineItems: refund_create_order_lineItems | null
  billingAddress: refund_create_order_billingAddress | null
  shippingAddress: refund_create_order_shippingAddress | null
  refunds: refund_create_order_refunds | null
  total: refund_create_order_total
  value: refund_create_order_value
  discounts: refund_create_order_discounts | null
  version: OrderVersion
  insertedAt: any
}

export interface refund_create {
  /**
   * Get an order by it's id
   */
  order: refund_create_order | null
}

export interface refund_createVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: refund_index
// ====================================================

export interface refund_index_refunds_nodes_order {
  __typename: 'Order'
  id: string
}

export interface refund_index_refunds_nodes {
  __typename: 'Refund'
  id: string
  order: refund_index_refunds_nodes_order
  status: RefundStatus
}

export interface refund_index_refunds {
  __typename: 'RefundList'
  nodes: refund_index_refunds_nodes[]
}

export interface refund_index {
  /**
   * Get all refunds
   */
  refunds: refund_index_refunds | null
}

export interface refund_indexVariables {
  page?: number | null
  perPage?: number | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: refund_view
// ====================================================

export interface refund_view_refund_lineItems_nodes_orderLineItem_product {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
}

export interface refund_view_refund_lineItems_nodes_orderLineItem_variant {
  __typename: 'ProductVariant'
  title: string
}

export interface refund_view_refund_lineItems_nodes_orderLineItem {
  __typename: 'OrderLineItem'
  product: refund_view_refund_lineItems_nodes_orderLineItem_product
  variant: refund_view_refund_lineItems_nodes_orderLineItem_variant
}

export interface refund_view_refund_lineItems_nodes {
  __typename: 'RefundLineItem'
  id: string
  quantity: number
  status: RefundStatus
  orderLineItem: refund_view_refund_lineItems_nodes_orderLineItem
}

export interface refund_view_refund_lineItems {
  __typename: 'RefundLineItemList'
  nodes: refund_view_refund_lineItems_nodes[]
}

export interface refund_view_refund_order_customerDetails {
  __typename: 'OrderCustomerDetails'
  name: string
}

export interface refund_view_refund_order {
  __typename: 'Order'
  id: string
  insertedAt: any
  customerDetails: refund_view_refund_order_customerDetails
}

export interface refund_view_refund_total {
  __typename: 'MoneyV1'
  value: number
  currencyCode: CurrencyCodeV1
}

export interface refund_view_refund_exchangeItems_nodes_variant {
  __typename: 'ProductVariant'
  title: string
}

export interface refund_view_refund_exchangeItems_nodes {
  __typename: 'RefundExchangeItem'
  id: string
  quantity: number
  variant: refund_view_refund_exchangeItems_nodes_variant
}

export interface refund_view_refund_exchangeItems {
  __typename: 'RefundExchangeItemList'
  nodes: refund_view_refund_exchangeItems_nodes[]
}

export interface refund_view_refund_basket {
  __typename: 'Basket'
  id: string
  status: BasketStatus
}

export interface refund_view_refund_payments_nodes_value {
  __typename: 'MoneyV1'
  value: number
  currencyCode: CurrencyCodeV1
}

export interface refund_view_refund_payments_nodes {
  __typename: 'RefundPayment'
  id: string
  value: refund_view_refund_payments_nodes_value
  insertedAt: any
}

export interface refund_view_refund_payments {
  __typename: 'RefundPaymentList'
  nodes: refund_view_refund_payments_nodes[]
}

export interface refund_view_refund {
  __typename: 'Refund'
  id: string
  lineItems: refund_view_refund_lineItems | null
  order: refund_view_refund_order
  notes: string | null
  total: refund_view_refund_total
  exchangeItems: refund_view_refund_exchangeItems | null
  basket: refund_view_refund_basket | null
  payments: refund_view_refund_payments | null
  status: RefundStatus
  insertedAt: any
  updatedAt: any
}

export interface refund_view {
  /**
   * Get refund by it's id
   */
  refund: refund_view_refund | null
}

export interface refund_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: refund_update
// ====================================================

export interface refund_update_order_customerDetails {
  __typename: 'OrderCustomerDetails'
  name: string
  email: string
}

export interface refund_update_order_lineItems_nodes_price {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface refund_update_order_lineItems_nodes_product {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
}

export interface refund_update_order_lineItems_nodes_variant {
  __typename: 'ProductVariant'
  id: string
}

export interface refund_update_order_lineItems_nodes {
  __typename: 'OrderLineItem'
  id: string
  title: string
  price: refund_update_order_lineItems_nodes_price
  product: refund_update_order_lineItems_nodes_product
  variant: refund_update_order_lineItems_nodes_variant
  quantity: number
}

export interface refund_update_order_lineItems {
  __typename: 'OrderLineItemList'
  nodes: refund_update_order_lineItems_nodes[]
}

export interface refund_update_order_paymentDetails_paypal {
  __typename: 'OrderPaymentDetailsPaypal'
  token: string
}

export interface refund_update_order_paymentDetails_stripe {
  __typename: 'OrderPaymentDetailsStripe'
  methodId: string
}

export interface refund_update_order_paymentDetails {
  __typename: 'OrderPaymentDetails'
  paypal: refund_update_order_paymentDetails_paypal | null
  stripe: refund_update_order_paymentDetails_stripe | null
}

export interface refund_update_order_total {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface refund_update_order_value {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface refund_update_order_discounts_nodes {
  __typename: 'OrderDiscount'
  code: string
  description: string
  name: string
  percent: number
}

export interface refund_update_order_discounts {
  __typename: 'OrderDiscountList'
  nodes: refund_update_order_discounts_nodes[]
}

export interface refund_update_order {
  __typename: 'Order'
  id: string
  customerDetails: refund_update_order_customerDetails
  lineItems: refund_update_order_lineItems | null
  paymentDetails: refund_update_order_paymentDetails
  total: refund_update_order_total
  value: refund_update_order_value
  discounts: refund_update_order_discounts | null
  version: OrderVersion
  insertedAt: any
}

export interface refund_update {
  /**
   * Get an order by it's id
   */
  order: refund_update_order | null
}

export interface refund_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resources_create_mutation
// ====================================================

export interface resources_create_mutation_resourceCreate {
  __typename: 'Resource'
  id: string
}

export interface resources_create_mutation {
  /**
   * Create a resource
   */
  resourceCreate: resources_create_mutation_resourceCreate | null
}

export interface resources_create_mutationVariables {
  description: string
  image: any
  tags: ResourceTag[]
  title: string
  url: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: resources_index
// ====================================================

export interface resources_index_resources_nodes {
  __typename: 'Resource'
  id: string
  title: string
}

export interface resources_index_resources_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface resources_index_resources {
  __typename: 'ResourceList'
  nodes: resources_index_resources_nodes[]
  totalCount: number
  pageInfo: resources_index_resources_pageInfo
}

export interface resources_index {
  /**
   * Get all resources
   */
  resources: resources_index_resources | null
}

export interface resources_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resources_update_mutation
// ====================================================

export interface resources_update_mutation_resourceUpdate {
  __typename: 'Resource'
  id: string
}

export interface resources_update_mutation {
  /**
   * Update a resource
   */
  resourceUpdate: resources_update_mutation_resourceUpdate | null
}

export interface resources_update_mutationVariables {
  id: string
  description: string
  image: any
  tags: ResourceTag[]
  title: string
  url: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: resources_update
// ====================================================

export interface resources_update_resource_image {
  __typename: 'Image'
  url: string
}

export interface resources_update_resource {
  __typename: 'Resource'
  id: string
  description: string
  image: resources_update_resource_image
  insertedAt: any
  tags: ResourceTag[]
  title: string
  url: string
}

export interface resources_update {
  /**
   * Get resource
   */
  resource: resources_update_resource | null
}

export interface resources_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: resources_view
// ====================================================

export interface resources_view_resource_image {
  __typename: 'Image'
  url: string
}

export interface resources_view_resource {
  __typename: 'Resource'
  id: string
  description: string
  image: resources_view_resource_image
  insertedAt: any
  tags: ResourceTag[]
  title: string
  url: string
}

export interface resources_view {
  /**
   * Get resource
   */
  resource: resources_view_resource | null
}

export interface resources_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ScholarshipCreateMutation
// ====================================================

export interface ScholarshipCreateMutation_scholarshipCreate {
  __typename: 'Scholarship'
  id: string
}

export interface ScholarshipCreateMutation {
  /**
   * Create a scholarship
   */
  scholarshipCreate: ScholarshipCreateMutation_scholarshipCreate | null
}

export interface ScholarshipCreateMutationVariables {
  applicationUrl: string
  body: string
  closingAt: any
  image: any
  intro: string
  publishedAt: any
  slug: string
  title: string
  winnersNotifiedAt: any
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: scholarship_delete
// ====================================================

export interface scholarship_delete_scholarshipDelete {
  __typename: 'Scholarship'
  id: string
}

export interface scholarship_delete {
  /**
   * Delete scholarship
   */
  scholarshipDelete: scholarship_delete_scholarshipDelete | null
}

export interface scholarship_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: scholarships_index
// ====================================================

export interface scholarships_index_scholarships_nodes {
  __typename: 'Scholarship'
  id: string
  title: string
}

export interface scholarships_index_scholarships_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface scholarships_index_scholarships {
  __typename: 'ScholarshipList'
  nodes: scholarships_index_scholarships_nodes[]
  totalCount: number
  pageInfo: scholarships_index_scholarships_pageInfo
}

export interface scholarships_index {
  /**
   * Get all scholarships
   */
  scholarships: scholarships_index_scholarships | null
}

export interface scholarships_indexVariables {
  page: number
  perPage: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: scholarships_view
// ====================================================

export interface scholarships_view_scholarship_image {
  __typename: 'Image'
  url: string
}

export interface scholarships_view_scholarship {
  __typename: 'Scholarship'
  id: string
  title: string
  applicationUrl: string
  body: string
  image: scholarships_view_scholarship_image
}

export interface scholarships_view {
  /**
   * Get scholarship
   */
  scholarship: scholarships_view_scholarship | null
}

export interface scholarships_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ScholarshipUpdateMutation
// ====================================================

export interface ScholarshipUpdateMutation_scholarshipUpdate {
  __typename: 'Scholarship'
  id: string
}

export interface ScholarshipUpdateMutation {
  /**
   * Update a scholarship
   */
  scholarshipUpdate: ScholarshipUpdateMutation_scholarshipUpdate | null
}

export interface ScholarshipUpdateMutationVariables {
  id: string
  applicationUrl: string
  body: string
  closingAt: any
  image?: any | null
  intro: string
  publishedAt: any
  slug: string
  title: string
  winnersNotifiedAt: any
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: scholarship_update
// ====================================================

export interface scholarship_update_scholarship_image {
  __typename: 'Image'
  url: string
}

export interface scholarship_update_scholarship {
  __typename: 'Scholarship'
  id: string
  applicationUrl: string
  body: string
  closingAt: any
  image: scholarship_update_scholarship_image
  intro: string
  publishedAt: any
  slug: string
  title: string
  winnersNotifiedAt: any
}

export interface scholarship_update {
  /**
   * Get scholarship
   */
  scholarship: scholarship_update_scholarship | null
}

export interface scholarship_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SettingsUpdateMutation
// ====================================================

export interface SettingsUpdateMutation_settingsUpdate_announcement {
  __typename: 'Announcement'
  body: string
}

export interface SettingsUpdateMutation_settingsUpdate {
  __typename: 'Settings'
  announcement: SettingsUpdateMutation_settingsUpdate_announcement | null
}

export interface SettingsUpdateMutation {
  /**
   * Update settings
   */
  settingsUpdate: SettingsUpdateMutation_settingsUpdate | null
}

export interface SettingsUpdateMutationVariables {
  announcement?: AnnouncementInput | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: settings_update
// ====================================================

export interface settings_update_settings_announcement {
  __typename: 'Announcement'
  id: string
  body: string
  hideAt: any | null
  path: string | null
  showAt: any | null
}

export interface settings_update_settings {
  __typename: 'Settings'
  announcement: settings_update_settings_announcement | null
}

export interface settings_update {
  /**
   * Get the settings
   */
  settings: settings_update_settings
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: settings_view
// ====================================================

export interface settings_view_settings_announcement {
  __typename: 'Announcement'
  id: string
  body: string
  path: string | null
}

export interface settings_view_settings {
  __typename: 'Settings'
  announcement: settings_view_settings_announcement | null
}

export interface settings_view {
  /**
   * Get the settings
   */
  settings: settings_view_settings
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: teams_create_mutation
// ====================================================

export interface teams_create_mutation_teamCreate {
  __typename: 'Team'
  id: string
}

export interface teams_create_mutation {
  /**
   * Create a team
   */
  teamCreate: teams_create_mutation_teamCreate | null
}

export interface teams_create_mutationVariables {
  adminEmail: string
  plan: TeamPlan
  title: string
  userEmails: string[]
  stripeSubscriptionId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: teams_index
// ====================================================

export interface teams_index_teams_nodes {
  __typename: 'Team'
  id: string
  title: string
}

export interface teams_index_teams_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface teams_index_teams {
  __typename: 'TeamList'
  nodes: teams_index_teams_nodes[]
  totalCount: number
  pageInfo: teams_index_teams_pageInfo
}

export interface teams_index {
  /**
   * All teams
   */
  teams: teams_index_teams | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: teams_update_mutation
// ====================================================

export interface teams_update_mutation_teamUpdate {
  __typename: 'Team'
  id: string
}

export interface teams_update_mutation {
  /**
   * Update a team
   */
  teamUpdate: teams_update_mutation_teamUpdate | null
}

export interface teams_update_mutationVariables {
  id: string
  adminEmail: string
  plan: TeamPlan
  title: string
  userEmails: string[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: teams_update
// ====================================================

export interface teams_update_team_users {
  __typename: 'User'
  id: string
  email: string
}

export interface teams_update_team {
  __typename: 'Team'
  id: string
  title: string
  plan: TeamPlan
  adminEmail: string
  users: teams_update_team_users[]
}

export interface teams_update {
  /**
   * Get a team by it's id
   */
  team: teams_update_team | null
}

export interface teams_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: teams_view
// ====================================================

export interface teams_view_team_admin {
  __typename: 'User'
  id: string
  email: string
}

export interface teams_view_team_users {
  __typename: 'User'
  id: string
  email: string
}

export interface teams_view_team_membership_plan_renewalCost {
  __typename: 'Money'
  value: number
  currencyCode: CurrencyCode
}

export interface teams_view_team_membership_plan {
  __typename: 'TeamMembershipPlan'
  stripeSubscriptionId: string
  renewalDate: any
  renewalCost: teams_view_team_membership_plan_renewalCost | null
}

export interface teams_view_team_membership {
  __typename: 'TeamMembership'
  id: string
  status: MembershipStatus
  plan: teams_view_team_membership_plan | null
}

export interface teams_view_team {
  __typename: 'Team'
  id: string
  title: string
  adminEmail: string
  admin: teams_view_team_admin | null
  users: teams_view_team_users[]
  membership: teams_view_team_membership
  plan: TeamPlan
  insertedAt: any
}

export interface teams_view {
  /**
   * Get a team by it's id
   */
  team: teams_view_team | null
}

export interface teams_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: user_delete
// ====================================================

export interface user_delete_userDelete {
  __typename: 'User'
  id: string
}

export interface user_delete {
  /**
   * Delete a user
   */
  userDelete: user_delete_userDelete | null
}

export interface user_deleteVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserUpdate
// ====================================================

export interface UserUpdate_userUpdate {
  __typename: 'User'
  id: string
}

export interface UserUpdate {
  /**
   * Update a user
   */
  userUpdate: UserUpdate_userUpdate | null
}

export interface UserUpdateVariables {
  id: string
  email: string
  directAccessCourseIds?: string[] | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: user_update
// ====================================================

export interface user_update_user_courses_nodes_course {
  __typename: 'Course'
  id: string
}

export interface user_update_user_courses_nodes {
  __typename: 'UserCourse'
  id: string
  accessType: UserCourseAccessType
  course: user_update_user_courses_nodes_course
}

export interface user_update_user_courses {
  __typename: 'UserCourseList'
  nodes: user_update_user_courses_nodes[]
}

export interface user_update_user {
  __typename: 'User'
  id: string
  email: string
  courses: user_update_user_courses | null
}

export interface user_update {
  /**
   * Get a user by their id
   */
  user: user_update_user | null
}

export interface user_updateVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserAdmin
// ====================================================

export interface UserAdmin_userUpdateAdmin {
  __typename: 'User'
  id: string
  isAdmin: boolean
}

export interface UserAdmin {
  /**
   * Update a user's admin state
   */
  userUpdateAdmin: UserAdmin_userUpdateAdmin | null
}

export interface UserAdminVariables {
  id: string
  isAdmin: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: users_isAdminButton
// ====================================================

export interface users_isAdminButton_user {
  __typename: 'User'
  isAdmin: boolean
}

export interface users_isAdminButton {
  /**
   * Get a user by their id
   */
  user: users_isAdminButton_user | null
}

export interface users_isAdminButtonVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserFreeze
// ====================================================

export interface UserFreeze_userUpdateFreeze {
  __typename: 'User'
  id: string
  isFrozen: boolean
}

export interface UserFreeze {
  /**
   * Update a user's frozen state
   */
  userUpdateFreeze: UserFreeze_userUpdateFreeze | null
}

export interface UserFreezeVariables {
  id: string
  isFrozen: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: users_isFrozenButton
// ====================================================

export interface users_isFrozenButton_user {
  __typename: 'User'
  isFrozen: boolean
  hasOverdueInvoice: boolean
}

export interface users_isFrozenButton {
  /**
   * Get a user by their id
   */
  user: users_isFrozenButton_user | null
}

export interface users_isFrozenButtonVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HasOverdueInvoiceButton
// ====================================================

export interface HasOverdueInvoiceButton_userUpdateHasOverdueInvoice {
  __typename: 'User'
  id: string
  hasOverdueInvoice: boolean
}

export interface HasOverdueInvoiceButton {
  /**
   * Update a user's over due invoice state
   */
  userUpdateHasOverdueInvoice: HasOverdueInvoiceButton_userUpdateHasOverdueInvoice | null
}

export interface HasOverdueInvoiceButtonVariables {
  id: string
  hasOverdueInvoice: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: users_hasOverdueInvoiceButton
// ====================================================

export interface users_hasOverdueInvoiceButton_user {
  __typename: 'User'
  isFrozen: boolean
  hasOverdueInvoice: boolean
}

export interface users_hasOverdueInvoiceButton {
  /**
   * Get a user by their id
   */
  user: users_hasOverdueInvoiceButton_user | null
}

export interface users_hasOverdueInvoiceButtonVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserInviteToSlack
// ====================================================

export interface UserInviteToSlack_userSlackInvite {
  __typename: 'User'
  id: string
}

export interface UserInviteToSlack {
  /**
   * Add user to slack
   */
  userSlackInvite: UserInviteToSlack_userSlackInvite | null
}

export interface UserInviteToSlackVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: users_view
// ====================================================

export interface users_view_user_courses_nodes_progress {
  __typename: 'UserProgress'
  status: UserProgressStatus
  percentage: number
}

export interface users_view_user_courses_nodes_course_image {
  __typename: 'Image'
  url: string
}

export interface users_view_user_courses_nodes_course {
  __typename: 'Course'
  id: string
  title: string
  image: users_view_user_courses_nodes_course_image
}

export interface users_view_user_courses_nodes {
  __typename: 'UserCourse'
  id: string
  accessType: UserCourseAccessType
  progress: users_view_user_courses_nodes_progress
  course: users_view_user_courses_nodes_course
}

export interface users_view_user_courses {
  __typename: 'UserCourseList'
  nodes: users_view_user_courses_nodes[]
}

export interface users_view_user_team {
  __typename: 'Team'
  id: string
  title: string
}

export interface users_view_user_membership_plan_product {
  __typename: 'MembershipProduct'
  id: string
  title: string
}

export interface users_view_user_membership_plan_nextPayment {
  __typename: 'Money'
  value: number
  currencyCode: CurrencyCode
}

export interface users_view_user_membership_plan_currentPrice_recurring {
  __typename: 'StripePriceRecurring'
  interval: StripePriceRecurringInterval
  intervalCount: number
}

export interface users_view_user_membership_plan_currentPrice {
  __typename: 'StripePrice'
  id: string
  recurring: users_view_user_membership_plan_currentPrice_recurring | null
}

export interface users_view_user_membership_plan {
  __typename: 'UserMembershipPlan'
  stripeSubscriptionId: string
  product: users_view_user_membership_plan_product
  renewalDate: any
  nextPayment: users_view_user_membership_plan_nextPayment | null
  currentPrice: users_view_user_membership_plan_currentPrice
}

export interface users_view_user_membership_invoices_product {
  __typename: 'MembershipProduct'
  id: string
  title: string
}

export interface users_view_user_membership_invoices_price_recurring {
  __typename: 'StripePriceRecurring'
  interval: StripePriceRecurringInterval
  intervalCount: number
}

export interface users_view_user_membership_invoices_price {
  __typename: 'StripePrice'
  id: string
  recurring: users_view_user_membership_invoices_price_recurring | null
}

export interface users_view_user_membership_invoices_total {
  __typename: 'Money'
  currencyCode: CurrencyCode
  value: number
}

export interface users_view_user_membership_invoices {
  __typename: 'MembershipInvoice'
  id: string
  status: MembershipInvoiceStatus
  product: users_view_user_membership_invoices_product
  price: users_view_user_membership_invoices_price
  total: users_view_user_membership_invoices_total
  createdAt: any
}

export interface users_view_user_membership {
  __typename: 'UserMembership'
  id: string
  status: MembershipStatus
  stripeCustomerId: string
  plan: users_view_user_membership_plan | null
  invoices: users_view_user_membership_invoices[] | null
}

export interface users_view_user_orders_nodes_total {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface users_view_user_orders_nodes {
  __typename: 'Order'
  id: string
  insertedAt: any
  total: users_view_user_orders_nodes_total
}

export interface users_view_user_orders {
  __typename: 'OrderList'
  nodes: users_view_user_orders_nodes[]
}

export interface users_view_user {
  __typename: 'User'
  id: string
  name: string | null
  username: string
  email: string
  isAdmin: boolean
  isFrozen: boolean
  hasOverdueInvoice: boolean
  type: UserType
  courses: users_view_user_courses | null
  team: users_view_user_team | null
  membership: users_view_user_membership | null
  orders: users_view_user_orders | null
  insertedAt: any
}

export interface users_view {
  /**
   * Get a user by their id
   */
  user: users_view_user | null
}

export interface users_viewVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: users_index
// ====================================================

export interface users_index_users_nodes {
  __typename: 'User'
  id: string
  name: string | null
  email: string
  insertedAt: any
}

export interface users_index_users_pageInfo {
  __typename: 'PageInfo'
  hasNextPage: boolean
  totalCount: number
}

export interface users_index_users {
  __typename: 'UserList'
  nodes: users_index_users_nodes[]
  totalCount: number
  pageInfo: users_index_users_pageInfo
}

export interface users_index {
  /**
   * All users
   */
  users: users_index_users | null
}

export interface users_indexVariables {
  page: number
  perPage: number
  search?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserSession
// ====================================================

export interface UserSession_avatar {
  __typename: 'Image'
  url: string
}

export interface UserSession {
  __typename: 'Session'
  id: string
  name: string | null
  username: string
  email: string
  token: string | null
  avatar: UserSession_avatar | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MoneyPreview
// ====================================================

export interface MoneyPreview {
  __typename: 'Money'
  value: number
  currencyCode: CurrencyCode
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductVariantPreview
// ====================================================

export interface ProductVariantPreview_product_image {
  __typename: 'Image'
  url: string
}

export interface ProductVariantPreview_product {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
  title: string
  image: ProductVariantPreview_product_image
}

export interface ProductVariantPreview {
  __typename: 'ProductVariant'
  id: string
  title: string
  product: ProductVariantPreview_product
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StripePricePreview
// ====================================================

export interface StripePricePreview_recurring {
  __typename: 'StripePriceRecurring'
  interval: StripePriceRecurringInterval
  intervalCount: number
}

export interface StripePricePreview {
  __typename: 'StripePrice'
  value: number
  currencyCode: CurrencyCode
  recurring: StripePricePreview_recurring | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CourseChapterPreview
// ====================================================

export interface CourseChapterPreview {
  __typename: 'CourseChapter'
  id: string
  title: string
  position: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CourseHomeworkPreview
// ====================================================

export interface CourseHomeworkPreview {
  __typename: 'CourseHomework'
  id: string
  title: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CourseLessonPreview
// ====================================================

export interface CourseLessonPreview {
  __typename: 'CourseLesson'
  id: string
  title: string
  position: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CourseProjectPreview
// ====================================================

export interface CourseProjectPreview {
  __typename: 'CourseProject'
  id: string
  title: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CoursePreview
// ====================================================

export interface CoursePreview_image {
  __typename: 'Image'
  url: string
}

export interface CoursePreview {
  __typename: 'Course'
  id: string
  title: string
  image: CoursePreview_image
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserCoursePreview
// ====================================================

export interface UserCoursePreview_progress {
  __typename: 'UserProgress'
  status: UserProgressStatus
  percentage: number
}

export interface UserCoursePreview_course_image {
  __typename: 'Image'
  url: string
}

export interface UserCoursePreview_course {
  __typename: 'Course'
  id: string
  title: string
  image: UserCoursePreview_course_image
}

export interface UserCoursePreview {
  __typename: 'UserCourse'
  id: string
  accessType: UserCourseAccessType
  progress: UserCoursePreview_progress
  course: UserCoursePreview_course
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MembershipInvoiceCardComponent
// ====================================================

export interface MembershipInvoiceCardComponent_product {
  __typename: 'MembershipProduct'
  id: string
  title: string
}

export interface MembershipInvoiceCardComponent_price_recurring {
  __typename: 'StripePriceRecurring'
  interval: StripePriceRecurringInterval
  intervalCount: number
}

export interface MembershipInvoiceCardComponent_price {
  __typename: 'StripePrice'
  id: string
  recurring: MembershipInvoiceCardComponent_price_recurring | null
}

export interface MembershipInvoiceCardComponent_total {
  __typename: 'Money'
  currencyCode: CurrencyCode
  value: number
}

export interface MembershipInvoiceCardComponent {
  __typename: 'MembershipInvoice'
  id: string
  status: MembershipInvoiceStatus
  product: MembershipInvoiceCardComponent_product
  price: MembershipInvoiceCardComponent_price
  total: MembershipInvoiceCardComponent_total
  createdAt: any
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MembershipProductPreview
// ====================================================

export interface MembershipProductPreview_price_recurring {
  __typename: 'StripePriceRecurring'
  interval: StripePriceRecurringInterval
  intervalCount: number
}

export interface MembershipProductPreview_price {
  __typename: 'StripePrice'
  currencyCode: CurrencyCode
  value: number
  recurring: MembershipProductPreview_price_recurring | null
}

export interface MembershipProductPreview {
  __typename: 'MembershipProduct'
  id: string
  title: string
  price: MembershipProductPreview_price
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderLineItemPreview
// ====================================================

export interface OrderLineItemPreview_price {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface OrderLineItemPreview_product {
  __typename: 'ProductBook' | 'ProductCourse'
  id: string
  title: string
}

export interface OrderLineItemPreview {
  __typename: 'OrderLineItem'
  id: string
  title: string
  price: OrderLineItemPreview_price
  product: OrderLineItemPreview_product
  quantity: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderPreview
// ====================================================

export interface OrderPreview_total {
  __typename: 'MoneyV1'
  currencyCode: CurrencyCodeV1
  value: number
}

export interface OrderPreview {
  __typename: 'Order'
  id: string
  insertedAt: any
  total: OrderPreview_total
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonPreview
// ====================================================

export interface PersonPreview_image {
  __typename: 'Image'
  url: string
}

export interface PersonPreview {
  __typename: 'Person'
  id: string
  name: string
  image: PersonPreview_image
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PriceConversionPreview
// ====================================================

export interface PriceConversionPreview_currency {
  __typename: 'Currency'
  code: CurrencyCode
  name: string
}

export interface PriceConversionPreview {
  __typename: 'PriceConversion'
  currency: PriceConversionPreview_currency
  value: number
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductFulfillablePreview
// ====================================================

export interface ProductFulfillablePreview {
  __typename: 'ProductFulfillable'
  id: string
  title: string
  sku: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductBookPreview
// ====================================================

export interface ProductBookPreview_image {
  __typename: 'Image'
  url: string
}

export interface ProductBookPreview {
  __typename: 'ProductBook'
  id: string
  title: string
  image: ProductBookPreview_image
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductCoursesPreview
// ====================================================

export interface ProductCoursesPreview_image {
  __typename: 'Image'
  url: string
}

export interface ProductCoursesPreview {
  __typename: 'ProductCourse'
  id: string
  title: string
  image: ProductCoursesPreview_image
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserPreviewComponent
// ====================================================

export interface UserPreviewComponent_avatar {
  __typename: 'Image'
  url: string
}

export interface UserPreviewComponent {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  avatar: UserPreviewComponent_avatar | null
  type: UserType
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Basket status
 */
export enum BasketStatus {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
}

/**
 * Course difficulty level
 */
export enum CourseDifficultyLevel {
  ADVANCED = 'ADVANCED',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
}

/**
 * Course Length
 */
export enum CourseLength {
  EIGHT_WEEKS = 'EIGHT_WEEKS',
  FOUR_SIX_WEEKS = 'FOUR_SIX_WEEKS',
  SHORT = 'SHORT',
}

/**
 * Course status
 */
export enum CourseStatus {
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
  LOCKED = 'LOCKED',
}

/**
 * Course subject
 */
export enum CourseSubject {
  CODE = 'CODE',
  DESIGN = 'DESIGN',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
}

/**
 * Credit note reason
 */
export enum CreditNoteReason {
  DUPLICATE = 'DUPLICATE',
  FRAUDULENT = 'FRAUDULENT',
  ORDER_CHANGE = 'ORDER_CHANGE',
  PRODUCT_UNSATISFACTORY = 'PRODUCT_UNSATISFACTORY',
}

/**
 * Currency Code
 *
 * The available currency codes
 */
export enum CurrencyCode {
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  JPY = 'JPY',
  NOK = 'NOK',
  NZD = 'NZD',
  SEK = 'SEK',
  SGD = 'SGD',
  USD = 'USD',
}

/**
 * Currency Codes for historical ordeers
 */
export enum CurrencyCodeV1 {
  ARS = 'ARS',
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CZK = 'CZK',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  ILS = 'ILS',
  JPY = 'JPY',
  KRW = 'KRW',
  MXN = 'MXN',
  NOK = 'NOK',
  NZD = 'NZD',
  PLN = 'PLN',
  SEK = 'SEK',
  SGD = 'SGD',
  USD = 'USD',
  ZAR = 'ZAR',
}

/**
 * Discount count operator
 *
 * Operators that can be applied to count based discount expressions to create
 * discounts that rely on a specific number of products in the basket.
 */
export enum DiscountCountOperator {
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
}

/**
 * Discount string operator
 *
 * Operators that can be applied to string based discount expressions
 */
export enum DiscountOperator {
  IN = 'IN',
}

/**
 * Discount type
 *
 * "Automatic" discounts are applied automatically if the basket has qualifying
 * items. "Manual" discounts are applied by the customer using a discount code.
 */
export enum DiscountType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

/**
 * Expression type value
 */
export enum ExpressionTypeValue {
  BOOK = 'BOOK',
  COURSE = 'COURSE',
}

/**
 * Hall of famer type
 */
export enum HallOfFamerType {
  DESIGN = 'DESIGN',
  WEBSITE = 'WEBSITE',
}

/**
 * Membership invoice payment status
 */
export enum MembershipInvoicePaymentStatus {
  FAILED = 'FAILED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  SUCCEEDED = 'SUCCEEDED',
}

/**
 * Membership invoice status
 */
export enum MembershipInvoiceStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  REFUNDED = 'REFUNDED',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID',
}

/**
 * Membership status
 */
export enum MembershipStatus {
  ACTIVE = 'ACTIVE',
  DEFAULTED = 'DEFAULTED',
  INACTIVE = 'INACTIVE',
  TRIALING = 'TRIALING',
}

/**
 * Order type
 */
export enum OrderType {
  EXCHANGE = 'EXCHANGE',
  MANUAL = 'MANUAL',
  NORMAL = 'NORMAL',
}

/**
 * Order version
 *
 * Signifies the version number of the API that was used to create the order.
 */
export enum OrderVersion {
  ONE_ZERO = 'ONE_ZERO',
  THREE_ZERO = 'THREE_ZERO',
  TWO_ZERO = 'TWO_ZERO',
}

/**
 * Payment method
 */
export enum PaymentMethod {
  PAYPAL = 'PAYPAL',
  STRIPE = 'STRIPE',
}

/**
 * Product course difficulty level
 */
export enum ProductCourseDifficultyLevel {
  ADVANCED = 'ADVANCED',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
}

/**
 * Product Course Length
 */
export enum ProductCourseLength {
  EIGHT_WEEKS = 'EIGHT_WEEKS',
  FOUR_SIX_WEEKS = 'FOUR_SIX_WEEKS',
  SHORT = 'SHORT',
}

/**
 * Product course subject
 */
export enum ProductCourseSubject {
  CODE = 'CODE',
  DESIGN = 'DESIGN',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
}

/**
 * Product Status
 */
export enum ProductStatus {
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
}

/**
 * Refund status
 */
export enum RefundStatus {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  CANCELLED = 'CANCELLED',
  EXCHANGED = 'EXCHANGED',
  REFUNDED = 'REFUNDED',
}

/**
 * Resource tag
 */
export enum ResourceTag {
  CODE = 'CODE',
  DESIGN = 'DESIGN',
  IMAGES = 'IMAGES',
  INSPIRATION = 'INSPIRATION',
  TOOLS = 'TOOLS',
  TYPOGRAPHY = 'TYPOGRAPHY',
  VIDEO = 'VIDEO',
}

/**
 * Stripe price recurring interval
 */
export enum StripePriceRecurringInterval {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

/**
 * Team plan
 */
export enum TeamPlan {
  ENTERPRISE = 'ENTERPRISE',
  STANDARD = 'STANDARD',
}

/**
 * User course access type
 */
export enum UserCourseAccessType {
  DIRECT = 'DIRECT',
  MEMBERSHIP = 'MEMBERSHIP',
}

/**
 * User progress status
 */
export enum UserProgressStatus {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

/**
 * User Type
 */
export enum UserType {
  FREE = 'FREE',
  MEMBER = 'MEMBER',
  STUDENT = 'STUDENT',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

/**
 * Announcement
 */
export interface AnnouncementInput {
  body: string
  hideAt?: any | null
  id?: string | null
  path?: string | null
  showAt?: any | null
}

/**
 * Course asset file create input
 */
export interface CourseAssetFileCreateInput {
  file: any
  title: string
}

/**
 * Course asset link create input
 */
export interface CourseAssetLinkCreateInput {
  title: string
  url: string
}

/**
 * Currency conversion
 */
export interface CurrencyConversionInput {
  priceId: string
  value: number
}

/**
 * Discount clause
 */
export interface DiscountClauseInput {
  countExpressions?: ExpressionCountInput[] | null
  productExpressions?: ExpressionProductInput[] | null
  typeExpressions?: ExpressionTypeInput[] | null
}

/**
 * Expression: Count
 */
export interface ExpressionCountInput {
  operator: DiscountCountOperator
  value: number
}

/**
 * Expression: Product
 */
export interface ExpressionProductInput {
  operator: DiscountOperator
  value: string[]
}

/**
 * Expression: Type
 */
export interface ExpressionTypeInput {
  operator: DiscountOperator
  value: ExpressionTypeValue[]
}

/**
 * Money input
 */
export interface MoneyInput {
  currencyCode: CurrencyCode
  value: number
}

/**
 * Order address
 */
export interface OrderAddressInput {
  city: string
  country: string
  line1: string
  line2?: string | null
  name: string
  phoneNumber?: string | null
  postalCode: string
  state: string
}

/**
 * Order customer details
 */
export interface OrderCustomerDetailsInput {
  email: string
  name: string
}

/**
 * Order line items
 */
export interface OrderLineItemInput {
  price: MoneyInput
  quantity: number
  variantId: string
}

/**
 * Price conversion create
 */
export interface PriceConversionInput {
  currencyId: string
  value: number
}

/**
 * Product content
 */
export interface ProductContentInput {
  description?: string | null
  title: string
}

/**
 * Product course project create
 */
export interface ProductCourseProjectCreateInput {
  description: string
  image?: any | null
  title: string
  videoId?: string | null
}

/**
 * Product course project create
 */
export interface ProductCourseProjectUpdateInput {
  description: string
  id?: string | null
  image?: any | null
  title: string
  videoId?: string | null
}

/**
 * Product variant create
 */
export interface ProductVariantCreateInput {
  description?: string | null
  fulfillableIds?: string[] | null
  mailchimpTag?: string | null
  maxQuantity: number
  options: ProductVariantOptionInput[]
  priceId: string
  title: string
}

/**
 * Product variant option
 */
export interface ProductVariantOptionInput {
  name: string
  value: string
}

/**
 * Product variant update
 */
export interface ProductVariantUpdateInput {
  description?: string | null
  fulfillableIds?: string[] | null
  id?: string | null
  mailchimpTag?: string | null
  maxQuantity: number
  options?: ProductVariantOptionInput[] | null
  priceId?: string | null
  title: string
}

/**
 * Refund exchange item input
 */
export interface RefundExchangeItemInput {
  id: string
  quantity: number
}

/**
 * Refund line item
 */
export interface RefundLineItemInput {
  id: string
  quantity: number
  reason: string
}

//==============================================================
// END Enums and Input Objects
//==============================================================
