import React from 'react'
// import { useQuery } from '@apollo/client';

import Page from '../../components/Page'
// import { API } from '../../api';
// import { formatDateTimeDistance } from '../../utils';

// import QUERY from './queries';
// import * as styles from './styles';

const Home: React.FC = () => {
  return <Page>Welcome</Page>
}

export default Home
