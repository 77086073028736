import React from 'react'
import { useQuery } from '@apollo/client'
import { string, array, object, number } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import * as config from '../config'
import { configToInput } from '../../utils'

import { PRICES } from './queries'
import MUTATION from './mutations'

type FormValues = {
  name: string
  code: string
  conversions: {
    priceId: string
    value: number
  }[]
}

const CurrenciesCreate: React.FC = () => {
  const { data } = useQuery<API.CurrencyCreatePrices>(PRICES)

  if (!data?.prices?.nodes) {
    return null
  }

  return (
    <BaseForm<FormValues, API.CreateCurrencyVariables>
      mutation={MUTATION}
      validationSchema={{
        name: string().required(),
        code: string().required(),
        conversions: array()
          .of(
            object({
              priceId: string().required(),
              value: number().required(),
            }).required(),
          )
          .required(),
      }}
      initialValues={{
        name: '',
        code: '',
        conversions: data.prices.nodes.map((price) => ({
          priceId: price.id,
          value: 0,
        })),
      }}
      createVariablesFn={(values) => ({
        ...values,
        conversions: values.conversions.map((conversion) => ({
          priceId: conversion.priceId,
          value: conversion.value,
        })),
      })}
    >
      <Form.Row>{configToInput(config.NAME)}</Form.Row>

      <Form.Row>{configToInput(config.CODE)}</Form.Row>

      <Form.Legend label="Prices">
        {data.prices.nodes.map((price, i) => (
          <Form.Row key={price.id}>
            {configToInput(config.CONVERSIONS_VALUE(`conversions.${i}`, price.name))}
          </Form.Row>
        ))}
      </Form.Legend>
    </BaseForm>
  )
}

export default CurrenciesCreate
