import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@superhi/design'
import { useParams } from 'react-router-dom'

import { API } from '../../../../../../../api'
import { action as freezeAction } from '../FreezeButton'
import FREEZE_MUTATION from '../FreezeButton/mutations'

import MUTATION from './mutations'
import QUERY from './queries'

const HasOverdueInvoiceButton: React.FC = () => {
  const params = useParams<any>()
  const { data } = useQuery<
    API.users_hasOverdueInvoiceButton,
    API.users_hasOverdueInvoiceButtonVariables
  >(QUERY, {
    variables: {
      id: params.id,
    },
  })
  const [mutationFn] = useMutation<
    API.HasOverdueInvoiceButton,
    API.HasOverdueInvoiceButtonVariables
  >(MUTATION, {
    refetchQueries: ['users_view', 'users_isFrozenButton', 'users_hasOverdueInvoiceButton'],
  })

  const [freezeMutationFn] = useMutation<API.UserFreeze, API.UserFreezeVariables>(FREEZE_MUTATION, {
    refetchQueries: ['users_view', 'users_isFrozenButton'],
  })

  const onSubmit = async () => {
    try {
      const response = await mutationFn({
        variables: { id: params.id, hasOverdueInvoice: !data?.user?.hasOverdueInvoice },
      })

      const hasOverdueInvoice = response.data?.userUpdateHasOverdueInvoice?.hasOverdueInvoice

      window.alert(`Overdue invoice flag ${hasOverdueInvoice ? 'added' : 'removed'}.`)

      const wantToFreeze = !data?.user?.isFrozen && hasOverdueInvoice
      const wantToUnfreeze = data?.user?.isFrozen && !hasOverdueInvoice

      if (wantToFreeze || wantToUnfreeze) {
        const confirmed = window.confirm(
          `Do you also want to ${wantToFreeze ? 'freeze' : 'unfreeze'} this user?`,
        )

        if (confirmed) {
          await freezeAction(freezeMutationFn, params.id, !data?.user?.isFrozen)
        }
      }
    } catch (e) {
      window.alert('Something went wrong.')
    }
  }

  return (
    <Button version="outline" fullWidth onClick={onSubmit}>
      {data?.user?.hasOverdueInvoice ? 'Remove' : 'Add'} overdue invoice flag
    </Button>
  )
}

export default HasOverdueInvoiceButton
