import { UserChip } from '@superhi/design'

import { API } from '../../../../../api'

type Props = API.PersonPreview & {}

const PersonPreview: React.FC<Props> = ({ id, image, name }) => (
  <UserChip
    name={name}
    image={{ src: image.url || '' }}
    link={{
      href: `/people/${id}`,
    }}
  />
)

export { default as FRAGMENT } from './fragments'
export default PersonPreview
