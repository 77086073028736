import React from 'react'
import { Body, Form, FormTextInput, TextInput, TextVerticalSpacer } from '@superhi/design'
import { useFormikContext } from 'formik'

import { Wrapper, Info, Preview } from './styles'
import { getPreviewImage } from './utils'

const InputImagePreview: React.FC<{ src?: string | File; width?: number; height?: number }> = ({
  src,
  width,
  height,
}) => {
  const isFile = src instanceof File
  const [realUrl, setRealUrl] = React.useState(isFile ? undefined : (src as string))

  React.useEffect(() => {
    if (isFile) {
      getPreviewImage(src as File).then((result) => setRealUrl(result))
    }
  }, [isFile, src])

  return (
    <Wrapper>
      <Preview src={realUrl && realUrl.length > 0 ? realUrl : undefined} />
      {width && height && (
        <Info>
          <Body level="3" inline>
            {width}px x {height}px
          </Body>
        </Info>
      )}
    </Wrapper>
  )
}

type Props = Omit<React.ComponentProps<typeof FormTextInput>, 'type' | 'id'> & {
  name: string
  width?: number
  height?: number
}

function InputImage({ src, width, height, label, ...props }: Props) {
  const formik = useFormikContext()
  console.log(formik.errors)

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(`${props.name}.file`, e.target.files ? e.target.files[0] : undefined)
      formik.setFieldValue(`${props.name}.value`, e.target.value)
    },
    [formik, props.name],
  )

  return (
    <TextVerticalSpacer level="4">
      {label && (
        <Body level="3">
          {label}
          {props.required ? ' *' : ''}
        </Body>
      )}
      <div>
        <Form.Peek<any, { file?: File; url?: string; width?: number; height?: number }>
          name={props.name}
        >
          {({ meta }) =>
            meta.value && (
              <InputImagePreview
                src={meta.value.file || meta.value.url}
                width={meta.value.width}
                height={meta.value.height}
              />
            )
          }
        </Form.Peek>
        <TextInput {...props} id={`${props.name}_input`} onChange={handleChange} type="file" />
      </div>
    </TextVerticalSpacer>
  )
}

export default InputImage
