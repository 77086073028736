import React from 'react'
import { DocumentNode } from '@apollo/client'
import { compile as compilePath } from 'path-to-regexp'
import slugify from 'slugify'
import {
  FormCheckboxInput,
  FormTextInput,
  FormMultiSelectInput,
  FormSelectInput,
  FormLongTextInput,
} from '@superhi/design'

import InputImage from '../../components/InputImage'
import MultiSelectProductFulfillable from '../../components/MultiSelectProductFulfillable'
import SelectPrice from '../../components/SelectPrice'
import SelectCourse from '../../components/SelectCourse'
import MultiSelectPerson from '../../components/MultiSelectPerson'
import MultiSelectProductVariant from '../../components/MultiSelectProductVariant'
import MultiSelectCourse from '../../components/MultiSelectCourse'
import FormDateTimeInput from '../../components/FormDateTimeInput'
import SelectCountry from '../../components/SelectCountry'
import SelectCurrency from '../../components/SelectCurrency'
import SelectVariant from '../../components/SelectVariant'

import List, { Props as ListProps } from './_templates/List'
import View, { Props as ViewProps } from './_templates/View'
import Delete, { Props as DeleteProps } from './_templates/Delete'

///////////////

type PageType = 'index' | 'create' | 'view' | 'edit' | 'delete'

export type PageList<T> = ListProps<T>
export type PageView<T> = ViewProps<T>
export type PageDelete<T> = DeleteProps<T>

export const ROUTES: Record<PageType, any> = {
  index: {
    name: 'index',
    path: '/:model',
    // Component: (): any => <Model type="index" />,
  },
  create: {
    name: 'new',
    path: '/:model/new',
    // Component: (): any => <Model type="new" />,
  },
  view: {
    name: 'view',
    path: '/:model/:id',
    // Component: (): any => <Model type="view" />,
  },
  edit: {
    name: 'edit',
    path: '/:model/:id/edit',
    // Component: (): any => <Model type="edit" />,
  },
  delete: {
    name: 'delete',
    path: '/:model/:id/delete',
    // Component: (): any => <Model type="delete" />,
  },
}

export const getModelFromPath = (path: string) => path.split('/')[1]

/* eslint-disable react/display-name */
export const createComponent = (
  type: PageType,
  stuff: ListProps<any> | DeleteProps<any> | ViewProps<any>,
) => {
  switch (type) {
    case 'index':
      return () => <List {...(stuff as ListProps<any>)} />
    case 'view':
      return () => <View {...(stuff as ViewProps<any>)} />
    case 'delete':
      return () => <Delete {...(stuff as DeleteProps<any>)} />
  }
}

export const createConfig = (
  type: PageType,
  model: string,
  excludeNavItem?: boolean,
  config?: ListProps<any> | ViewProps<any> | DeleteProps<any> | React.FC<any>,
  showDelete = false,
) => {
  let Component

  if (typeof config === 'function') {
    Component = config
  } else {
    Component = createComponent(type, config!)
  }

  return {
    name: model,
    exact: true,
    path: compilePath(ROUTES[type].path)({ model: slugify(model, { lower: true }), id: ':id' }),
    props: {
      showDelete,
    },
    excludeNavItem,
    Component,
  }
}

export type ModelPageConfigView<Data> =
  | React.FC<any>
  | {
      query: DocumentNode
      queryKey: string
      formatData?: (data: Data) => { [key: string]: any }
      actions?: (id: string, data: Data) => any
    }

type ModelPageConfig<Index, View, Delete> = {
  name: string
  pages: {
    index?: React.FC<any> | ListProps<Index>
    view?: ModelPageConfigView<View>
    create?: React.FC<any>
    edit?: React.FC<any>
    destroy?: DeleteProps<Delete>
  }
  excludeNavItem?: boolean
}

export const createModelPage = <Index, View, Delete = any>({
  name,
  index,
  view,
  create,
  edit,
  destroy,
  excludeNavItem,
}: {
  name: ModelPageConfig<Index, View, Delete>['name']
  index?: ModelPageConfig<Index, View, Delete>['pages']['index']
  view?: ModelPageConfig<Index, View, Delete>['pages']['view']
  create?: ModelPageConfig<Index, View, Delete>['pages']['create']
  edit?: ModelPageConfig<Index, View, Delete>['pages']['edit']
  destroy?: ModelPageConfig<Index, View, Delete>['pages']['destroy']
  excludeNavItem?: boolean
}): ModelPageConfig<Index, View, Delete> => {
  let pages: ModelPageConfig<Index, View, Delete>['pages'] = {}

  if (index) {
    pages = {
      ...pages,
      index,
    }
  }

  if (view) {
    pages = {
      ...pages,
      view,
    }
  }

  if (create) {
    pages = {
      ...pages,
      create,
    }
  }

  if (edit) {
    pages = {
      ...pages,
      edit,
    }
  }

  if (destroy) {
    pages = {
      ...pages,
      destroy,
    }
  }

  return {
    name,
    excludeNavItem,
    pages,
  }
}

type CommonConfig<T> = {
  name: string //keyof T;
  label?: string
  ariaLabel: string
  placeholder?: string
  required: boolean
  hint?: string
  disabled?: boolean
}

type StringConfig<T> = CommonConfig<T> & {
  type: 'string'
}

type NumberConfig<T> = CommonConfig<T> & {
  type: 'number'
  min?: number
  max?: number
  step?: number
}

type LongtextConfig<T> = CommonConfig<T> & {
  type: 'longtext'
  height?: number
}

type BooleanConfig<T> = CommonConfig<T> & {
  type: 'boolean'
  text: string
}

type SelectConfig<T> = CommonConfig<T> & {
  type: 'select'
  options: any[]
}

type SelectMultiConfig<T> = CommonConfig<T> & {
  type: 'select-multi'
  options: any[]
}

type ImageConfig<T> = CommonConfig<T> & {
  type: 'image'
}

type SelectPriceConfig<T> = CommonConfig<T> & {
  type: 'select-price'
}

type SelectFulfillablesConfig<T> = CommonConfig<T> & {
  type: 'select-fulfillables'
}

type SelectMultiPersonConfig<T> = CommonConfig<T> & {
  type: 'select-multi-person'
}

type SelectMultiProductVariantConfig<T> = CommonConfig<T> & {
  type: 'select-multi-product-variant'
}

type SelectCourseConfig<T> = CommonConfig<T> & {
  type: 'select-course'
}

type SelectCountryConfig<T> = CommonConfig<T> & {
  type: 'select-country'
}
type SelectCurrencyConfig<T> = CommonConfig<T> & {
  type: 'select-currency'
}
type SelectVariantConfig<T> = CommonConfig<T> & {
  type: 'select-variant'
}

type SelectMultiCourseConfig<T> = CommonConfig<T> & {
  type: 'select-multi-course'
  height?: number
}

type DateConfig<T> = CommonConfig<T> & {
  type: 'date'
}

type DateTimeConfig<T> = CommonConfig<T> & {
  type: 'datetime'
}

export type FieldConfig<T> =
  | StringConfig<T>
  | DateConfig<T>
  | DateTimeConfig<T>
  | LongtextConfig<T>
  | NumberConfig<T>
  | BooleanConfig<T>
  | ImageConfig<T>
  | SelectConfig<T>
  | SelectCourseConfig<T>
  | SelectCurrencyConfig<T>
  | SelectVariantConfig<T>
  | SelectCountryConfig<T>
  | SelectMultiCourseConfig<T>
  | SelectMultiConfig<T>
  | SelectMultiPersonConfig<T>
  | SelectMultiProductVariantConfig<T>
  | SelectPriceConfig<T>
  | SelectFulfillablesConfig<T>

export function configToInput(config: FieldConfig<unknown>): React.ReactElement {
  switch (config.type) {
    case 'string': {
      return <FormTextInput {...config} type="text" />
    }
    case 'longtext': {
      return <FormLongTextInput {...config} />
    }
    case 'number': {
      return <FormTextInput {...config} type="number" />
    }
    case 'date': {
      return <FormTextInput {...config} type="date" />
    }
    case 'datetime': {
      return <FormDateTimeInput {...config} />
    }
    case 'boolean': {
      return <FormCheckboxInput {...config}>{config.text}</FormCheckboxInput>
    }
    case 'image': {
      return <InputImage {...config} />
    }
    case 'select': {
      return <FormSelectInput {...config} type={undefined} />
    }
    case 'select-multi': {
      return <FormMultiSelectInput {...config} type={undefined} />
    }
    case 'select-price': {
      return <SelectPrice {...config} />
    }
    case 'select-variant': {
      return <SelectVariant {...config} />
    }
    case 'select-currency': {
      return <SelectCurrency {...config} />
    }
    case 'select-country': {
      return <SelectCountry {...config} />
    }
    case 'select-course': {
      return <SelectCourse {...config} />
    }
    case 'select-multi-course': {
      return <MultiSelectCourse {...config} />
    }
    case 'select-multi-person': {
      return <MultiSelectPerson {...config} />
    }
    case 'select-multi-product-variant': {
      return <MultiSelectProductVariant {...config} />
    }
    case 'select-fulfillables': {
      return <MultiSelectProductFulfillable {...config} />
    }
  }
}
