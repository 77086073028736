import React from 'react'
import { string } from 'yup'
import { Form } from '@superhi/design'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import * as config from '../config'
import { configToInput } from '../../utils'

import MUTATION from './mutations'
import QUERY from './queries'

type FormValues = {
  stripeProductId: string
  title: string
  slug: string
  description: string
  mailchimpTag: string
}

const MembershipProductsUpdate: React.FC = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.membership_products_update>(QUERY, { variables: { id } })

  if (!data?.membershipProduct) {
    return null
  }

  return (
    <BaseForm<FormValues, API.membership_products_update_mutationVariables>
      initialValues={{
        stripeProductId: data.membershipProduct.stripeProductId,
        title: data.membershipProduct.title,
        slug: data.membershipProduct.slug,
        description: data.membershipProduct.description,
        mailchimpTag: data.membershipProduct.mailchimpTag,
      }}
      validationSchema={{
        stripeProductId: string().required(),
        title: string().required(),
        slug: string().required(),
        description: string().required(),
        mailchimpTag: string().required(),
      }}
      mutation={MUTATION}
      createVariablesFn={(formValues) => ({ id, ...formValues })}
    >
      <Form.Row>{configToInput({ ...config.STRIPE_PRODUCT_ID, disabled: true })}</Form.Row>

      <Form.Row>{configToInput(config.TITLE)}</Form.Row>

      <Form.Row>{configToInput(config.SLUG)}</Form.Row>

      <Form.Row>{configToInput(config.DESCRIPTION)}</Form.Row>

      <Form.Row>{configToInput(config.MAILCHIMP_TAG)}</Form.Row>
    </BaseForm>
  )
}

export default MembershipProductsUpdate
