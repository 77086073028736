import React from 'react'
import { useQuery } from '@apollo/client'
import { string, array, number, object } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import * as config from '../config'
import { configToInput } from '../../utils'

import MUTATION from './mutations'
import { CURRENCIES } from './queries'

type FormValues = {
  name: string
  conversions: {
    currencyId: string
    value: number
  }[]
}

const PricesCreate: React.FC = () => {
  const { data } = useQuery<API.PricesCreateAllCurrencies>(CURRENCIES)

  if (!data?.currencies?.nodes) {
    return null
  }

  return (
    <BaseForm<FormValues, API.PriceCreateMutationVariables>
      initialValues={{
        name: '',
        conversions: data.currencies.nodes.map((currency) => ({
          currencyId: currency.id,
          value: 0,
        })),
      }}
      validationSchema={{
        name: string().required(),
        conversions: array()
          .of(
            object({
              currencyId: string().required(),
              value: number().required(),
            }).required(),
          )
          .required(),
      }}
      mutation={MUTATION}
      createVariablesFn={(formValues) => ({
        name: formValues.name,
        conversions: formValues.conversions.map((conversion) => ({
          currencyId: conversion.currencyId,
          value: Number(conversion.value),
        })),
      })}
    >
      <Form.Row>{configToInput(config.NAME)}</Form.Row>

      <Form.Legend label="Conversions">
        {data.currencies.nodes.map((currency, i) => (
          <Form.Row key={currency.id}>
            {configToInput(config.CONVERSIONS_VALUE(`conversions.${i}`, currency.name))}
          </Form.Row>
        ))}
      </Form.Legend>
    </BaseForm>
  )
}

export default PricesCreate
