import { useQuery } from '@apollo/client'
import { Form } from '@superhi/design'
import React from 'react'
import { useParams } from 'react-router-dom'
import { array, boolean, mixed, object, string } from 'yup'

import { API } from '../../../../api'
import { configToInput } from '../../utils'
import * as config from '../config'
import BaseForm from '../../_templates/BaseForm'

import MUTATION from './mutations'
import QUERY from './queries'

type FormValues = {
  title: string
  slug: string
  image: {
    url: string
    value?: string
    file?: any
  }
  description: string
  variantIds: string[]
  isBestSeller: boolean
}

const ProductCollectionUpdate: React.FC = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.product_collections_update>(QUERY, {
    variables: {
      id,
    },
  })

  if (!data?.productCollection) {
    return null
  }

  return (
    <BaseForm<FormValues, API.ProductCollectionUpdateMutationVariables>
      mutation={MUTATION}
      validationSchema={{
        title: string().required(),
        slug: string().required(),
        image: object({
          url: string().required(),
          value: string(),
          file: mixed(),
        }).required(),
        description: string().required(),
        variantIds: array().of(string().required()).required(),
        isBestSeller: boolean().required(),
      }}
      initialValues={{
        title: data.productCollection.title,
        slug: data.productCollection.slug,
        description: data.productCollection.description,
        variantIds: data.productCollection.variants?.nodes?.map((variant) => variant.id) || [],
        isBestSeller: data.productCollection.isBestSeller,
        image: {
          url: data.productCollection.image.url,
          value: '',
          file: undefined,
        },
      }}
      createVariablesFn={(values) => ({
        ...values,
        id,
        image: values.image.file,
      })}
    >
      <Form.Row>{configToInput(config.TITLE)}</Form.Row>

      <Form.Row>{configToInput(config.SLUG)}</Form.Row>

      <Form.Row>{configToInput(config.DESCRIPTION)}</Form.Row>

      <Form.Row>{configToInput(config.IS_BEST_SELLER)}</Form.Row>

      <Form.Row>{configToInput({ ...config.IMAGE, required: false })}</Form.Row>

      <Form.Row>{configToInput(config.VARIANT_IDS)}</Form.Row>
    </BaseForm>
  )
}

export default ProductCollectionUpdate
