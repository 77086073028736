import { Body, Heading, Link } from '@superhi/design'
import React from 'react'

import * as styles from './styles'

type Props = {
  modelName: string
  data: Record<string, unknown>[]
  isFiltered?: boolean
  hideEdit?: boolean
  hideDelete?: boolean
}

const Table: React.FC<Props> = ({
  modelName,
  data,
  isFiltered = false,
  hideEdit = false,
  hideDelete = false,
}) => {
  const newData = data.map((d) =>
    Object.entries(d)
      .filter(([key]) => key !== '__typename')
      .reduce((acc, [key, item]) => ({ ...acc, [key]: item }), {} as any),
  )

  return (
    <styles.Wrapper $isFiltered={isFiltered}>
      {isFiltered && <styles.IsFiltered>Data is filtered...</styles.IsFiltered>}
      <styles.Table>
        <thead>
          <tr>
            {Object.keys(newData[0]).map((d, i) => (
              <td key={i}>
                <Heading level="5">{d}</Heading>
              </td>
            ))}

            <td className="actions">
              <Heading level="5">actions</Heading>
            </td>
          </tr>
        </thead>
        <tbody>
          {newData.map((dataItem, dataItemIndex) => (
            <tr key={dataItemIndex}>
              {Object.values(dataItem).map((dataItemFieldValue, dataItemFieldIndex) => {
                if (dataItemFieldIndex === 0) {
                  return (
                    <td key={dataItemFieldIndex}>
                      <Body level="3" inline>
                        <Link
                          href={`/${modelName}/${dataItem.id}`}
                          color="BLUE_50"
                          hoverColor="BLUE_60"
                        >
                          {dataItemFieldValue as any}
                        </Link>
                      </Body>
                    </td>
                  )
                } else {
                  switch (typeof dataItemFieldValue) {
                    case 'boolean': {
                      return (
                        <td key={dataItemFieldIndex}>
                          <Body level="3" inline color={dataItemFieldValue ? 'GREEN_50' : 'RED_50'}>
                            {dataItemFieldValue ? 'TRUE' : 'FALSE'}
                          </Body>
                        </td>
                      )
                    }

                    default: {
                      return (
                        <td key={dataItemFieldIndex}>
                          <Body level="3" inline>{`${dataItemFieldValue}`}</Body>
                        </td>
                      )
                    }
                  }
                }
              })}

              <td className="actions">
                <styles.Actions>
                  <Body level="3" inline>
                    <Link
                      href={`/${modelName}/${dataItem.id}`}
                      color="BLUE_50"
                      hoverColor="BLUE_60"
                    >
                      View
                    </Link>
                  </Body>
                  {!hideEdit && (
                    <Body level="3" inline>
                      <Link
                        href={`/${modelName}/${dataItem.id}/edit`}
                        color="BLUE_50"
                        hoverColor="BLUE_60"
                      >
                        Edit
                      </Link>
                    </Body>
                  )}
                  {!hideDelete && (
                    <Body level="3" inline>
                      <Link
                        href={`/${modelName}/${dataItem.id}/delete`}
                        color="BLUE_50"
                        hoverColor="BLUE_60"
                      >
                        Delete
                      </Link>
                    </Body>
                  )}
                </styles.Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </styles.Table>
    </styles.Wrapper>
  )
}

export default Table
