import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@superhi/design'
import { useParams } from 'react-router-dom'

import { API } from '../../../../../../../api'

import MUTATION from './mutations'

const InviteToSlackButton: React.FC = () => {
  const params = useParams<any>()
  const [mutationFn] = useMutation<API.UserInviteToSlack, API.UserInviteToSlackVariables>(
    MUTATION,
    { variables: { id: params.id } },
  )

  const onSubmit = async () => {
    try {
      await mutationFn()

      window.alert('User has been invited.')
    } catch (e) {
      window.alert('Something went wrong.')
    }
  }

  return (
    <Button version="outline" fullWidth onClick={onSubmit}>
      Invite to Slack
    </Button>
  )
}

export default InviteToSlackButton
