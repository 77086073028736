import React from 'react'
import { useQuery } from '@apollo/client'
import { FormMultiSelectInput } from '@superhi/design'

import { API } from '../../api'

import QUERY from './queries'

type Props = Omit<React.ComponentProps<typeof FormMultiSelectInput>, 'options'>

const MultiSelectProductVariant: React.FC<Props> = (props) => {
  const { data } = useQuery<API.MultiSelectProductVariant>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.productVariants) {
    return null
  }

  const OPTIONS = data.productVariants.nodes.map((productVariant) => ({
    value: productVariant.id,
    display: productVariant.title,
  }))

  return <FormMultiSelectInput {...props} options={OPTIONS} />
}

export default MultiSelectProductVariant
