import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { string, object, mixed, boolean } from 'yup'
import { Form } from '@superhi/design'
import format from 'date-fns/format'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import * as config from '../config'
import { configToInput } from '../../utils'

import QUERY from './queries'
import MUTATION from './mutations'

type FormValues = {
  applicationUrl: string
  body: string
  closingAt: string
  image: {
    url: string
    value?: string
    file?: any
  }
  intro: string
  publishedAt: string
  slug: string
  title: string
  winnersNotifiedAt: string
}

const ScholarshipUpdate: React.FC = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.scholarship_update>(QUERY, { variables: { id } })

  if (!data?.scholarship) {
    return null
  }

  return (
    <BaseForm<FormValues, API.ScholarshipUpdateMutationVariables>
      initialValues={{
        applicationUrl: data.scholarship.applicationUrl,
        body: data.scholarship.body,
        closingAt: format(new Date(data.scholarship.closingAt), "yyyy-MM-dd'T'hh:mm"),
        image: {
          url: data.scholarship.image.url,
          value: undefined,
          file: undefined,
        },
        intro: data.scholarship.intro,
        publishedAt: format(new Date(data.scholarship.publishedAt), "yyyy-MM-dd'T'hh:mm"),
        slug: data.scholarship.slug,
        title: data.scholarship.title,
        winnersNotifiedAt: format(
          new Date(data.scholarship.winnersNotifiedAt),
          "yyyy-MM-dd'T'hh:mm",
        ),
      }}
      validationSchema={{
        applicationUrl: string().required(),
        body: string().required(),
        closingAt: string().required(),
        image: object({
          url: string().required(),
          value: string(),
          file: mixed(),
        }).required(),
        intro: string().required(),
        publishedAt: string().required(),
        slug: string().required(),
        title: string().required(),
        winnersNotifiedAt: string().required(),
      }}
      mutation={MUTATION}
      createVariablesFn={(formValues) => ({
        id,
        ...formValues,
        image: formValues.image.file || undefined,
        publishedAt: new Date(formValues.publishedAt).toISOString(),
        closingAt: new Date(formValues.closingAt).toISOString(),
        winnersNotifiedAt: new Date(formValues.winnersNotifiedAt).toISOString(),
      })}
    >
      <Form.Row>{configToInput(config.TITLE)}</Form.Row>

      <Form.Row>{configToInput(config.SLUG)}</Form.Row>

      <Form.Row>{configToInput(config.INTRO)}</Form.Row>

      <Form.Row>{configToInput({ ...config.IMAGE, required: false })}</Form.Row>

      <Form.Row>{configToInput(config.BODY)}</Form.Row>

      <Form.Row>{configToInput(config.APPLICATION_URL)}</Form.Row>

      <Form.Row>{configToInput(config.WINNERS_NOTIFIED_AT)}</Form.Row>

      <Form.Row>{configToInput(config.PUBLISHED_AT)}</Form.Row>

      <Form.Row>{configToInput(config.CLOSING_AT)}</Form.Row>
    </BaseForm>
  )
}

export default ScholarshipUpdate
