import React from 'react'
import { array, boolean, mixed, number, object, string } from 'yup'
import {
  Form,
  FormCheckboxInput,
  FormLongTextInput,
  FormMultiSelectInput,
  FormSelectInput,
  FormTextInput,
} from '@superhi/design'

import BaseForm from '../../../_templates/BaseForm'
import { API } from '../../../../../api'
import {
  CREATE_VARIABLES_CREATE as BASE_CREATE_VARIABLES_CREATE,
  INITIAL_VALUES_CREATE as BASE_INITIAL_VALUES_CREATE,
  VALIDATION_SCHEMA_CREATE as BASE_VALIDATION_SCHEMA_CREATE,
} from '../../config'
import { FormCreate } from '../../Form'
import { InitialValuesCreate as BaseInitialValuesCreate } from '../../types'
import * as config from '../config'
import MultiSelectPerson from '../../../../../components/MultiSelectPerson'
import SelectCourse from '../../../../../components/SelectCourse'
import InputImage from '../../../../../components/InputImage'

import MUTATION from './mutations'

export type InitialValues = BaseInitialValuesCreate & {
  difficultyLevel: API.ProductCourseDifficultyLevel
  length: API.ProductCourseLength
  requirements: string
  subjects: API.ProductCourseSubject[]
  syllabusUrl: string
  targetAudience: string
  overview: string[]
  courseId: string
  order: number
  instructorIds?: string[]
  projects: {
    title: string
    description: string
    image: {
      url?: string
      value?: string
      file?: any
    }
    videoId?: string
  }[]
  status: API.ProductStatus
  mailchimpTag: string
  isBeta: boolean
  trailerWistiaId?: string
}

const ProductCourseCreate: React.FC = () => (
  <BaseForm<InitialValues, API.ProductCourseCreateVariables>
    mutation={MUTATION}
    initialValues={{
      ...BASE_INITIAL_VALUES_CREATE,
      courseId: '',
      subjects: [],
      difficultyLevel: API.ProductCourseDifficultyLevel.BEGINNER,
      length: API.ProductCourseLength.SHORT,
      isBestSeller: false,
      syllabusUrl: '',
      instructorIds: undefined,
      requirements: '',
      targetAudience: '',
      order: 1,
      overview: [],
      projects: [],
      status: API.ProductStatus.LIVE,
      mailchimpTag: '',
      isBeta: false,
      trailerWistiaId: '',
    }}
    validationSchema={{
      ...BASE_VALIDATION_SCHEMA_CREATE,
      subjects: array()
        .of(string().oneOf(Object.values(API.ProductCourseSubject)).required())
        .required(),
      difficultyLevel: string().oneOf(Object.values(API.ProductCourseDifficultyLevel)).required(),
      length: string().oneOf(Object.values(API.ProductCourseLength)).required(),
      requirements: string().required(),
      syllabusUrl: string().required(),
      targetAudience: string().required(),
      overview: array().of(string().required()).required(),
      instructorIds: array().of(string().required()),
      courseId: string().required(),
      order: number().required(),
      isBeta: boolean().required(),
      projects: array()
        .of(
          object({
            title: string().required(),
            description: string().required(),
            image: object({
              url: string(),
              value: string(),
              file: mixed(),
            }).required(),
            videoId: string(),
          }).required(),
        )
        .required(),
      status: string().oneOf(Object.values(API.ProductStatus)).required(),
      mailchimpTag: string().required(),
      trailerWistiaId: string(),
    }}
    createVariablesFn={(formValues) => ({
      ...BASE_CREATE_VARIABLES_CREATE(formValues),
      difficultyLevel: formValues.difficultyLevel,
      length: formValues.length,
      requirements: formValues.requirements,
      subjects: formValues.subjects,
      syllabusUrl: formValues.syllabusUrl,
      targetAudience: formValues.targetAudience,
      overview: formValues.overview,
      order: formValues.order,
      instructorIds: formValues.instructorIds,
      courseId: formValues.courseId,
      projects: formValues.projects.map(({ image, ...project }) => ({
        ...project,
        image: image.file === '' ? undefined : image.file,
      })),
      status: formValues.status,
      mailchimpTag: formValues.mailchimpTag,
      isBeta: formValues.isBeta,
      trailerWistiaId: formValues.trailerWistiaId,
    })}
  >
    <Form.Rows>
      <FormCreate />

      <FormSelectInput {...config.STATUS} />

      <FormSelectInput {...config.DIFFICULTY_LEVEL} />

      <FormSelectInput {...config.LENGTH} />

      <FormTextInput {...config.ORDER} />

      <FormCheckboxInput {...config.IS_BETA} />

      <FormLongTextInput {...config.REQUIREMENTS} />

      <MultiSelectPerson {...config.INSTRUCTOR_IDS} />

      <FormMultiSelectInput {...config.SUBJECTS} />

      <FormTextInput {...config.SYLLABUS_URL} />

      <FormTextInput {...config.MAILCHIMP_TAG} />

      <FormTextInput {...config.TRAILER_WISTIA_ID} />

      <FormLongTextInput {...config.TARGET_AUDIENCE} />

      <Form.Repeatable<InitialValues, 'overview'>
        name="overview"
        label="What youll learn"
        defaultValue={''}
      >
        {({ name }) => <FormTextInput {...config.OVERVIEW(name)} />}
      </Form.Repeatable>

      <Form.Repeatable<InitialValues, 'projects'>
        name="projects"
        label="Projects"
        defaultValue={{
          title: '',
          description: '',
          image: {
            url: undefined,
            file: '',
            value: '',
          },
        }}
      >
        {({ name }) => (
          <>
            <FormTextInput {...config.PROJECTS_TITLE(name)} />

            <FormLongTextInput {...config.PROJECTS_DESCRIPTION(name)} />

            <InputImage {...config.PROJECTS_IMAGE(name)} />

            <FormTextInput {...config.PROJECTS_VIDEO_ID(name)} />
          </>
        )}
      </Form.Repeatable>

      <SelectCourse {...config.COURSE_ID} />
    </Form.Rows>
  </BaseForm>
)

export default ProductCourseCreate
