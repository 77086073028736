import React from 'react'
import { MutationTuple, useMutation, useQuery } from '@apollo/client'
import { Button } from '@superhi/design'
import { useParams } from 'react-router-dom'

import { API } from '../../../../../../../api'

import MUTATION from './mutations'
import QUERY from './queries'

export const action = async (
  mutationFn: MutationTuple<API.UserFreeze, API.UserFreezeVariables>[0],
  userId: string,
  isFrozen: boolean,
) => {
  const response = await mutationFn({
    variables: {
      id: userId,
      isFrozen,
    },
  })

  window.alert(`User is now ${response?.data?.userUpdateFreeze?.isFrozen ? 'frozen' : 'unfrozen'}.`)
}

const FreezeButton: React.FC = () => {
  const params = useParams<any>()
  const { data } = useQuery<API.users_isFrozenButton, API.users_isFrozenButtonVariables>(QUERY, {
    variables: {
      id: params.id,
    },
  })
  const [mutationFn] = useMutation<API.UserFreeze, API.UserFreezeVariables>(MUTATION, {
    refetchQueries: ['users_view', 'users_isFrozenButton'],
  })

  const onSubmit = async () => {
    const confirmed =
      data?.user?.isFrozen && data.user.hasOverdueInvoice
        ? window.confirm(
            'This user has an overdue invoice. Are you sure you want to unfreeze them?',
          )
        : true

    if (confirmed) {
      try {
        await action(mutationFn, params.id, !data?.user?.isFrozen)
      } catch (e) {
        window.alert('Something went wrong.')
      }
    }
  }

  return (
    <Button version="outline" fullWidth onClick={onSubmit}>
      {data?.user?.isFrozen ? 'Unfreeze' : 'Freeze'}
    </Button>
  )
}

export default FreezeButton
