import courses from './courses'
import courseWatchedSummaries from './courseWatchedSummaries'
import courseChapters from './courseChapters'
import courseLessons from './courseLessons'
import courseProjects from './courseProjects'
import courseHomeworks from './courseHomeworks'
import resources from './resources'
import currencies from './currencies'
import collaborators from './collaborators'
import prices from './prices'
import productFulfillables from './productFulfillables'
import productCourses from './products/productCourses'
import productBooks from './products/productBooks'
import productCollections from './productCollections'
import discounts from './discounts'
import people from './people'
import orders from './orders'
import refunds from './refunds'
import hallOfFamers from './hallOfFamers'
import scholarships from './scholarships'
import users from './users'
import settings from './settings'
import teams from './teams'
import popups from './popups'
import membershipAffiliates from './membershipAffiliates'
import membershipProducts from './membershipProducts'
import membershipInvoices from './membershipInvoices'
import membershipRefunds from './membershipRefunds'
import { createConfig } from './utils'

export const PAGES = [
  // courses,
  // courseChapters,
  // courseLessons,
  // courseProjects,
  // courseHomeworks,
  // resources,
  currencies,
  collaborators,
  courseWatchedSummaries,
  prices,
  membershipAffiliates,
  membershipProducts,
  membershipRefunds,
  membershipInvoices,
  // productFulfillables,
  productCourses,
  productBooks,
  productCollections,
  discounts,
  people,
  orders,
  hallOfFamers,
  refunds,
  scholarships,
  users,
  teams,
  // popups,
  settings,
].reduce((acc, page) => {
  const hideCreate = page.pages.create ? false : true
  const hideEdit = page.pages.edit ? false : true
  const hideDelete = page.pages.destroy ? false : true

  return [
    ...acc,
    page.pages.index &&
      createConfig(
        'index',
        page.name,
        page.excludeNavItem,
        typeof page.pages.index === 'function'
          ? page.pages.index
          : {
              ...page.pages.index,
              hideCreate,
              hideEdit,
              hideDelete,
            },
      ),
    page.pages.create && createConfig('create', page.name, page.excludeNavItem, page.pages.create),
    page.pages.view &&
      createConfig(
        'view',
        page.name,
        page.excludeNavItem,
        typeof page.pages.view === 'function'
          ? page.pages.view
          : {
              ...page.pages.view,
              hideEdit,
              hideDelete,
            },
      ),
    page.pages.edit &&
      createConfig('edit', page.name, page.excludeNavItem, page.pages.edit, !!page.pages.destroy),
    page.pages.destroy &&
      createConfig('delete', page.name, page.excludeNavItem, page.pages.destroy),
  ].filter(Boolean)
}, [] as any)

export default PAGES
