import React from 'react'
import { string, object, mixed } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import { IMAGE, SLUG, TITLE, TYPE, URL, USERS } from '../config'
import { configToInput } from '../../utils'

import MUTATION from './mutations'

export type FormValues = {
  slug: string
  title: string
  type: API.HallOfFamerType
  url?: string
  userIds?: string
  image: {
    url?: string
    value: string
    file: any
  }
}

const HallOfFamerCreate: React.FC = () => {
  return (
    <BaseForm<FormValues, API.HallOfFameItemCreateVariables>
      mutation={MUTATION}
      validationSchema={{
        slug: string().required(),
        title: string().required(),
        type: string().oneOf(Object.values(API.HallOfFamerType)).required(),
        url: string().when('type', {
          is: API.HallOfFamerType.WEBSITE,
          then: string().required(),
        }),
        userIds: string(), //array().of(string().required()).required(),
        image: object({
          url: string(),
          value: string().required(),
          file: mixed().required(),
        }).required(),
      }}
      initialValues={{
        slug: '',
        title: '',
        type: API.HallOfFamerType.WEBSITE,
        url: undefined,
        userIds: undefined,
        image: {
          url: '',
          value: '',
          file: undefined,
        },
      }}
      createVariablesFn={(values) => {
        const userIds = values.userIds?.trim().split(',').filter(Boolean)

        return {
          ...values,
          image: values.image.file,
          userIds: userIds && userIds.length > 0 ? userIds : undefined,
        }
      }}
    >
      <Form.Row>{configToInput(TITLE)}</Form.Row>

      <Form.Row>{configToInput(SLUG)}</Form.Row>

      <Form.Row>{configToInput(TYPE)}</Form.Row>

      <Form.Peek<FormValues, FormValues['type']> name="type">
        {({ meta }) => (
          <Form.Row>
            {configToInput({ ...URL, required: meta.value === API.HallOfFamerType.WEBSITE })}
          </Form.Row>
        )}
      </Form.Peek>

      <Form.Row>{configToInput(USERS)}</Form.Row>

      <Form.Row>{configToInput(IMAGE)}</Form.Row>
    </BaseForm>
  )
}

export default HallOfFamerCreate
