import React from 'react'
import { useQuery } from '@apollo/client'
import { FormSelectInput } from '@superhi/design'

import { API } from '../../api'

import QUERY from './queries'

const SelectCourse: React.FC<Omit<React.ComponentProps<typeof FormSelectInput>, 'options'>> = (
  props,
) => {
  const { data } = useQuery<API.SelectCourse>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.courses) {
    return null
  }

  const OPTIONS = [
    { display: 'Choose one', value: undefined },
    ...data.courses.nodes.map((course) => ({
      value: course.id,
      display: course.title,
    })),
  ]

  return <FormSelectInput {...props} options={OPTIONS} />
}

export default SelectCourse
