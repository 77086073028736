import { Body, DateTime, VerticalSpacer } from '@superhi/design'
import React from 'react'
// import { RichTextSlateEditor } from '@superhi/design';

import { camelToTitle } from '../../../../../../utils'
import UrlPreview from '../../../../../../components/UrlPreview'
import StringPreview from '../../../../../../components/StringPreview'
import BooleanPreview from '../../../../../../components/BooleanPreview'
import ImagePreview from '../../../../../../components/ImagePreview'
import PersonPreview from '../../../../people/components/PersonPreview'
import MarkdownPreview from '../../../../../../components/MarkdownPreview'
import ProductCoursesPreview from '../../../../products/productCourses/components/ProductCoursesPreview'
import PriceConversionPreview from '../../../../prices/components/PriceConversionPreview'
import { getStripeUrl } from '../../../../../../utils/stripe'
import StripePricePreview from '../../../../../../components/StripePricePreview'
import MoneyPreview from '../../../../../../components/MoneyPreview'
import UserPreview from '../../../../users/components/UserPreview'
import MembershipProductPreview from '../../../../membershipProducts/components/MembershipProductPreview'
import ProductVariantPreview from '../../../../../../components/ProductVariantPreview'
import OrderLineItemPreview from '../../../../orders/components/OrderLineItemPreview'
import CoursePreview from '../../../../courses/components/CoursePreview'
import UserCoursePreview from '../../../../courses/components/UserCoursePreview'
import OrderPreview from '../../../../orders/components/OrderPreview'

const getCustomGraphQLType = (item: any) => {
  switch (item.__typename) {
    case 'Course': {
      return <CoursePreview {...item} />
    }
    case 'UserCourse': {
      return <UserCoursePreview {...item} />
    }
    case 'Order': {
      return <OrderPreview {...item} />
    }
    case 'OrderLineItem': {
      return <OrderLineItemPreview {...item} />
    }
    case 'Image': {
      return <ImagePreview {...item} />
    }
    case 'Person': {
      return <PersonPreview {...item} />
    }
    case 'User': {
      return <UserPreview {...item} />
    }
    case 'ProductCourse': {
      return <ProductCoursesPreview {...item} />
    }
    case 'PriceConversion': {
      return <PriceConversionPreview {...item} />
    }
    case 'StripePrice': {
      return <StripePricePreview {...item} />
    }
    case 'Money':
    case 'MoneyV1': {
      return <MoneyPreview {...item} />
    }
    case 'MembershipProduct': {
      return <MembershipProductPreview {...item} />
    }
    case 'ProductVariant': {
      return <ProductVariantPreview {...item} />
    }
    default: {
      return null
    }
  }
}

const URL_REGEX =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

const UTC_REGEX = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z/

const getValue = (item: any): React.ReactNode => {
  switch (typeof item) {
    case 'string': {
      if (item.startsWith('http://') || item.startsWith('https://') || item.startsWith('://')) {
        return <UrlPreview url={item} />
      } else if (item.startsWith('acct_')) {
        return <UrlPreview url={getStripeUrl(`/connect/accounts/${item}`)}>{item}</UrlPreview>
      } else if (item.startsWith('prod_')) {
        return <UrlPreview url={getStripeUrl(`/products/${item}`)}>{item}</UrlPreview>
      } else if (item.startsWith('in_')) {
        return <UrlPreview url={getStripeUrl(`/invoices/${item}`)}>{item}</UrlPreview>
      } else if (UTC_REGEX.test(item)) {
        return `${new Intl.DateTimeFormat('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: 'America/New_York',
        }).format(new Date(item))} (EST)`
      } else {
        return <MarkdownPreview>{item}</MarkdownPreview>
      }
    }
    case 'boolean': {
      return <BooleanPreview>{item}</BooleanPreview>
    }
    case 'undefined': {
      return null
    }
    case 'object': {
      if (item === null) {
        return <StringPreview />
      } else if (Array.isArray(item)) {
        if (item.length === 0) {
          return <StringPreview />
        } else {
          return (
            <VerticalSpacer amount={{ small: 16, medium: 16, large: 16 }}>
              {item.map((i, index) => (
                <div key={index}>{getValue(i)}</div>
              ))}
            </VerticalSpacer>
          )
        }
      } else if ('__typename' in item) {
        return getCustomGraphQLType(item)
      }

      return item
    }
    case 'function': {
      if (item === null) {
        return <StringPreview />
      }

      return item
    }
    default: {
      return item
    }
  }
}

// const getType = (children: any) => {
//   if (typeof children === 'string' && children.includes(`{"object"`)) {
//     return 'rich-text';
//   } else if (Array.isArray(children)) {
//     return 'array';
//   } else if (children === true || children === false) {
//     return 'boolean';
//   } else if (React.isValidElement(children)) {
//     return 'react-element';
//   } else if (
//     (typeof children === 'object' || typeof children === 'function') &&
//     children !== null
//   ) {
//     return 'object';
//   } else if (children === 'undefined') {
//     return 'remove';
//   } else {
//     return 'unknown';
//   }
// };

type Props = {
  title?: string
  nested?: boolean
}

const DataRow: React.FC<Props> = ({ title, children, nested = false }) => {
  const columnName = camelToTitle(title || '')

  if (columnName === '__typename') {
    return null
  }

  const th = (
    <th>
      <Body level="3">
        <strong>{columnName}</strong>
      </Body>
    </th>
  )

  if (React.isValidElement(children)) {
    return (
      <tr>
        {th}
        <td>{children}</td>
      </tr>
    )
  } else {
    return (
      <tr>
        {th}
        <td>{getValue(children)}</td>
      </tr>
    )
  }

  // switch (type) {
  //   case 'remove': {
  //     return null;
  //   }
  //   case 'react-element': {
  //     return (
  //       <tr>
  //         {th}
  //         <td>{children}</td>
  //       </tr>
  //     );
  //   }
  //   case 'array': {
  //     return (
  //       <tr>
  //         {columnName && th}
  //         <td>
  //           <Wrapper>
  //             <tbody>
  //               {(children as any[]).map((item, i) => (
  //                 <DataRow key={i} nested>
  //                   {item}
  //                 </DataRow>
  //               ))}
  //             </tbody>
  //           </Wrapper>
  //         </td>
  //       </tr>
  //     );
  //   }
  //   case 'object': {
  //     return (
  //       <tr>
  //         {columnName && th}
  //         <td>
  //           <Wrapper>
  //             <tbody>
  //               {Object.entries(children as any).map(([key, value], i) => (
  //                 <DataRow key={i} title={key}>
  //                   {value as any}
  //                 </DataRow>
  //               ))}
  //             </tbody>
  //           </Wrapper>
  //         </td>
  //       </tr>
  //     );
  //   }

  // default: {
  // return (
  //   <tr>
  //     {th}
  //     <td>{children}</td>
  //   </tr>
  // );
  // }
  // }
}

export default DataRow
