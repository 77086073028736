import { DocumentNode, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'

type Props = {
  query: DocumentNode
  queryKey: string
  formatData: (data: any) => React.ReactElement
}

const Links: React.FC<Props> = ({ query, queryKey, formatData }) => {
  const { id } = useParams<any>()
  const { data, loading } = useQuery(query, {
    variables: {
      id,
    },
  })

  const formattedData = React.useMemo(() => {
    if (data) {
      return formatData(data[queryKey])
    }

    return <div></div>
  }, [data, formatData, queryKey])

  if (loading && !data) {
    return <div>Loading...</div>
  }

  return formattedData
}

export default Links
